<template>
  <div class="input-group dk-port-select-new">
    <label
      v-if="showLabel"
      :style="{ width: labelWidth, 'text-align': Align }"
      class="control-label"
    >
      <i v-if="isMustFill" class="fa fa-asterisk"></i>{{ title }}
    </label>
    <el-select
      v-model="value"
      v-bind="$attrs"
      :filterable="filterable"
      remote
      :multiple="multiple"
      :collapse-tags="collapseTags"
      :remote-method="remoteMethod"
      @change="selectChange"
      :clearable="clearable"
      :style="{ width: selectWidth }"
      :disabled="isDisabled"
      :placeholder="placeholder"
      :size="size"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
const { httpAccounting } = require("../api/_http");

export default {
  name: "dk-port-select-new",
  data() {
    return {
      options: [],
      allOptions: [],
      dataSourceUrl: "",
    };
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Function,
      default: function () {},
    },
    reservekeyword: {
      type: Boolean,
      default: false,
    },
    value: {
      type: null,
      default: "",
    },
    title: {
      type: String,
      default: "港口",
    },
    placeholder: {
      type: String,
      default: "请选择港口",
    },
    selectWidth: {
      type: Number,
      default: 150,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    labelWidth: {
      type: Number,
      default: 85,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isMustFill: {
      type: Boolean,
      default: false,
    },
    Align: {
      type: String,
      default: "center",
    },
    clearable: {
      // 支持不显示clearable，默认true，不会影响以前使用的组件
      type: Boolean,
      default: true,
    },
    collapseTags: {
      // 多选，省略显示，默认false, 不会影响以前使用的组件
      type: Boolean,
      default: true,
    },
    code: {
      type: String,
      default: "",
    },
    needCompositeName: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    size:{
      type:String,
      default:"small"
    },
    tags: {
      type: Array,
      default: () => ["trailerPort"], // 海运港口 'shippingPort' |  拖车港口 'trailerPort'
    },
  },
  watch: {
    value(newVal) {
      if (!newVal) {
        return;
      }

      if (Array.isArray(newVal)) {
        if (newVal.length > 0) {
          const allExist = newVal.every(
            (item) => !!this.options.find((i) => i.value === item.value)
          );
          if (!allExist) {
            this.getOptions("code", newVal);
          }
        }
      } else {
        if (!this.options.find((item) => item.value === newVal)) {
          this.getOptions("code", newVal);
        }
      }
    },
  },
  async mounted() {
    if (this.value) {
      await this.getOptions("name", "");
      if (!this.options.find((item) => item.value === this.value)) {
        this.getOptions("code", this.value);
      }
    } else {
      this.getOptions("name", this.value);
    }
  },
  methods: {
    selectChange: function (val) {
      this.$emit("input", val);
      this.$emit("change", val);
      if (Array.isArray(val)) {
        this.$emit(
          "changeItem",
          val.map((item) => this.allOptions.find((option) => option.value === item))
        );
      } else {
        this.$emit(
          "change-item",
          this.options.find((item) => item.value === val)
        );
      }

      if (!val || (Array.isArray(val) && val.length === 0)) {
        this.getOptions("name", "");
      }
    },
    getOptions: function (type, value) {
      return httpAccounting
        .get("/base-biz-data-server/query/data/port/composite/search", {
          params: {
            [type]: Array.isArray(value) ? value.join(",") : value,
            needCompositeName: this.needCompositeName,
            tags: this.tags.join(","),
          },
        })
        .then((res) => {
          if (res.data.success && res.data.data) {
            if (type === "code") {
              res.data.data.forEach((item) => {
                if (!this.options.find((i) => i.value === item.value)) {
                  this.options.push(item);
                }
              });
            } else {
              this.options = res.data.data;
            }

            this.allOptions.push(...res.data.data);
          }
        });
    },
    remoteMethod: function (query) {
      this.getOptions("name", query);
    },
  },
};
</script>

<style lang="css" scoped>
.dk-port-select-new /deep/ .el-input--small .el-input__inner {
  height: 28px;
  line-height: 28px;
}
.dk-port-select-new /deep/ .el-input--small .el-input__suffix {
  display: flex;
  align-items: center;
}
</style>