const { harbourHttp, iamHttp } = require('./_http')


// exports.fetchPortList = () => {
//   return harbourHttp.get("/common/findPort/list", {
//     parseResp: true
//   })
// }

exports.fetchNewPortList = (params) => {
  return iamHttp.get("/base-biz-data-server/query/data/port/composite/search", {
    params: params
  })
}

exports.getDriverTruckInfo = (params) => {
  return harbourHttp.get("/driver/truck/info", { params })
}



exports.queryBasicData = (params) => {
  return harbourHttp.get("/common/basic/data?dataType=" + params.dataType)
}