<template>
  <div class="search-file">
    <SearchForm
      :config="{ items: searchItems, tableRef: 'table', itemSpan: 12 }"
      ref="searchForm"
      :size="'small'"
      :handleSearch="handleSearch"
    >
      <template slot="customerId" slot-scope="{ value }">
        <a-auto-complete
          allowClear
          :dataSource="autoCustomerList"
          @change="handleCustomerChange"
          v-decorator="[value.fieldName, {}]"
          size="small"
          style="width: 100%"
          :placeholder="value.placeholder"
          :backfill="false"
          :filter-option="
            (input, option) => {
              return option.componentOptions.children[0].text.indexOf(input) >= 0;
            }
          "
        ></a-auto-complete>
      </template>
    </SearchForm>
    <el-table
      :data="dataSource"
      border
      height="100%"
      style="width: 100%; margin-top: 18px; height: 100%"
    >
      <el-table-column fixed="left" label="操作" width="80">
        <template slot-scope="scope">
          <span
            style="color: #1890ff; cursor: pointer"
            @click="handleSelect(scope.row)"
            >{{ selectFile.includes(scope.row.id) ? "取消选择" : "选择" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="文件名" width="200">
        <template slot-scope="scope">
          <span
            style="color: #1890ff; cursor: pointer"
            @click="showFile(scope.row.fileUrl)"
            >{{ scope.row.fileName }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        v-for="item in tableColumns"
        :key="item.dataIndex"
        :prop="item.dataIndex"
        :label="item.title"
        :width="item.width"
      >
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import SearchForm from "./search-form.vue";
import { searchItems, tableColumns } from "./options";
import { postCustomerList, fetchSearchFile } from "./server.js";
import dayjs from "dayjs";
export default {
  name: "search-file",
  props: ["fileList", "selectFile"],
  components: {
    SearchForm: SearchForm,
  },
  computed: {
    autoCustomerList() {
      const list = this.customerList.map((v) => ({
        text: v.name,
        value: v.id + "",
      }));
      return list;
    },
  },
  data() {
    return {
      searchItems: searchItems,
      tableColumns: tableColumns,
      dataSource: [],
      customerList: [],
    };
  },
  methods: {
    handleData(dataSource) {
      const { records, total, pages, current, size } = dataSource.data;
      const pageInfo = {
        pageSize: size || 0,
        total,
        pageNo: current || 0,
        totalPage: pages,
        dataSource: records || [],
      };
      return pageInfo;
    },

    async handleSearch() {
      const values = this.$refs.searchForm.getValues();
      let userInfo = JSON.parse(localStorage.getItem("iam-user-info"));
      const result = await fetchSearchFile({ ...values, huid: userInfo?.huid || "" });
      if (!result.success) {
        return;
      }
      if (!result.data || !result.data.length) {
        this.dataSource = [];
        return;
      }
      let data = result.data;
      if (data && data.length) {
        data.forEach((v) => {
          if (v.createTime) {
            v.createTime = v.createTime.slice(5, -1);
          }
        });
      }
      this.dataSource = data;
    },

    async handleSelect(record) {
      this.$emit("select", record);
    },
    handleCustomerChange: _.debounce(async function (value) {
      //搜索查询承运人
      if (value && value.trim() == "") {
        return;
      }
      var param = {
        key: value.trim(),
        limit: 50,
      };
      const result = await postCustomerList(param);
      try {
        this.customerList = JSON.parse(result.data.value);
      } catch (error) {
        // this.$message.error(result.msg);
        console.log(error);
      }
    }, 800),
    init() {
      this.$refs.searchForm.setValues({
        createTime: [
          dayjs().subtract(1, "days").format("YYYY-MM-DD 00:00:00"),
          dayjs().format("YYYY-MM-DD 23:59:59"),
        ],
      });
      this.$refs.searchForm.search();
    },
    setValues(values) {
      console.log(values, "values");
      this.$refs.searchForm.setValues(values);
    },
    search() {
      this.$refs.searchForm.search();
    },
    setCustomer(child) {
      console.log(child, "child");
      this.customerList.push(child);
    },
    showFile(url) {
      this.$emit("viewerFile", url);
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style scoped>
.search-file /deep/ .el-table .el-table__cell {
  padding: 5px 0px;
  text-align: center;
}

.search-file /deep/ .ant-table-tbody td {
  padding: 5px 0px;
  text-align: center;
}
.pageComponent {
  position: relative;
  float: right;
  margin-top: 10px;
}

.search-file {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 0px 18px; */
}
</style>
