var Vue = require('vue');
var responseModal = require('dk-response-modal')
var dkLocaleSelect = require('dk-locale-select')
var dkQuestionFeedbackModal = require('dk-question-feedback-modal')
var dkSystemIssueFeedbackModal = require('dk-system-issue-feedback-modal')
setTimeout(() => {
var vm = new Vue({
    el: '#nav',
    components: {
        'dk-response': responseModal.default,
        'dk-locale-select': dkLocaleSelect.default,
        'dk-question-feedback-modal': dkQuestionFeedbackModal.default,
        'dk-system-issue-feedback-modal': dkSystemIssueFeedbackModal.default,
    },
    data: {
        showThisWrap:false,
        isCheckedSwitch: true,
        hasAuth: false,
        messageCount: 0,
        responseModal:{
            fade: false,
            title: '客户',
            info: {},
            config: {},
            id: '',
            oppositeId: '',
            count: 0
        },
        timer: ''
    },
    mounted: function(){
        var that = this
        var timer = setInterval(function(){
            if(!esapp.user){
                return
            }else{
                clearInterval(timer)
                esapp.user.authorities.forEach(function(v){
                    if(v == 'CUSTOMER_COMMUNICATE'){
                        that.hasAuth = true
                        that.getMessageCount()
                        if(window.location.host.indexOf("localhost")>=0){
                            //是本地开发的话，就不让开启 WebSocket 了
                            return;
                        }
                        that.init()
                        return
                    }
                })
            }
        },100)
        this.$eventBus.$on('showSystemIssueFeedbackModal',()=>{
            this.$refs.dkSystemIssueFeedbackModalRef.dialogVisible = true
        })
        this.initNav()
    },
    methods: {
        initNav() {
            // boss 特殊需求
            if (esapp && esapp.user && encodeURI(esapp.user.userDisplayName) === '%E5%94%90%E7%BA%A2%E6%96%8C') {
                window.localStorage.setItem('index_nav_hide_switch', true);
            }

            const index_nav_hide_switch = window.localStorage.getItem('index_nav_hide_switch') === 'true';
            this.isCheckedSwitch = index_nav_hide_switch;

            const indexNav = document.getElementById('index_nav');
            const pageContainer = document.querySelector('.page-container');
            const scrollWrap = document.querySelector('.page-container .page-content-wrapper .tab-content .scrollWrap');

            if (!index_nav_hide_switch) {
                indexNav.style.display = 'block';
                indexNav.style.top = '0px';
                pageContainer.style.marginTop = '52px';
                if (scrollWrap) { scrollWrap.style.height = 'calc(100vh - 90px)'; };
            } else {
                document.addEventListener('mousemove', this.handleMouseMove);
                indexNav.addEventListener('mouseleave', this.handleMouseLeave);
            }
        },
        handleMouseMove(event) {
            const indexNav = document.getElementById('index_nav');
            if (event.clientY <= 10) {
                indexNav.style.display = 'block';
                setTimeout(function() {
                    indexNav.style.top = '0px';
                }, 10);
            } else if (!indexNav.matches(':hover')) {
                indexNav.style.top = '-50px';
                setTimeout(function() {
                    if (indexNav.style.top === '-50px') {
                        indexNav.style.display = 'none';
                    }
                }, 300);
            }
        },
        handleMouseLeave(event) {
            const indexNav = document.getElementById('index_nav');
            if (event.clientY > 10) {
                indexNav.style.top = '-50px';
                setTimeout(function() {
                    if (indexNav.style.top === '-50px') {
                        indexNav.style.display = 'none';
                    }
                }, 300);
            }
        },
        indexNavHideSwitch() {
            const index_nav_hide_switch = window.localStorage.getItem('index_nav_hide_switch') === 'true';
            window.localStorage.setItem('index_nav_hide_switch', !index_nav_hide_switch);
            this.isCheckedSwitch = !index_nav_hide_switch;

            const indexNav = document.getElementById('index_nav');
            const pageContainer = document.querySelector('.page-container');
            const scrollWrap = document.querySelector('.page-container .page-content-wrapper .tab-content .scrollWrap');

            if (!this.isCheckedSwitch) {
                indexNav.style.display = 'block';
                indexNav.style.top = '0px';
                pageContainer.style.marginTop = '52px';
                if (scrollWrap) { scrollWrap.style.height = 'calc(100vh - 90px)'; };
                document.removeEventListener('mousemove', this.handleMouseMove);
                indexNav.removeEventListener('mouseleave', this.handleMouseLeave);
            } else {
                indexNav.style.top = '-50px';
                if (scrollWrap) { scrollWrap.style.height = 'calc(100vh - 50px)'; };
                setTimeout(function() {
                    if (indexNav.style.top === '-50px') {
                        indexNav.style.display = 'none';
                    }
                }, 300);
                pageContainer.style.marginTop = '10px';
                document.addEventListener('mousemove', this.handleMouseMove);
                indexNav.addEventListener('mouseleave', this.handleMouseLeave);
            }
        },
        hideThisWrap:function (params) {
            this.showThisWrap = false
        },
        getwebsocketurl:function(){
            // return 'ws://192.168.30.185:8080/websocket'
            // return 'ws://qa.new.carrierglobe.com/websocket'

            var host = window.location.host
            var protocol = (host.indexOf('qa') != -1) ?'wss://' :'wss://'
            return protocol + host + '/websocket'
        },
        init: function(){

            if(this.timer) clearInterval(this.timer)

        },
        onQuestionFeedback() {
            this.$refs.dkQuestionFeedbackModalRef.open()
        },
        // onQuestionFeedback2() {
        //     this.$refs.dkSystemIssueFeedbackModalRef.dialogVisible = true
        // },
        getMessageCount: function(val){
            var that = this
            if(!val) {
                _.util.ajax_get('/messenger/waiting/dispose/messageCount',{},function(res){
                    that.messageCount = res.data.value
                    if(that.messageCount){
                        that.showThisWrap = true
                        console.log( that.showThisWrap)
                    }
                    // that.setNotice(that.messageCount)
                })
            }
            _.util.ajax_get('/messenger/waiting/dispose/messageInfo',{},function(res){
                console.log(res)
                if(!res.data) return
                res = eval('(' + res.data.value + ')')
                that.responseModal.title = res.createdByName + '--' + res.companyName
                that.responseModal.id = res.earliestPostingId
                that.responseModal.oppositeId = res.createdBy
                that.responseModal.count = res.count
                if(that.messageCount){
                    that.showThisWrap = true
                    console.log( that.showThisWrap)
                }
            })
        },
        queryMessage: function(){
            if(window.location.hash.indexOf('customer-communication') != -1){
                $('[esapp-id="customer-communication-query"]')[0].click()
            }else{
                window.location.href = '#customer-communication?&1'
            }
        },
        setNotice: function(i){
            if(i == 0) return
            if (window.Notification) {
                var popNotice = function() {
                    if (Notification.permission == "granted") {
                        var notification = new Notification("Hi，帅哥：", {
                            body: '有你的新消息~【'+i+'】',
                            icon: '//image.zhangxinxu.com/image/study/s/s128/mm1.jpg',
                            tag: 'customerMessage',
                            renotify: true
                        });

                        notification.onclick = function() {
                            notification.close();
                        };
                    }
                };

                if (Notification.permission == "granted") {
                    popNotice();
                } else if (Notification.permission != "denied") {
                    Notification.requestPermission(function (permission) {
                      popNotice();
                    });
                }
            }
        },
        response: function(item,addContent){
            // this.responseModal.title = 'zhh--上海管易云计算软件有限公司';
            this.responseModal.fade = true;
            this.responseModal.config = {
                id: this.responseModal.id,
                key: 'customer',
                documentId: 'customer-communication',
                editorTool: [
                    'image',
                    'file'
                ],
                // changeInfo: item.id ?true :false,
                buttons: [
                    'complete',
                    'containerOrder',
                    'send'
                ],
                oppositeId: this.responseModal.oppositeId,
            }
        },
        completeChat: function() {
            this.responseModal.fade = false;
            this.getMessageCount();
            if(window.location.hash.indexOf('customer-communication') != -1){
                $('[esapp-id="customer-communication-query"]')[0].click()
            }
        }
    }
})
}, 2500);
