<template>
  <a-drawer
    wrapClassName="dk-drag-drawer"
    :visible="visible"
    @close="handleClose"
    :getContainer="getNewContainer"
    :mask="mask"
    v-bind="$attrs"
  >
    <slot></slot>
    <div class="dk-drag-drawer-footer">
      <a-space>
        <slot name="footer"></slot>
        <a-button size="small" @click="handleClose"> 关闭 </a-button>
      </a-space>
    </div>
  </a-drawer>
</template>
<script>
export default {
  name: "dk-drag-drawer",
  props: ["visible", "getContainer", "mask"],
  watch: {
    visible(val, oldValue) {
      let pageId =this.getPageId();
      let pageBody = document.querySelector(pageId);
      console.log(pageBody,'pageBody')
      if (pageBody) {
        if (val) {
          pageBody.style.position = "relative";
          pageBody.style.overflow = "hidden";
          pageBody.style.width = "100%";
          pageBody.style.height = "calc( 100vh - 70px)";
        } else {
          pageBody.style.overflow = "auto";
        }
      }
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleClose() {
      // this.show = false;
      this.visible = false;
      this.$emit("close");
    },
    getNewContainer() {
      if (this.getContainer) {
        return this.getContainer;
      }
      let pageId = this.getPageId();
      let dom = document.querySelector(pageId);
      return dom;
    },
    getPageId() {
      let pathHash = window.location.hash;
      if (pathHash.indexOf("/:") > -1) {
        return pathHash.split("/:")[0];
      }
      if (pathHash.indexOf("?") > -1) {
        return pathHash.split("?")[0];
      }
      return pathHash;
    },
  },
  beforeDestroy() {
    const dragDrawerElm = document.getElementsByClassName("dk-drag-drawer");
    if (dragDrawerElm.length < 1) {
      document.body.style.overflow = "auto";
      let tableBar = document.querySelector(".tableBar");
      if (tableBar) {
        tableBar.style.visibility = "visible";
      }
    }
  },
};
</script>
<style scoped>
.page-content-wrapper .dk-drag-drawer {
  position: fixed !important;
  bottom: 0px !important;
  top: 70px !important;
  height: calc(100vh - 70px) !important;
  transform: translateX(0px) !important;
  z-index: 11003 !important;
}
.page-content-wrapper .dk-drag-drawer /deep/ .ant-drawer-body {
  /* position: relative; */
  height: calc(100% - 80px) !important;
  padding: 12px;
}

.page-content-wrapper .dk-drag-drawer /deep/ .ant-drawer-header {
  padding: 8px;
  background-color: #7e8c9c;
  border-radius: 0px;
}

.page-content-wrapper .dk-drag-drawer /deep/ .ant-drawer-header .ant-drawer-title {
  position: relative;
  color: #ffff;
}

.page-content-wrapper .dk-drag-drawer /deep/ .ant-drawer-header .ant-drawer-close {
  height: 40px;
  line-height: 40px;
  color: #fff;
}
.page-content-wrapper .dk-drag-drawer .dk-drag-drawer-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 6px 16px;
  text-align: left;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}
</style>
