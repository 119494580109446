var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"common-viewer-container"},[(
      _vm.includeSuffix(_vm.url, [
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
        'pdf',
        'zip',
        'rar',
        '7z' ])
    )?_c('embed',{staticStyle:{"height":"100%","width":"100%"},attrs:{"src":_vm.getUrl(_vm.url)}}):(_vm.includeSuffix(_vm.url, ['png', 'jpg', 'gif', 'jpeg', 'webp']))?_c('div',{staticStyle:{"height":"100%","width":"100%"}},[_c('InlineViewer',{staticClass:"w-full h-full",attrs:{"images":[_vm.url],"options":{ backdrop: true }}})],1):_c('div',[_vm._v("\n    在线预览只支持.doc、.docx、.xls、.xlsx、.ppt、.pptx、.zip、.rar、.7z格式的文件\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }