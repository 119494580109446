<template>
  <div id="supplementary-business-modal">
    <dk-modal title="补业务登记" :fade="show" @change="toggleModal()" zindex="106">
      <div
        class="supplementary-business-info"
        v-if="driverShow && driverVarietyDto.takerName"
      >
        <div class="supplementary-business-info-title" style="padding-top: 0">
          司机信息
        </div>
        <div class="supplementary-business-info-padding" style="padding-top: 0">
          <span
            >接单人：{{ driverVarietyDto.takerName }}
            <dk-sign
              :is-self-support="driverVarietyDto && driverVarietyDto.isSelfSupport"
              :is-self-buy="driverVarietyDto && driverVarietyDto.isSelfBuy"
              :is-vip="driverVarietyDto && driverVarietyDto.isVip"
              :is-out="driverVarietyDto && driverVarietyDto.isOut"
              :vip-level="driverVarietyDto && driverVarietyDto.vipLevel"
              :is-first-taker-order="
                driverVarietyDto && driverVarietyDto.isFirstTakeOrder
              "
              :is-temporary="driverVarietyDto && driverVarietyDto.temporaryFlag"
            >
            </dk-sign>
          </span>
          <span class="driver-info">
            {{ driverVarietyDto.takerPhone }}
            <dk-icon-phone
              v-if="driverVarietyDto.takerPhone"
              :value="driverVarietyDto.takerPhone"
            ></dk-icon-phone
          ></span>
          <span class="driver-info">{{ driverVarietyDto.truckPlateNumber }}</span>

          <span style="margin-left: 15px"
            >司机名:{{ driverVarietyDto.driverName }}
            <dk-sign
              :is-self-support="driverVarietyDto && driverVarietyDto.isSelfSupport"
              :is-self-buy="driverVarietyDto && driverVarietyDto.isSelfBuy"
              :is-vip="driverVarietyDto && driverVarietyDto.isVip"
              :is-out="driverVarietyDto && driverVarietyDto.isOut"
              :vip-level="driverVarietyDto && driverVarietyDto.vipLevel"
              :is-first-taker-order="
                driverVarietyDto && driverVarietyDto.isFirstTakeOrder
              "
              :is-temporary="driverVarietyDto && driverVarietyDto.temporaryFlag"
            >
            </dk-sign>
          </span>
          <span class="driver-info">
            {{ driverVarietyDto.driverPhone }}
            <dk-icon-phone
              v-if="driverVarietyDto.driverPhone"
              :value="driverVarietyDto.driverPhone"
            ></dk-icon-phone
          ></span>
          <span class="driver-info">{{ driverVarietyDto.truckPlateNumber }}</span>
        </div>
      </div>
      <div class="supplementary-business-info">
        <div
          class="supplementary-business-info-title"
          v-if="orderInfoList && orderInfoList.length > 0"
        >
          需要补业务的订单信息
        </div>
        <div
          class="supplementary-business-info-padding supplementary-business-info-color"
          v-for="(item, index) in orderInfoList"
          :key="index"
          :class="{ 'border-line': index === 0 && orderInfoList.length > 1 }"
        >
          <div>
            <el-row :gutter="20" class="task-margin">
              <el-col :span="6"
                ><span>提单号：</span>
                <sapn
                  >{{ item.blNo }}
                  <img
                    v-if="item.blNo"
                    @click.stop="copyClipboard(item.blNo)"
                    src="../images/icon_copy.png"
                    alt=""
                    style="
                      width: 16px;
                      height: 16px;
                      cursor: pointer;
                      vertical-align: text-top;
                    "
                  />
                </sapn>
              </el-col>
              <el-col :span="6">
                <span
                  >订单号：
                  <span
                    >{{ item.orderNo }}
                    <img
                      v-if="item.orderNo"
                      @click.stop="copyClipboard(item.orderNo)"
                      src="../images/icon_copy.png"
                      alt=""
                      style="
                        width: 16px;
                        height: 16px;
                        cursor: pointer;
                        vertical-align: text-top;
                      "
                  /></span>
                </span>
              </el-col>
              <el-col :span="6">
                <span
                  >箱号：
                  <span
                    >{{ item.containerNumber }}
                    <img
                      v-if="item.containerNumber"
                      @click.stop="copyClipboard(item.containerNumber)"
                      src="../images/icon_copy.png"
                      alt=""
                      style="
                        width: 16px;
                        height: 16px;
                        cursor: pointer;
                        vertical-align: text-top;
                      "
                  /></span>
                </span>
              </el-col>
              <el-col :span="6">
                <span
                  >箱型：<span>{{ item.containerSize }}</span></span
                >
              </el-col>
            </el-row>

            <el-row :gutter="20" class="task-margin">
              <el-col :span="6">
                <span
                  >做箱时间： <span> {{ item.planArrivalTime }}</span></span
                >
              </el-col>
              <el-col :span="6">
                <span
                  >最晚进港时间：{{ getDateFormat(item.requiredGateInTime) }}
                  <span> </span
                ></span>
              </el-col>
              <el-col :span="6">
                <span
                  >截单时间：{{ getDateFormat(item.documentaryOffTime) }} <span> </span
                ></span>
              </el-col>
              <el-col :span="6">
                <span
                  >货重： <span>{{ item.weight }}吨</span></span
                >
              </el-col>
            </el-row>

            <el-row :gutter="20" class="task-margin">
              <el-col :span="6">
                <span
                  >客服：<span>{{ item.customerServer }}</span></span
                >
              </el-col>
              <el-col :span="12">
                <span
                  >客户： <span>{{ item.customer }}</span></span
                >
              </el-col>
              <el-col :span="6">
                <span> {{ item.boundType }}</span>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <span
                  >堆场： <span>{{ item.depot }} </span></span
                >
              </el-col>
              <el-col :span="6">
                <span
                  >港区： <span>{{ item.portDistrict }}</span></span
                >
              </el-col>
              <el-col :span="12">
                <el-tooltip effect="dark" :content="item.doorAddress" placement="top">
                  <span class="singe-line">门点地址:{{ item.doorAddress }}</span>
                </el-tooltip>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="supplementary-business-info">
        <div class="supplementary-business-info-title" style="padding-bottom: 0">
          信息登记
        </div>
        <div class="supplementary-business-info-padding">
          <div style="width: 60%">
            <el-form :model="modalForm" ref="modalForm" label-width="100px" size="small">
              <el-form-item
                label="补业务原因:"
                prop="reason"
                :rules="[{ required: true, message: '请选择补业务原因' }]"
              >
                <el-select
                  style="width: 200px"
                  :clearable="true"
                  v-model="modalForm.reason"
                  filterable
                  remote
                  placeholder="请输入关键词"
                  :remote-method="remoteSearch"
                  @change="remoteChange"
                  @focus="supplementaryBusinessFocus"
                >
                  <el-option
                    v-for="item in tempList"
                    :key="item.value"
                    :label="item.label"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <!-- <dk-search-select :select-width="200" :label-width="0" title="" :options="tempList"
                                        :clearable="true" :focus="supplementaryBusinessFocus"
                                        :remote-method="remoteSearch" v-model="modalForm.reason"
                                        @change="remoteChange" /> -->
                            </el-form-item>
                            <el-form-item label="补业务方式:" prop="type" :rules="[
            { required: true, message: '请选择补业务方式' },
        ]">
                                <el-radio-group v-model="modalForm.type">
                                    <el-radio :label="0">补一车</el-radio>
                                    <el-radio :label="1">挑一车</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="补业务趟数:" prop="number" :rules="[
            { required: true, message: '补业务趟数不能为空' },
            { type: 'number', message: '业务趟数必须为数字值' }
        ]">
                                <el-input style="width:200px" v-model.number="modalForm.number" placeholder="请选择补业务趟数"
                                    autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="港口:" prop="portId" :rules="[
                                    { required: true, message: '请选择港口' },
                                ]">
                                <!-- <dk-select-port v-model="modalForm.portId" no-label="true" :needShowAll="false" :needEmpty="false"></dk-select-port> -->
                                <!-- <el-select style="width:200px" :clearable="true" v-model="modalForm.portId" filterable>
                                    <el-option v-for="item in portList" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select> -->
                                <dk-port-select-new
                                    :show-label="false"
                                    :tags="['trailerPort']"
                                    select-width="200"
                                    v-model="modalForm.portId"
                                    @change="portChange">
                                </dk-port-select-new>
                            </el-form-item>
                            <el-form-item label="备注:" prop="remark">
                                <el-input type="textarea" placeholder="请输入内容" v-model="modalForm.remark" maxlength="50"
                                    show-word-limit>
                                </el-input>
                            </el-form-item>
                            <!-- <el-form-item>
                                        <el-button type="primary" @click="submitForm('modalForm')">提交</el-button>
                                        <el-button @click="resetForm('modalForm')">重置</el-button>
                                    </el-form-item> -->
            </el-form>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="supplementary-business-modal-footer">
          <el-button @click="toggleModal('modalForm')" size="medium">关闭</el-button>
          <el-button
            style="margin-left: 20px"
            size="medium"
            type="primary"
            @click="submitForm('modalForm')"
            :loading="buttonLoading"
            >确定</el-button
          >
        </div>
      </div>
    </dk-modal>
  </div>
</template>

<script>
import dkModal from "dk-modal";
import dkSignList from "dk-sign-list";
import dkIconPhone from "dk-icon-phone";
import dkSelect from "dk-select";
import dkSearchSelect from "./dk-search-select.vue";
import dKselectPort from "dk-select-port";
import { fetchNewPortList } from "../api/basicData";
import dkPortSelectNew from "./dk-port-select-new.vue";
export default {
    name: "dk-supplementary-business-modal",
    data() {
        return {
            driverVarietyDto: {
                driverName: '',
                driverPhone: '',
                truckPlateNumber: '',
                isSelfSupport: false,
                isVip: false,
                vipLevel: '',
                takerName:'',
                takerPhone:'',
            },
            modalForm: {
                reason: '',
                type: '',
                number: void 0,
                remark: '',
                portId: '',
            },
            supplementaryBusinessReasonList: [
                { label: '工厂临时多地装', value: '工厂临时多地装' },
                { label: '门点业务取消', value: '门点业务取消' },
                { label: '回来早等开港', value: '回来早等开港' },
                { label: '货物装超重', value: '货物装超重' },
                { label: '工厂装卸货时间太长', value: '工厂装卸货时间太长' },
                { label: '帮忙做短驳', value: '帮忙做短驳' },
                { label: '帮忙做难做的业务', value: '帮忙做难做的业务' },
                { label: '其他', value: '其他' }
            ],
            tempList: [],
            orderInfoList: [],
            buttonLoading: false,
            portList: [],
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        driverShow: {
            type: Boolean,
            default: true
        },
        config: {
            type: Object,
            default: {}
        },
        type: {
            type: String,
            default: 'DDFD'
        },
        portId: {
            type: String,
            default: ''
        },
        config:{
            type:Object,
            default:{}
        },
    },
    components: {
        "dk-modal": dkModal,
        "dk-sign": dkSignList,
        "dk-icon-phone": dkIconPhone,
        "dk-select": dkSelect,
        "dk-search-select": dkSearchSelect,
        "dk-select-port": dKselectPort,
        "dk-port-select-new": dkPortSelectNew,
    },
    computed: {
        shwoTruckBtn: function () {
            return this.key == 'driver'
        },
    },
    created() {
    },
    mounted() {
        // this.getPorts()
        this.tempList = _.cloneDeep(this.supplementaryBusinessReasonList)
    },
    methods: {
        portChange(val){
            this.modalForm.portId = val
        },
        // 获取司机信息
        getDriverInfo(data){
        _.util.ajax_get("/driver/detail", { id:data }, res => {                
                let detail = JSON.parse(res.data.value || '')
                this.driverVarietyDto.takerName = detail.name
                this.driverVarietyDto.takerPhone = detail.takerPhone
                this.driverVarietyDto.truckPlateNumber = detail.truckPlateNumber
                this.driverVarietyDto.driverName = detail.driverName
                this.driverVarietyDto.driverPhone = detail.driverPhone
                // console.log('司机详情',detail)
            });
        },
        // getPorts: function () {
        //     var that = this;
        //     var arr = [];
        //     _.util.ajax_get("/common/findPort/list", {}, (res) => {
        //         var list = JSON.parse(res.data.value);
        //         list.forEach((v) => {
        //             if (v.portId) {
        //                 arr.push({
        //                     value: v.portId,
        //                     label: v.portName,
        //                 });
        //             }
        //         });
        //         that.portList = arr;
        //     });
        // },
        // 获取订单信息
        getOrderInfo() {
            let that = this
            _.util.ajax_get_gw('/cds-dispatch-web/compensate/getInfo', { taskId: this.config.taskId }, function (res) {
                if (res && res.success) {
                    that.orderInfoList = res.data
                }
                if (res && !res.success) {
                    _.ui.notify({ message: res.msg, type: 'warning' })
                }
                console.log(res, '---->');
            })
        },
        // 失焦时重新赋值
        supplementaryBusinessFocus() {
            this.tempList = _.cloneDeep(this.supplementaryBusinessReasonList)
        },
        //搜索时的方法
        remoteSearch(val) {
            console.log(val);
            if (val) { //val存在
                this.tempList = this.tempList.filter((item) => {
                    if (item.label.indexOf(val) !== -1) {
                        return true
                    }
                })
            } else { //val为空时，还原数组
                this.tempList = this.supplementaryBusinessReasonList;
            }
        },
        remoteChange(e) {
            this.tempList = this.supplementaryBusinessReasonList;
            console.log(e);
            console.log(this.modalForm.reason);
            this.modalForm.reason = e
        },
        submitForm(formName) {
            let that = this
            that.$refs[formName].validate((valid) => {
                if (valid) {
                    if (!(!isNaN(parseFloat(that.modalForm.number)) && isFinite(that.modalForm.number)) || that.modalForm.number < 0 || that.modalForm.number > 10) {
                        _.ui.notify({ message: '补业务趟数必须是0~10范围内的数字', type: 'warning' })
                        return
                    }
                    that.buttonLoading = true
                    console.log(valid, 'valid==>');
                    console.log(that.modalForm, that.config);
                    let params = {}
                    if (that.type === 'DDFD') {
                        params = {
                            type: that.modalForm.type,
                            count: that.modalForm.number,
                            reason: that.modalForm.reason.value,
                            remark: that.modalForm.remark,
                            needCompensateTaskId: that.config.taskId,
                            registrationEntrance: 1,
                            portId: that.modalForm.portId
                        }
                    } else if (that.type === 'SJRX') {
                        params = {
                            driverId: that.config.driverPostingInfo.takerId,
                            driverName: that.config.driverPostingInfo.takerName,
                            truckPlateNumber: that.config.driverPostingInfo.truckPlateNumber,
                            type: that.modalForm.type,
                            count: that.modalForm.number,
                            reason: that.modalForm.reason.value,
                            remark: that.modalForm.remark,
                            needCompensateTaskId: that.config.taskId,
                            registrationEntrance: 2,
                            portId: that.modalForm.portId,
                        }
                    } else if (that.type === 'YYLY') {
                        params = {
                            // driverId: that.config.driverId,
                            // driverName: that.config.driverName,
                            // truckPlateNumber: that.config.truckPlateNumber,
                            type: that.modalForm.type,
                            count: that.modalForm.number,
                            reason: that.modalForm.reason.value,
                            remark: that.modalForm.remark,
                            needCompensateTaskId: that.config.taskId,
                            registrationEntrance: 3,
                            portId: that.modalForm.portId,
                        }
                        if (that.config.driverVarietyDto.id) {
                            params.driverId = that.config.driverVarietyDto.id
                            params.driverName = that.config.driverVarietyDto.name
                            params.truckPlateNumber = that.config.driverVarietyDto.truckPlateNumber
                        }
                    }
                    console.log(params, 'params');
                    _.util.ajax_submit2('/cds-dispatch-web/compensate/insert', params, function (res) {
                        if (res && res.success) {
                            _.ui.notify({ message: '提交成功', type: 'success' })
                            that.toggleModal('modalForm')
                        }
                        if (res && !res.success) {
                            _.ui.notify({ message: res.msg, type: 'warning' })
                        }
                        that.buttonLoading = false
                        // console.log(res, '---->');
                    }, function () {
                        that.buttonLoading = false
                    })
                    // this.resetForm(formName);
                } else {
                    if (that.modalForm.reason === '') {
                        _.ui.notify({ message: '请填写原因', type: 'warning' })
                        return false
                    }
                    if (that.modalForm.type === '') {
                        _.ui.notify({ message: '请选择补业务方式', type: 'warning' })
                        return false
                    }
                    if (that.modalForm.count === '') {
                        _.ui.notify({ message: '请填写补业务趟数', type: 'warning' })
                        return false
                    }
                    if (that.modalForm.portId === '') {
                        _.ui.notify({ message: '请选择港口', type: 'warning' })
                        return false
                    }
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        toggleModal(value) {
            if (value) {
                this.resetForm(value);
            }
            this.$emit('change');
        },
        copyClipboard(val) {
            _.util.copyClipboard(val);
        },
        getDateFormat(value) {
            if (!value) {
                return '-'
            }
            return _.util.dateFormat(value)
        }
    },
    watch: {
        show: function (newValue, oldValue) {
            if (newValue) {
                let that = this
                this.buttonLoading = false
                this.getOrderInfo()
                this.modalForm.portId = this.config.portId
                // console.log(this.portId, 222, this.config, this.$props);
                // 加载的时候初始化数据 
                if (this.config && this.driverShow) {
                    if (this.type === 'YYLY') {
                        this.driverVarietyDto = this.config.driverVarietyDto
                        this.driverVarietyDto.takerName = this.config.driverVarietyDto.name
                        this.driverVarietyDto.driverPhone = this.config.driverVarietyDto.belongedDriverPhone
                        this.driverVarietyDto.driverName = this.config.driverVarietyDto.belongedDriverName
                        // console.log('--->', this.driverVarietyDto);
                        return
                    }else if(this.type === 'SJRX'){
                        that.getDriverInfo(that.config.driverPostingInfo.takerId)
                        this.driverVarietyDto.isSelfSupport = this.config.isSelfSupport
                        this.driverVarietyDto.isVip = this.config.isVIP
                        this.driverVarietyDto.vipLevel = this.config.vipLevel
                        return 
                    }
                    // console.log('taskInfoList', this.config,);
                }
            } else {
                this.resetForm('modalForm')
            }
        }
    }
};
</script>
<style>
#supplementary-business-modal .supplementary-business-info-title {
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 10px;
}

#supplementary-business-modal .supplementary-business-info-padding {
  padding: 15px 24px 8px;
}

#supplementary-business-modal .border-line {
  border-bottom: 1px solid #c7cad8;
}

#supplementary-business-modal .singe-line {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  max-width: 450px;
  display: inline-block;
}

#supplementary-business-modal .supplementary-business-info-color {
  background-color: #f0f4fc;
  border-radius: 2px;
}

#supplementary-business-modal .driver {
  padding: 0 12px;
}

#supplementary-business-modal .driver-info {
  margin-left: 24px;
}

#supplementary-business-modal .supplementary-business-info {
  padding: 12px 12px 0px;
}

#supplementary-business-modal .task-margin {
  margin-bottom: 8px;
}

#supplementary-business-modal .modal-footer {
  text-align: left !important;
}

#supplementary-business-modal .supplementary-business-select {
  display: flex;
  align-items: center;
}

#supplementary-business-modal .supplementary-business-require::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

#supplementary-business-modal .supplementary-business-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>