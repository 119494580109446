<template>
  <el-dropdown @command="item => handleChange(item)">
    <div style="display: flex; align-items: center">
      <img
        :src="currentLocale.img"
        alt="National flag"
        style="max-width: 2rem;height: 2rem; border-radius: 4px; margin-right: 5px"
      />
      <span style="color: #fefefe;">{{ currentLocale.label }}</span>
    </div>
    <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="item in locales" :key="item.value" :command="item.value">
          <div style="display: flex; align-items: center">
            <img
              :src="item.img"
              alt="National flag"
              style="max-width: 2rem;height: 2rem; border-radius: 4px; margin-right: 5px"
            />
            <span>{{ item.label }}</span>
          </div>
        </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
const { iamHttp } = require('../api/_http')

export default {
  name: "dk-locale-select",
  data() {
    return {
      locale: "zh-CN",
      locales: [
        {
          "value": "en-US",
          "label": "English",
          "img": "/public/flags/us.svg"
        },
        {
          "value": "zh-CN",
          "label": "中文",
          "img": "/public/flags/cn.svg"
        },
        {
          "value": "vi-VN",
          "label": "Tiếng Việt",
          "img": "/public/flags/vn.svg"
        }
      ],
    };
  },
  computed: {
    currentLocale() {
      return this.locales.find(item => item.value === this.locale) || this.locales.find(item => item.value === 'zh-CN')
    }
  },
  mounted() {
    this.initLanguage();
    this.getI18nLanguage()
  },
  methods: {
    initLanguage() {
      if (localStorage.getItem('language')) {
        this.locale = localStorage.getItem('language')
      } else {
        let language = (navigator.language || navigator.languages[0])
        this.locale = this.locales.find(item => item.value === language) ? language : 'zh-CN'
        localStorage.setItem('language', language)
      }
    },
    async getI18nLanguage() {
      const { data } = await iamHttp({
        url: "/base-biz-data-server/query/i18n/language/support?i18n=false",
        method: "get",
      })
      if (data && data.data) {
        this.locales = Object.keys(data.data).map(key => {
          return {
            value: key,
            label: data.data[key],
            img: `/public/flags/${key.split("-")[1]?.toLowerCase()}.svg`,
          };
        });
      }
    },
    handleChange(value) {
      this.locale = value
      localStorage.setItem('language', value)
      location.reload();
    }
  }
};
</script>
