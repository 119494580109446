<template>
  <el-button
    v-bind="$attrs"
    :type="type"
    :plain="plain"
    :disabled="disabled"
    :size="size"
    :round="round"
    :circle="circle"
    :icon="icon"
    :loading="loading"
    :native-type="nativeType"
    @click="onClick"
    :style="type === 'f2ePrimary' ? `line-height: 1.44;padding: 6px 12px;color: #fff;background-color: #337ab7;border-color: #337ab7;vertical-align: middle;font-size: 13px;font-weight: 400; ${style}` : style">
    <slot v-if="$slots.default"></slot>
  </el-button>
</template>

<script>

export default {
  name: "dk-debounce-button",
  props: {
    type: {
      type: String,
      default: "f2ePrimary", // f2ePrimary: f2e主题按钮
    },
    plain: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: undefined, // medium / small / mini
    },
    stopPropapation: {
      type: Boolean,
      default: true,
    },
    round: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    nativeType: {
      type: String,
      default: "button",
    },
    loadingTime: {
      type: Number,
      default: 5000,
    },
    style: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods:{
    onClick(event) {
      if (this.stopPropapation) {
        event.stopPropagation();
      }

      if (this.loading) { return };

      this.loading = true
      this.$emit('click', () => {
        this.loading = false
        clearTimeout(this.timer)
      })
      this.timer = setTimeout(() => {
        this.loading = false
      }, this.loadingTime || 0)
    }
  }
};
</script>
