<template>
  <el-drawer
      ref="myDrawer"
      title="反馈系统问题和需求"
      :visible.sync="dialogVisible"
      direction="rtl"
      :modal="false"
      :wrapperClosable="false"
      :with-header="false"
      size="35%">
    <div class="custom-header">
      反馈系统问题和需求
    </div>

    <!-- <div style="height:25vh">
      <MultipleUpload
          :uploadConfig="uploadConfig"
          ref="multipleUpload"
      ></MultipleUpload>
    </div> -->

    <el-form label-width="80px" :model="ruleForm" ref="ruleForm" class="feedback-form" label-position="top">
        <!-- 选择文件 -->
        <el-form-item label="文件上传" prop="feedbackFileUrlList">
        <MultipleUpload
          class="feedback-upload"
          :showTitle = false
          :uploadConfig="uploadConfig"
          ref="multipleUpload"
          style="height:250px"
      ></MultipleUpload>
        <template v-slot:label>
          <div class="flex-start-center">
            <i class="fa fa-asterisk"></i>
            <span>文件上传</span>
          </div>
        </template>
      </el-form-item>
      <!-- 提单号 -->
      <el-form-item label="提单号" prop="blNo">
        <el-input
            placeholder="请输入提单号"
            v-model="ruleForm.blNo"
            class="full-width"
        />
      </el-form-item>

      <!-- 箱号 -->
      <el-form-item label="箱号" prop="number">
        <el-input
            placeholder="请输入箱号"
            v-model="ruleForm.number"
            class="full-width"
        />
      </el-form-item>

      <!-- 车牌号 -->
      <el-form-item label="车牌号" prop="truckPlateNumber">
        <el-input
            placeholder="请输入车牌号"
            v-model="ruleForm.truckPlateNumber"
            class="full-width"
        />
      </el-form-item>

      <!-- 订单号 -->
      <el-form-item label="订单号" prop="orderNo">
        <el-input
            placeholder="请输入订单号"
            v-model="ruleForm.orderNo"
            class="full-width"
        />
      </el-form-item>

      <!-- 备注 -->
      <el-form-item label="备注" prop="remark">
        <el-input
            type="textarea"
            v-model="ruleForm.remark"
            class="full-width"
            maxlength="255"
            show-word-limit
            :rows="4"
        />
        <template v-slot:label>
          <div class="flex-start-center">
            <i class="fa fa-asterisk"></i>
            <span>备注</span>
          </div>
        </template>
      </el-form-item>

      <!-- 操作按钮 -->
      <el-form-item class="footer-btns">
        <!-- <el-button type="primary" @click="submitForm">提交</el-button> -->
        <dk-debounce-button type="primary" @click="submitForm($event)">提交</dk-debounce-button>
        <el-button @click="dialogVisible = false">关闭</el-button>
        <!-- <el-button @click="handleClose">关闭</el-button> -->
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>
import MultipleUpload from "./dk-multiple-types-upload-file/feed.vue";
import dkDebounceButton from 'dk-debounce-button'
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        blNo: '',
        number: '',
        truckPlateNumber: '',
        orderNo: '',
        remark: '',
        feedbackFileUrlList: [],
        fileName: ''
      },
      uploadConfig: {
        code: "document-and-certificate",
        bizKey: "customs-declaration",
        bizType: "customs-declaration",
        showFileSearch: true,
        autoUpload: true,
      },
      fileUploadInfo:{
        fileType:["jpg", "png", "jpeg", "gif", "JPG", "PNG", "JPEG", "GIF"],
      }
    };
  },
  components: {
    MultipleUpload,
    dkDebounceButton
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.$nextTick(() => {
          const wrappers = document.querySelectorAll('.el-drawer__wrapper');
          wrappers.forEach(wrapper => {
            wrapper.classList.add('drawer-feedback-wrapper');
          });
        });
      }
    }
  },
  methods: {

    checkValue(){
      const {feedbackFileUrlList,remark} = this.ruleForm
      if(feedbackFileUrlList.length === 0 || feedbackFileUrlList.some(item=>!item)){
        _.ui.notify({
          type:'warning',
          message: "请上传文件或图片"
        })
        return true
      }
      if (!remark) {
        _.ui.notify({
          type:'warning',
          message: "请填写备注"
        })
        return true
      }
    },
    async submitForm(finish) {
      const result = await this.$refs.multipleUpload.getValues();
      if (result) {
        result.forEach(item => {
          if (item.file && !this.ruleForm.feedbackFileUrlList.includes(item.fileUrl)) {
            this.ruleForm.feedbackFileUrlList.push(item.fileUrl)
          }
        });
      }
      if (this.checkValue()) {
        finish && finish()
        return
      }
      _.util.ajax_submit("/systemIssueFeedback/submit", this.ruleForm, (res) => {
          if (res.success) {
            _.ui.notify({ message: "提交成功" })
            this.handleClose()
          }
        }, () => {
          this.dialogVisible = false;
        },
        finish
      );
    },
    handleClose() {
      this.dialogVisible = false;
      this.$refs.ruleForm.resetFields();
      this.$refs.multipleUpload.resetValues();
    },
  }
};
</script>

<style>
.custom-header {
  background-color: #5A738E;
  color: #FFFFFF;
  text-align: left;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}


.feedback-form {
  background-color: #fff;
  padding: 16px;
}

.full-width {
  width: 100%;
}

.footer-btns {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.footer-btns .el-button[type="primary"] {
  background-color: #409eff;
  border-color: #409eff;
  color: #fff;
}

.footer-btns .el-button[type="primary"]:hover {
  background-color: #66b1ff;
  border-color: #66b1ff;
}

.drawer-feedback-wrapper {
  pointer-events: none !important;
}

.drawer-feedback-wrapper .el-drawer {
  pointer-events: auto !important;
}
.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.feedback-upload .feed-upload-file-content{
  padding-left: 0;
}
.feedback-upload .feed-base-upload{
  margin-left: 0;
}
.feedback-form .fa.fa-asterisk{
  font-size: 12px;
  font-weight: normal;
  color: #e55a5f;
  transform: scale(0.8);
}
</style>
