<template>
  <div id="dk-inline-viewer">
    <img v-for="src in images" :key="src" style="height: 1px; opacity: 0" :src="src" />
  </div>
</template>
<script>
import Viewer from "viewerjs";
export default {
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      viewer: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      setTimeout(() => {
        if (!this.viewer) {
          const options = Object.assign(
            {
              backdrop: false,
              inline: true,
              button: false,
              toolbar: {
                prev: true,
                rotateLeft: true,
                zoomIn: true,
                zoomOut: true,
                rotateRight: true,
                next: true,
                // myClose,
              },
            },
            this.options
          );
          this.viewer = new Viewer(document.getElementById("dk-inline-viewer"), options);
        } else {
          this.viewer.update;
        }
      }, 300);
    },
  },
  beforeDestroy() {
    this.viewer = null;
  },
};
</script>
<style src="./viewer.min.css"></style>
