import axios from "axios"
import { getMiniType } from "./mimeType";
import dayjs from "dayjs";
export function urlToFile(url, filename) {
  return fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      return new File([blob], filename, { type: blob.type });
    });
}

export function debounceFun(fn, delay = 500) {
  let timer = null;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}
export function fileToBase64(file) {
  // let fileBase64 = ''
  return new ((resolve, reject) => {
    const reader = new FileReader();
    // 文件读取完成时触发load事件
    reader.onload = function (e) {
      resolve(e.currentTarget.result);
    };
    // 以DataURL的形式读取文件内容
    reader.readAsDataURL(file);
  })();
}
export function getFileSuffix(fileName) {
  if (!fileName) {
    return null;
  }
  const fileSuffix = fileName.split(".").pop().toLowerCase();
  return fileSuffix;
}
export function getFileMD5(file) {
  return CryptoJS.MD5(file.name + file.size).toString();
}

export function urlToBold(url) {
  return fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      return {
        success: true,
        blob: blob
      };
    })
    .catch((err) => {
      console.log(err, "文件下载失败！");
      return {
        success: false,
        msg: "文件下载失败！",
      };
    });
}

export function newUrlToFile(url, filename) {
  return fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      let suffix = getFileSuffix(filename);
      let type = getMiniType(suffix);
      if (!type) {
        return {
          success: false,
          msg: "文件类型不支持！",
        };
      }
      return {
        success: true,
        file: new File([blob], filename, { type: type }),
      };
    })
    .catch((err) => {
      console.log(err, "文件下载失败！");
      return {
        success: false,
        msg: "文件下载失败！",
      };
    });
}

export function systemIssueFeedback(url,param) {
  return fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        return new File([blob], filename, { type: blob.type });
      });
}
// 搜索配置
export const searchItems = [
  {
    label: "文件名",
    key: "fileName",
    type: "input",
    itemSpan: 12,
    placeholder: "请输入文件名",
  },
  {
    label: "创建时间",
    key: "createTime",
    type: "rangePicker",
    itemSpan: 12,
    placeholder: "请选择注册时间",
    handleValue: function (value, type, values, key) {
      if (value.length) {
        values.createTimeStart = dayjs(value[0]).format("YYYY-MM-DD 00:00:00");
        values.createTimeEnd = dayjs(value[1]).format("YYYY-MM-DD 23:59:59");
      }
      delete values.createTime;
    },
  },
  {
    type: "input",
    label: "客户",
    fieldName: "customerId",
    itemSpan: 12,
    scopedSlots: { customRender: "customerId" },
    placeholder: "请选择客户",
  },
  {
    type: "select",
    label: "来源",
    key: "typeCode",
    itemSpan: 12,
    children: [{ label: "企点", value: "企点" }],
    placeholder: "请选择来源",
  },
  {
    label: "上传人",
    key: "uploadName",
    type: "input",
    itemSpan: 12,
    placeholder: "请输入上传人",
  },
  {
    type: "select",
    label: "文件类型",
    key: "fileType",
    itemSpan: 12,
    children: [
      { label: "图片", value: "image" },
      { label: "文件", value: "file" },
      { label: "视频", value: "shortVideo" },
    ],
    placeholder: "请选择文件类型",
  },
];

export const tableColumns = [
  // {
  //   title: "操作",
  //   width: 80,
  //   dataIndex: "operation",
  //   scopedSlots: { customRender: "operation" },
  // },
  // {
  //   title: "文件名",
  //   dataIndex: "fileName",
  //   ellipsis: true,
  //   width: 200,
  //   key: "fileName",
  // },
  {
    title: "客户",
    dataIndex: "customerName",
    ellipsis: true,
    width: 200,
    key: "customerName",
  },

  // {
  //   title: "大小",
  //   dataIndex: "size",
  //   width: 60,
  //   key: "size",
  // },
  {
    title: "创建时间",
    dataIndex: "createTime",
    width: 120,
    key: "createTime",
  },
  {
    title: "来源说明",
    dataIndex: "fileDescription",
    width: 200,
    key: "fileDescription",
    ellipsis: true,
  },
  {
    title: "上传人",
    dataIndex: "createBy",
    width: 60,
    key: "createBy",
  },
  {
    title: "来源",
    dataIndex: "fileSource",
    width: 200,
    key: "fileSource",
  },

];
export function objectToURLSearchParams(obj) {
  const searchParams = new URLSearchParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      searchParams.append(key, obj[key]);
    }
  }
  return searchParams.toString();
}

export function getFileUploadInfo(params) {
  const payload = objectToURLSearchParams(params)
  return axios("/gw/filemanager/client/http/config?" + payload, {
    headers: {
      Authorization: localStorage.getItem('login-token')
    }
  }).then((res) => {
    if (res.status === 200) {
      if (res.data.success) {
        console.log(res.data, 'res.data')
        const values = res.data.data.fileType;
        if (values) {
          res.data.data.fileType = values.split(',')
          return res.data.data
        }
        return res.data.data
      }
      return null
    }
  })

}
