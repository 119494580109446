<template>
  <div
      ref="uploadRef"
      class="f2e-fed-dk-multiple-types-upload-file"
      @click.prevent
      @dragover="handleDragover"
      @drop="handleDrop"
  >
    <div class="upload-content">
      <p class="ant-upload-drag-icon">
        <img :src="uploadIcon" />
        <!-- <i
          class="el-icon-upload"
          style="font-size: 70px; color: #c0c4cc; margin-bottom: 10px"
        ></i> -->
      </p>
      <p class="ant-upload-text">
        在此处粘贴（Ctrl + V）文件，或拖到此处，或选择本地文件
        <a-upload :multiple="true" :customRequest="handleCustomRequest" :fileList="[]">
          <span style="color: #1890ff; cursor: pointer; font-size: 18px">本地文件</span>
        </a-upload>
      </p>
    </div>
  </div>
  <!-- <a-upload-dragger
    name="file"
    :multiple="true"
    :fileList="[]"
    :customRequest="handleCustomRequest"
  >
    <div class="upload-content">
      <p class="ant-upload-drag-icon">
        <i
          class="el-icon-upload"
          style="font-size: 70px; color: #c0c4cc; margin-bottom: 10px"
        ></i>
      </p>
      <p class="ant-upload-text">在此处粘贴（Ctrl + V）文件，或从本地选择文件</p>
    </div>
  </a-upload-dragger> -->
</template>
<script>
const addedListeners = new WeakSet();
import { getFileMD5 } from "./options";
import uploadIcon from "./icon/upload.png";
export default {
  name: "base-upload",
  props: ["getMountContent", "beforeUpload"],
  data() {
    return {
      uploadIcon,
    };
  },

  methods: {
    async handleCustomRequest({ file }) {
      this.uploadFile(file);
    },
    addListenerIfNotPresent(element, eventName, listener) {
      if (!addedListeners.has(listener)) {
        element.addEventListener(eventName, listener);
        addedListeners.add(listener);
      }
    },
    handlePaste(event) {
      const items = event.clipboardData?.items;
      if (items) {
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (item.kind === "file") {
            const file = item.getAsFile();
            this.uploadFile(file);
          }
        }
      }
    },
    async uploadFile(file) {
      const fileKey = getFileMD5(file);
      const SelectFileInfo = {
        fileKey: fileKey,
        fileName: file.name,
        file: file,
      };
      this.$emit("change", SelectFileInfo);
    },
    handleDragover(e) {
      e.preventDefault();
    },
    handleDrop(e) {
      e.preventDefault();
      // 获取拖放的文件列表
      const { files } = e.dataTransfer;
      if (files && files.length) {
        for (const item of files) {
          this.uploadFile(item);
        }
      }
    },
  },
  mounted() {
    let elm = document.body;
    if (this.getMountContent) {
      elm = this.getMountContent();
    }
    this.addListenerIfNotPresent(elm, "paste", this.handlePaste);
  },
  unmounted() {
    let elm = document.body;
    if (this.getMountContent) {
      elm = this.getMountContent();
    }
    if (elm) {
      elm.removeEventListener("paste", this.handlePaste);
    }
  },
};
</script>
<style scoped>
.f2e-fed-dk-multiple-types-upload-file {
  justify-content: center;
  align-items: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.f2e-fed-dk-multiple-types-upload-file:hover {
  border: 1px dashed #1890ff;
}
.f2e-fed-dk-multiple-types-upload-file .upload-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.f2e-fed-dk-multiple-types-upload-file .ant-upload-text {
  padding-left: 18px;
  padding-right: 18px;
  font-size: 16px;
  margin-bottom: 15px;
}

.f2e-fed-dk-multiple-types-upload-file .upload-content .ant-upload-drag-icon {
  margin-top: 30px;
}

.f2e-fed-dk-multiple-types-upload-file .upload-content .ant-upload-drag-icon >img {
  position: relative;
  width: 60px;
}
</style>
