var menuList = [
    {
      html: '/control-center/control-center.html',
      css: '/control-center/control-center.css',
      js: '/control-center/control-center.js',
      title: '工作台',
      isSingle: true,
      text: '工作台',
      href: 'control-center'
      // auth: 'DISPATCHER_CENTER',
      // parent: 'dispatch'
    },
    {
      html: '/dashboard/dashboard.html',
      css: '/dashboard/dashboard.css',
      js: '/dashboard/dashboard.js',
      title: '工作台-旧',
      isSingle: true,
      text: '工作台-旧',
      href: 'dashboard'
      // auth: 'DISPATCHER_CENTER',
      // parent: 'dispatch'
    },
    {
        html: '/unknow-permission/unknow-permission.html',
        css: '/unknow-permission/unknow-permission.css',
        js: '/unknow-permission/unknow-permission.js',
        title: '暂无权限',
        isSingle: true,
        text: '暂无权限',
        href: 'unknow-permission'
        // auth: 'DISPATCHER_CENTER',
        // parent: 'dispatch'
      },
    // 顶部菜单导航   
    //客户菜单
    {
      html: '/customer-registration/customer-registration.html',
      css: '/customer-registration/customer-registration.css',
      js: '/customer-registration/customer-registration.js',
      title: '客户登记',
      isSingle: true,
      parent: 'customer',
      text: '客户登记',
      // auth: 'CUSTOMER_STANDARD_PRICE_QUERY_LOG-M',
      auth: 'CUSTOMER_REGISTRATION',
      href: 'customer-registration'
    },
     //查价记录
    {
      html: '/price-check-record/price-check-record.html',
      css: '/price-check-record/price-check-record.css',
      js: '/price-check-record/price-check-record.js',
      title: '查价记录',
      isSingle: true,
      parent: 'customer',
      text: '查价记录',
      auth: 'PRICE_CHECK_RECORD',
      href: 'price-check-record'
    },
    {
      html: '/query-area/query-area.html',
      css: '/query-area/query-area.css',
      js: '/query-area/query-area.js',
      title: '门点区域查询',
      isSingle: true,
      parent: 'customer',
      text: '门点区域查询',
      auth: 'QUERY_AREA',
      href: 'query-area'
    },
    {
      html: '/query-extra-money/query-extra-money.html',
      css: '/query-extra-money/query-extra-money.css',
      js: '/query-extra-money/query-extra-money.js',
      title: '客户附加费用查询',
      isSingle: true,
      parent: 'customer',
      text: '客户附加费用查询',
      auth: 'QUERY_EXTRA_MONEY',
      href: 'query-extra-money'
    },
    //客户报价
    {
      html: '/standard-quotation-inquiry/standard-quotation-inquiry.html',
      css: '/standard-quotation-inquiry/standard-quotation-inquiry.css',
      js: '/standard-quotation-inquiry/standard-quotation-inquiry.js',
      title: '客户报价',
      isSingle: true,
      parent: 'customer',
      text: '客户报价',
      // auth: 'CUSTOMER_STANDARD_PRICE_QUERY_LOG-M',
      auth: 'STANDARD_QUOTATION_INQUIRY',
      href: 'standard-quotation-inquiry'
    },
    {
      html: '/transport-price-query/transport-price-query.html',
      css: '/transport-price-query/transport-price-query.css',
      js: '/transport-price-query/transport-price-query.js',
      title: '客户询价',
      isSingle: true,
      parent: 'customer',
      text: '客户询价',
      auth: 'CUSTOMER_STANDARD_PRICE_QUERY_LOG-M',
      href: 'transport-price-query'
    },
    {
      css: '/new-customer-account/new-customer-account.css',
      html: '/new-customer-account/new-customer-account.html',
      js: '/new-customer-account/new-customer-account.js',
      isSingle: true,
      title: '客户账户(新)',
      text: '客户账户(新)',
      parent: 'customer',
      auth: 'CUSTOMER_ACCOUNT_MGT-M',
      href: 'new-customer-account'
    },
    {
      html: '/query-customer/query-customer.html',
      css: '/query-customer/query-customer.css',
      js: '/query-customer/query-customer.js',
      title: '客户查询',
      isSingle: true,
      text: '客户查询',
      href: 'query-customer',
      auth: 'QUERY_CUSTOMER',
      parent: 'customer'
    },
    {
      css: '/customer-files/customer-files.css',
      html: '/customer-files/customer-files.html',
      js: '/customer-files/customer-files.js',
      isSingle: true,
      title: '客户档案',
      text: '客户档案',
      parent: 'customer',
      auth: 'YZS_CUSTOMER_DOCUMENT-M',
      href: 'customer-files'
    },
    {
      css: '/my-customer-files/my-customer-files.css',
      html: '/my-customer-files/my-customer-files.html',
      js: '/my-customer-files/my-customer-files.js',
      isSingle: true,
      title: '我的客户',
      text: '我的客户',
      parent: 'customer',
      auth: 'MY_CUSTOMER_FILES',
      href: 'my-customer-files'
    },
    {
      css: '/custmoer-real-control/custmoer-real-control.css',
      html: '/custmoer-real-control/custmoer-real-control.html',
      js: '/custmoer-real-control/custmoer-real-control.js',
      isSingle: true,
      title: '相同实控人客户',
      text: '相同实控人客户',
      parent: 'customer',
      auth: 'CUSTMOER-REAL-CONTROL',
      href: 'custmoer-real-control'
    },
    {
      css: '/custmoer-real-control-add-edit/custmoer-real-control-add-edit.css',
      html: '/custmoer-real-control-add-edit/custmoer-real-control-add-edit.html',
      js: '/custmoer-real-control-add-edit/custmoer-real-control-add-edit.js',
      isSingle: true,
      title: '新增/编辑相同实控人客户',
      text: '新增/编辑相同实控人客户',
      parent: '',
      auth: 'CUSTMOER-REAL-CONTROL',
      href: 'custmoer-real-control-add-edit'
    },
    {
      css: '/customer-consultation/customer-consultation.css',
      html: '/customer-consultation/customer-consultation.html',
      js: '/customer-consultation/customer-consultation.js',
      isSingle: true,
      title: '客户咨询',
      text: '客户咨询',
      parent: 'customer',
      auth: 'CUSTOMER_CONSULTATION-M',
      href: 'customer-consultation'
    },
    {
      css: '/appionted-task/appionted-task.css',
      html: '/appionted-task/appionted-task.html',
      js: '/appionted-task/appionted-task.js',
      isSingle: true,
      title: '达客必优拜访',
      text: '达客必优拜访',
      parent: 'customer',
      auth: 'SALES_CUSTOMER_VISIT_RECORD-M',
      href: 'appionted-task'
    },
    {
      html: '/promotion-record/promotion-record.html',
      css: '/promotion-record/promotion-record.css',
      js: '/promotion-record/promotion-record.js',
      title: '推广记录',
      isSingle: true,
      text: '推广记录',
      href: 'promotion-record',
      auth: 'OFFLINE_PROMOTION_RECORD-M',
      parent: 'customer'
    },
    {
      css: '/contract-apply-old/contract-apply-old.css',
      html: '/contract-apply-old/contract-apply-old.html',
      js: '/contract-apply-old/contract-apply-old.js',
      isSingle: true,
      title: '客户建档（旧）',
      text: '客户建档（旧）',
      parent: 'customer',
      auth: 'CUSTOMER_CONTRACT_APPLICATION-M-1',
      href: 'contract-apply-old'
    },
    {
      css: '/contract-apply/contract-apply.css',
      html: '/contract-apply/contract-apply.html',
      js: '/contract-apply/contract-apply.js',
      isSingle: true,
      title: '客户建档',
      text: '客户建档',
      parent: 'customer',
      auth: 'CUSTOMER_APPLY_AUDIT_SALES',
      href: 'contract-apply'
    },
    {
      html: '/extension-insurance-application-list/extension-insurance-application-list.html',
      css: '/extension-insurance-application-list/extension-insurance-application-list.css',
      js: '/extension-insurance-application-list/extension-insurance-application-list.js',
      title: '延保申请记录',
      isSingle: true,
      text: '延保申请记录',
      auth:"EXTENSION_INSURANCE_APPLICATION_LIST",
      parent: 'customer',
      href: 'extension-insurance-application-list'
    },
    {
      css: '/filing-application/filing-application.css',
      html: '/filing-application/filing-application.html',
      js: '/filing-application/filing-application.js',
      isSingle: true,
      title: '建档申请',
      text: '建档申请',
      auth: 'FILING_APPLICATION',
      href: 'filing-application'
    },
    {
      css: '/offline-contract-apply/offline-contract-apply.css',
      html: '/offline-contract-apply/offline-contract-apply.html',
      js: '/offline-contract-apply/offline-contract-apply.js',
      isSingle: true,
      title: '客户线下合同申请',
      text: '客户线下合同申请',
      parent: 'customer',
      auth: 'CUSTOMER_CONTRACT_APPLICATION-M-2',
      href: 'offline-contract-apply'
    },
    {
      css: '/contract-modal/contract-modal.css',
      html: '/contract-modal/contract-modal.html',
      js: '/contract-modal/contract-modal.js',
      isSingle: true,
      title: '合同管理',
      text: '合同管理',
      parent: 'customer',
      auth: 'CUSTOMER_CONTRACT_AUDIT-M',
      href: 'contract-modal'
    },
    
    {
      css: '/standard-price-query/standard-price-query.css',
      html: '/standard-price-query/standard-price-query.html',
      js: '/standard-price-query/standard-price-query.js',
      isSingle: true,
      title: '标准报价查询',
      text: '标准报价查询',
      parent: 'customer',
      auth: 'STANDARD_QUOTATION_INQUIRY-M',
      href: 'standard-price-query'
    },
    {
      html: '/multiple-packing/multiple-packing.html',
      css: '/multiple-packing/multiple-packing.css',
      js: '/multiple-packing/multiple-packing.js',
      title: '多地装价格查询',
      isSingle: true,
      text: '多地装价格查询',
      href: 'multiple-packing',
      auth: 'MULTIPLE-PACKING-M',
      parent:'customer',
    },
    {
      css: '/customer-evaluate/customer-evaluate.css',
      html: '/customer-evaluate/customer-evaluate.html',
      js: '/customer-evaluate/customer-evaluate.js',
      isSingle: true,
      title: '客户评价',
      text: '客户评价',
      parent: 'customer',
      auth: 'CUSTOMER_REVIEWS-M',
      href: 'customer-evaluate'
    },
    {
      html: '/door-management/door-management.html',
      css: '/door-management/door-management.css',
      js: '/door-management/door-management.js',
      title: '维护工厂地址',
      isSingle: true,
      text: '维护工厂地址',
      href: 'door-management',
      auth: 'FACTORY_DOOR_ADDRESS-M',
      parent: 'customer'
    },
    {
      html: '/coustomer-door-check/coustomer-door-check.html',
      css: '/coustomer-door-check/coustomer-door-check.css',
      js: '/coustomer-door-check/coustomer-door-check.js',
      title: '客户门点审核',
      isSingle: true,
      text: '客户门点审核',
      href: 'coustomer-door-check',
      auth: 'BUSINESS_STANDARD_PRICE-M-1',
      parent: 'customer',
    },
    {
      html: '/customer-complaints/customer-complaints.html',
      css: '/customer-complaints/customer-complaints.css',
      js: '/customer-complaints/customer-complaints.js',
      title: '客户投诉',
      isSingle: true,
      text: '客户投诉',
      href: 'customer-complaints',
      auth: 'CUSTOMER_COMPLAINTS-M',
      parent:'customer'
    },
    {
      html: '/customer-conversion/customer-conversion.html',
      css: '/customer-conversion/customer-conversion.css',
      js: '/customer-conversion/customer-conversion.js',
      title: '客户公海',
      isSingle: true,
      text: '客户公海',
      href: 'customer-conversion',
      auth: 'CUSTOMER_CONVERSION-M-1',
      parent:'customer'
    },
    {
      html: '/phone-promotion/phone-promotion.html',
      css: '/phone-promotion/phone-promotion.css',
      js: '/phone-promotion/phone-promotion.js',
      title: '客户电话推广',
      isSingle: true,
      text: '客户电话推广',
      href: 'phone-promotion',
      auth: 'CUSTOMER_CONVERSION-M-2',
      parent:'customer'
    },
    {
      css: '/losing-customer/losing-customer.css',
      html: '/losing-customer/losing-customer.html',
      js: '/losing-customer/losing-customer.js',
      isSingle: true,
      title: '客户维护管理',
      text: '客户维护管理',
      parent: 'customer',
      auth: 'LOSING_CUSTOMERS-M-1',
      href: 'losing-customer'
    },
    {
      css: '/lost-customers/lost-customer.scss',
      html: '/lost-customers/lost-customers.html',
      js: '/lost-customers/lost-customers.js',
      isSingle: true,
      title: '流失客户管理',
      text: '流失客户管理',
      parent: 'customer',
      auth: 'LOSING_CUSTOMERS-M-2',
      href: 'lost-customers'
    },
    {
        css: '/certification-review/certification-review.css',
        html: '/certification-review/certification-review.html',
        js: '/certification-review/certification-review.js',
        isSingle: true,
        title: '客户注册记录',
        text: '客户注册记录',
        parent: 'customer',
        auth: 'CERTIFICATION_REVIEW-M',
        href: 'certification-review'
    },
    {
      css: '/quit-request/quit-request.css',
      html: '/quit-request/quit-request.html',
      js: '/quit-request/quit-request.js',
      isSingle: true,
      title: '退出企业申请',
      text: '退出企业申请',
      parent: 'customer',
      auth: 'QUIT_REVIEW-M',
      href: 'quit-request'
    },
    {
      css: '/join-request/join-request.css',
      html: '/join-request/join-request.html',
      js: '/join-request/join-request.js',
      isSingle: true,
      title: '加入企业申请',
      text: '加入企业申请',
      parent: 'customer',
      auth: 'JOIN_REVIEW-M',
      href: 'join-request'
    },
    {
      css: '/register-review-person/register-review-person.css',
      html: '/register-review-person/register-review-person.html',
      js: '/register-review-person/register-review-person.js',
      isSingle: true,
      title: '个人注册记录',
      text: '个人注册记录',
      parent: 'customer',
      auth: 'REGISTER_REVIEW_PERSON-M',
      href: 'register-review-person'
    },
    {
      css: '/customer-query/customer-query.css',
      html: '/customer-query/customer-query.html',
      js: '/customer-query/customer-query.js',
      isSingle: true,
      title: '客户询价-无运价',
      text: '客户询价-无运价',
      parent: 'customer',
      auth: 'CUSTOMER_QUERY-M',
      href: 'customer-query'
    },
    {
      css: '/bargaining-audit/bargaining-audit.css',
      html: '/bargaining-audit/bargaining-audit.html',
      js: '/bargaining-audit/bargaining-audit.js',
      isSingle: true,
      title: '议价审核',
      text: '议价审核',
      parent: 'customer',
      auth: 'BARGAIN_REVIEW-M',
      href: 'bargaining-audit'
    },
    {
      html: '/customize-customer-bill-template/customize-customer-bill-template.html',
      css: '/customize-customer-bill-template/customize-customer-bill-template.css',
      js: '/customize-customer-bill-template/customize-customer-bill-template.js',
      title: '客户账单定制模板',
      isSingle: true,
      text: '客户账单定制模板',
      href: 'customize-customer-bill-template',
      auth: 'CUSTOMIZE-CUSTOMER-BILL-TEMPLATE-M',
      parent:'customer',
    },
    {  
      css: '/quota-warning/quota-warning.css',
      html: '/quota-warning/quota-warning.html',
      js: '/quota-warning/quota-warning.js',
      isSingle: true,
      title: '授信额度预警',
      text: '授信额度预警',
      parent: 'customer',
      auth: 'QUOTA_WARNING-M',
      href: 'quota-warning'
    },
    {
      html: '/approval-of-credit-line/approval-of-credit-line.html',
      css: '/approval-of-credit-line/approval-of-credit-line.css',
      js: '/approval-of-credit-line/approval-of-credit-line.js',
      title: '额度授信审批',
      isSingle: true,
      parent: 'customer',
      text: '额度授信审批',
      auth: 'APPROVAL_OF_CREDIT_LINE',
      href: 'approval-of-credit-line'
    },
    {
      html: '/customer-line-of-credit/customer-line-of-credit.html',
      css: '/customer-line-of-credit/customer-line-of-credit.css',
      js: '/customer-line-of-credit/customer-line-of-credit.js',
      title: '客户授信额度',
      isSingle: true,
      parent: 'customer',
      text: '客户授信额度',
      auth: 'CUSTOMER_LINE_OF_CREDIT',
      href: 'customer-line-of-credit'
    },
    {  
      css: '/free-riding-subscription-query/free-riding-subscription-query.css',
      html: '/free-riding-subscription-query/free-riding-subscription-query.html',
      js: '/free-riding-subscription-query/free-riding-subscription-query.js',
      isSingle: true,
      title: '顺风车订阅查询',
      text: '顺风车订阅查询',
      parent: 'free-ride',
      auth: 'FREE_RIDING_SUBSCRIPTION_QUERY',
      href: 'free-riding-subscription-query'
    },
    {
      css: '/customer-ordering-car/customer-ordering-car.css',
      html: '/customer-ordering-car/customer-ordering-car.html',
      js: '/customer-ordering-car/customer-ordering-car.js',
      isSingle: true,
      title: '客户订车',
      text: '客户订车',
      parent: 'free-ride',
      auth: 'CUSTOMER_ORDERING_CAR',
      href: 'customer-ordering-car'
    },
    {
      css: '/invoice-sending/invoice-sending.css',
      html: '/invoice-sending/invoice-sending.html',
      js: '/invoice-sending/invoice-sending.js',
      isSingle: true,
      title: '发送发票',
      text: '发送发票',
      auth: 'INVOICE_SENDING',
      href: 'invoice-sending'
    },{
      css: '/input-invoice/input-invoice.css',
      html: '/input-invoice/input-invoice.html',
      js: '/input-invoice/input-invoice.js',
      isSingle: true,
      title: '进项发票上传',
      text: '进项发票上传',
      parent: 'cost-other',
      auth: 'INPUT_INVOICE',
      href: 'input-invoice'
    },
    //运力菜单 
    {
      html: '/driverer-certification/driverer-certification.html',
      css: '/driverer-certification/driverer-certification.css',
      js: '/driverer-certification/driverer-certification.js',
      title: '人车运力',
      isSingle: true,
      text: '人车运力',
      href: 'driver-car-system',
      auth: 'UNIT-VERIFY-M-1',
      parent: 'capacity'
    },
    {
      html: '/driverer-certification/driverer-certification.html',
      css: '/driverer-certification/driverer-certification.css',
      js: '/driverer-certification/driverer-certification.js',
      title: '接单人/车辆认证',
      isSingle: true,
      text: '接单人/车辆认证',
      href: 'driverer-certification',
      auth: 'AUDIT_DRIVER-M-1',
      parent: 'capacity'
    },
    {
      html: '/driver-vip-management/driver-vip-management.html',
      css: '/driver-vip-management/driver-vip-management.css',
      js: '/driver-vip-management/driver-vip-management.js',
      title: 'VIP会员申请',
      isSingle: true,
      text: 'VIP会员申请',
      href: 'driver-vip-management',
      auth: 'AUDIT_VIP_DRIVER-M',
      parent: 'capacity'
    },
    {
      html: '/maintain-truck-info/maintain-truck-info.html',
      css: '/maintain-truck-info/maintain-truck-info.css',
      js: '/maintain-truck-info/maintain-truck-info.js',
      title: '司机、车辆信息维护',
      isSingle: true,
      text: '司机、车辆信息维护',
      href: 'maintain-truck-info',
      auth: 'AUDIT_DRIVER-M-2',
      parent: 'capacity'
    },
    {
      html: '/driver-repayment-plan/driver-repayment-plan.html',
      css: '/driver-repayment-plan/driver-repayment-plan.css',
      js: '/driver-repayment-plan/driver-repayment-plan.js',
      title: '还款计划',
      isSingle: true,
      text: '还款计划',
      href: 'driver-repayment-plan',
      auth: 'REPAYMENT_PLAN-M',
      parent: 'capacity'
    },
    {
      html: '/driver-repayment-apply/driver-repayment-apply.html',
      css: '/driver-repayment-apply/driver-repayment-apply.css',
      js: '/driver-repayment-apply/driver-repayment-apply.js',
      title: '司机还款申请', //导航栏显示标题
      isSingle: true,
      text: '司机还款申请', //下拉菜单显示标题
      href: 'driver-repayment-apply',
      auth: 'DRIVER_EXTENSION_APPLICATION-M',
      parent: 'capacity'
    },
    {
      html: '/driver-invitation/driver-invitation.html',
      css: '/driver-invitation/driver-invitation.css',
      js: '/driver-invitation/driver-invitation.js',
      title: '驾驶员邀请列表',
      isSingle: true,
      text: '驾驶员邀请列表',
      href: 'driver-invitation',
      auth: 'INVITE_DRIVER_REPORT-M',
      parent: 'capacity',
    },
    {
      html: '/to-vehicle-management/to-vehicle-management.html',
      css: '/to-vehicle-management/to-vehicle-management.css',
      js: '/to-vehicle-management/to-vehicle-management.js',
      title: '车辆管理',
      isSingle: true,
      text: '车辆管理',
      href: 'to-vehicle-management',
      auth: 'VEHICLE_MANAGEMENT-M-1',
      parent: 'capacity',
    },
    {
      html: '/special-line-driver-join/special-line-driver-join.html',
      css: '/special-line-driver-join/special-line-driver-join.css',
      js: '/special-line-driver-join/special-line-driver-join.js',
      title: '加入专线审批详情',
      isSingle: true,
      text: '加入专线审批详情',
      href: 'special-line-driver-join'
    },
    {
      html: '/special-line-driver-join-list/special-line-driver-join-list.html',
      css: '/special-line-driver-join-list/special-line-driver-join-list.css',
      js: '/special-line-driver-join-list/special-line-driver-join-list.js',
      title: '加入专线审批',
      isSingle: true,
      text: '加入专线审批',
      href: 'special-line-driver-join-list',
      auth: 'SPECIAL_LINE_DRIVER_JOIN_LIST',
      parent: 'capacity',
    },
    {
      html: '/special-line-driver-exit/special-line-driver-exit.html',
      css: '/special-line-driver-exit/special-line-driver-exit.css',
      js: '/special-line-driver-exit/special-line-driver-exit.js',
      title: '退出专线审批详情',
      isSingle: true,
      text: '退出专线审批详情',
      href: 'special-line-driver-exit'
    },
    {
      html: '/special-line-driver-exit-list/special-line-driver-exit-list.html',
      css: '/special-line-driver-exit-list/special-line-driver-exit-list.css',
      js: '/special-line-driver-exit-list/special-line-driver-exit-list.js',
      title: '退出专线审批',
      isSingle: true,
      text: '退出专线审批',
      href: 'special-line-driver-exit-list',
      auth: 'SPECIAL_LINE_DRIVER_EXIT_LIST',
      parent: 'capacity',
    },
    {
      html: '/output-line-driver/output-line-driver.html',
      css: '/output-line-driver/output-line-driver.css',
      js: '/output-line-driver/output-line-driver.js',
      title: '专线司机产值',
      isSingle: true,
      text: '专线司机产值',
      href: 'output-line-driver',
      auth: 'OUTPUT_LINE_DRIVER-M',
      parent: 'capacity',
    },
    {
      html: '/phone-bar/phone-bar.html',
      css: '/phone-bar/phone-bar.css',
      js: '/phone-bar/phone-bar.js',
      title: '电话推广',
      isSingle: true,
      text: '电话推广',
      href: 'phone-bar',
      auth: 'TEL_MARKETING_CALL-M',
      parent:'capacity'
    },
    {
      html: '/capacity-generalize/capacity-generalize.html',
      css: '/capacity-generalize/capacity-generalize.css',
      js: '/capacity-generalize/capacity-generalize.js',
      title: '电话推广任务',
      isSingle: true,
      text: '电话推广任务',
      auth: 'TEL_MARKETING_TASK-M',
      href: 'capacity-generalize',
      parent:'capacity'
    },
    {
      html: '/ask-leave/ask-leave.html',
      css: '/ask-leave/ask-leave.css',
      js: '/ask-leave/ask-leave.js',
      title: '暂停接单',
      isSingle: true,
      text: '暂停接单',
      auth: 'ASK_LEAVE-M',
      href: 'ask-leave',
      parent:'capacity'
    },
    {
      html: '/driver-complaints/driver-complaints.html',
      css: '/driver-complaints/driver-complaints.css',
      js: '/driver-complaints/driver-complaints.js',
      title: '司机投诉',
      isSingle: true,
      text: '司机投诉',
      href: 'driver-complaints',
      auth: 'DRIVER_COMPLAINTS-M',
      parent:'capacity'
    },
    /**
     * 2023-03-14   
     * 下线原因：
     * 1，因订单组上线异常登记的功能，已经替代了客服到此面板提交数据的功能。
     * 2，功能上线后，业务没有全面使用，没有运力部门的跟进。
    */
    {
      html: '/complain-about-driver/complain-about-driver.html',
      css: '/complain-about-driver/complain-about-driver.css',
      js: '/complain-about-driver/complain-about-driver.js',
      title: '违规司机处理',
      isSingle: true,
      text: '违规司机处理',
      href: 'complain-about-driver',
      auth: 'COMPLAIN_ABOUT_DRIVER-M',
      parent: 'capacity',
    },
    {
      html: '/coupon-manage/coupon-manage.html',
      css: '/coupon-manage/coupon-manage.css',
      js: '/coupon-manage/coupon-manage.js',
      title: '奖券管理',
      isSingle: true,
      text: '奖券管理',
      href: 'coupon-manage',
      auth: 'COUPON_MANAGE-M',
      parent:'capacity'
    },
    {
      html: '/car-sticker-management/car-sticker-management.html',
      css: '/car-sticker-management/car-sticker-management.css',
      js: '/car-sticker-management/car-sticker-management.js',
      title: '车贴审核',
      isSingle: true,
      text: '车贴审核',
      auth: 'UNIT-VERIFY-M-2',
      href: 'car-sticker-management',
      parent:'capacity'
    },
    {
      html: '/leave-assessmentl/leave-assessmentl.html',
      css: '/leave-assessmentl/leave-assessmentl.css',
      js: '/leave-assessmentl/leave-assessmentl.js',
      title: '请假考核',
      isSingle: true,
      text: '请假考核',
      auth: 'LEAVE-ASSESSMENTL-M',
      href: 'leave-assessmentl',
      parent:'capacity'
    },
    {
      html: '/intention-buycar/intention-buycar.html',
      css: '/intention-buycar/intention-buycar.css',
      js: '/intention-buycar/intention-buycar.js',
      title: '意向购车',
      isSingle: true,
      text: '意向购车',
      href: 'intention-buycar',
      auth: 'INTENTION-BUYCAR-M',
      parent: 'capacity',
    },
    {
      html: '/hot-date-set/hot-date-set.html',
      css: '/hot-date-set/hot-date-set.css',
      js: '/hot-date-set/hot-date-set.js',
      title: '旺季&额度设置',
      isSingle: true,
      text: '旺季&额度设置',
      href: 'hot-date-set',
      auth: 'HOT-DATE-SET-M',
      parent: 'capacity',
    },
    {
      html: '/driver-reservation/driver-reservation.html',
      css: '/driver-reservation/driver-reservation.css',
      js: '/driver-reservation/driver-reservation.js',
      title: '司机拜访预约',
      isSingle: true,
      text: '司机拜访预约',
      href: 'driver-reservation',
      auth: 'DRIVER_RESERVATION-M',
      parent: 'capacity',
    },
  // 客户订单菜单
    {
      html: '/upload-booking/upload-booking.html',
      css: '/upload-booking/upload-booking.css',
      js: '/upload-booking/upload-booking.js',
      title: '上传拖车托书',
      isSingle: true,
      text: '上传拖车托书',
      href: 'upload-booking',
      auth: 'CONTAINER_ALL_STATUS-M-1',
      parent: 'customer-order',
      seq: 1
    },
    {
      html: '/shipping-order-list/shipping-order-list.html',
      css: '/shipping-order-list/shipping-order-list.css',
      js: '/shipping-order-list/shipping-order-list.js',
      title: '客户订舱订单',
      isSingle: true,
      text: '客户订舱订单',
      href: 'shipping-order-list',
      auth: 'SHIPPING_ORDER_LIST',
      parent: 'customer-order',
      seq:5
    },
    {
      html: '/shipping-order-cancel/shipping-order-cancel.html',
      css: '/shipping-order-cancel/shipping-order-cancel.css',
      js: '/shipping-order-cancel/shipping-order-cancel.js',
      title: '退关费用待确认',
      isSingle: true,
      text: '退关费用待确认',
      href: 'shipping-order-cancel',
      auth: 'SHIPPING_ORDER_CANCEL',
      seq:6
    },
    {
      html: '/upload-shipping-booking/upload-shipping-booking.html',
      css: '/upload-shipping-booking/upload-shipping-booking.css',
      js: '/upload-shipping-booking/upload-shipping-booking.js',
      title: '上传海运订舱托书',
      isSingle: true,
      text: '上传海运订舱托书',
      href: 'upload-shipping-booking',
      auth: 'SHIPPING_ORDER_WAIT_UPDATE',
      parent: 'customer-order',
      seq: 2
    },
    {
      html: '/entry-shipping-booking/entry-shipping-booking.html',
      css: '/entry-shipping-booking/entry-shipping-booking.css',
      js: '/entry-shipping-booking/entry-shipping-booking.js',
      title: '海运订舱托书录入',
      isSingle: true,
      text: '海运订舱托书录入',
      href: 'entry-shipping-booking',
      auth: 'SHIPPING_ORDER_WAIT_DOCUMENT_WRITTEN',
      // parent: 'customer-order',
      seq: 3
    },
    {
      html: '/shipping-booking-space/shipping-booking-space.html',
      css: '/shipping-booking-space/shipping-booking-space.css',
      js: '/shipping-booking-space/shipping-booking-space.js',
      title: '海运订舱',
      isSingle: true,
      text: '海运订舱',
      href: 'shipping-booking-space',
      auth: 'SHIPPING_ORDER_SHIPPING_BOOKING_SPACE',
      parent: 'customer-order',
      seq: 4
    },
    {
      html: '/shipping-bill-lading/shipping-bill-lading.html',
      css: '/shipping-bill-lading/shipping-bill-lading.css',
      js: '/shipping-bill-lading/shipping-bill-lading.js',
      title: '提单-待操作提交',
      isSingle: true,
      text: '提单-待操作提交',
      href: 'shipping-bill-lading',
      auth: 'SHIPPING_ORDER_SHIPPING_BOOKING_SPACE',
      // parent: 'customer-order',
      // seq: 4
    },
    {
      html: '/shipping-bill-lading-dispatch-customer/shipping-bill-lading-dispatch-customer.html',
      css: '/shipping-bill-lading-dispatch-customer/shipping-bill-lading-dispatch-customer.css',
      js: '/shipping-bill-lading-dispatch-customer/shipping-bill-lading-dispatch-customer.js',
      title: '提单-待发客户',
      isSingle: true,
      text: '提单-待发客户',
      href: 'shipping-bill-lading-dispatch-customer',
      auth: 'SHIPPING_ORDER_SHIPPING_BOOKING_SPACE',
      // parent: 'customer-order',
      // seq: 4
    },
    {
      html: '/shipping-bill-lading-send/shipping-bill-lading-send.html',
      css: '/shipping-bill-lading-send/shipping-bill-lading-send.css',
      js: '/shipping-bill-lading-send/shipping-bill-lading-send.js',
      title: '提单-待客户确认',
      isSingle: true,
      text: '提单-待客户确认',
      href: 'shipping-bill-lading-send',
      auth: 'SHIPPING_ORDER_SHIPPING_BOOKING_SPACE',
      // parent: 'customer-order',
      // seq: 4
    },
    {
      html: '/shipping-bill-lading-confirmed/shipping-bill-lading-confirmed.html',
      css: '/shipping-bill-lading-confirmed/shipping-bill-lading-confirmed.css',
      js: '/shipping-bill-lading-confirmed/shipping-bill-lading-confirmed.js',
      title: '提单-待截单',
      isSingle: true,
      text: '提单-待截单',
      href: 'shipping-bill-lading-confirmed',
      auth: 'SHIPPING_ORDER_SHIPPING_BOOKING_SPACE',
      // parent: 'customer-order',
      // seq: 4
    },
    {
      html: '/shipping-order-detail/shipping-order-detail.html',
      css: '/shipping-order-detail/shipping-order-detail.css',
      js: '/shipping-order-detail/shipping-order-detail.js',
      title: '海运订舱订单详情',
      isSingle: false,
      text: '海运订舱订单详情',
      href: 'shipping-order-detail',
      auth: 'SHIPPING_ORDER_DETAIL',
      // parent: 'customer-order',
      // seq:5
    },
    {
      html: '/remote-portal/remote-portal.html',
      css: '/remote-portal/remote-portal.css',
      js: '/remote-portal/remote-portal.js',
      title: '远程门户',
      isSingle: false,
      text: '远程门户',
      href: 'remote-portal',
    },
     //该页面已经下架暂时隐藏菜单入口 2025-01-8
    {
      html: '/new-box-list/new-box-list.html',
      css: '/new-box-list/new-box-list.css',
      js: '/new-box-list/new-box-list.js',
      title: '业务接单(新)',
      isSingle: true,
      text: '业务接单(新)',
      href: 'new-box-list',
      auth: 'NEW_CONTAINER_ORDER_LIST-M-1',
      // parent: 'customer-order'
    },
    {
      html: '/order-search/order-search.html',
      css: '/order-search/order-search.css',
      js: '/order-search/order-search.js',
      title: '新业务接单',
      isSingle: true,
      text: '新业务接单',
      href: 'order-search',
      auth: 'ORDER-SEARCH-LIST-M',
      parent: 'customer-order'
    },
    //该页面已经下架暂时隐藏菜单入口 2025-01-8
    {
      html: '/box-list/box-list.html',
      css: '/box-list/box-list.css',
      js: '/box-list/box-list.js',
      title: '业务接单',
      isSingle: true,
      text: '业务接单',
      href: 'box-list',
      auth: 'NEW_CONTAINER_ORDER_LIST-M-2',
      // parent: 'customer-order'
    },
    {
      html: '/data-compare/data-compare.html',
      css: '/data-compare/data-compare.css',
      js: '/data-compare/data-compare.js',
      title: '进港数据对比',
      isSingle: true,
      text: '进港数据对比',
      href: 'data-compare',
      auth: 'GATE_IN_DATA_COMPARE-M',
      parent: 'customer-order'
    },
    {
      html: '/recording-center/recording-center.html',
      css: '/recording-center/recording-center.css',
      js: '/recording-center/recording-center.js',
      title: '录单中心',
      isSingle: true,
      text: '录单中心',
      href: 'recording-center',
      auth: 'MAKING_ACCOUNT_RECEIVABLE-M-1',
      parent: 'customer-order'
    },
    {
      html: '/customer-communication/customer-communication.html',
      css: '/customer-communication/customer-communication.css',
      js: '/customer-communication/customer-communication.js',
      title: '客户热线',
      isSingle: true,
      text: '客户热线',
      href: 'customer-communication',
      auth: 'CUSTOMER_COMMUNICATE-M',
      parent: 'customer-order'
    },
    {
      css: '/container-quality-evaluate/container-quality-evaluate.css',
      html: '/container-quality-evaluate/container-quality-evaluate.html',
      js: '/container-quality-evaluate/container-quality-evaluate.js',
      isSingle: true,
      title: '客户做箱质量评价统计',
      text: '客户做箱质量评价统计',
      parent: 'customer-order',
      auth: 'CONTAINER_QUALITY_EVALUATE-M',
      href: 'container-quality-evaluate'
    },
    {
      html: '/container-track/container-track.html',
      css: '/container-track/container-track.css',
      js: '/container-track/container-track.js',
      title: '节点跟踪',
      isSingle: true,
      text: '节点跟踪',
      href: 'container-track',
      auth: 'CONTAINER_TRACK-M',
      parent: 'customer-order',
    },
    {
      html: '/short-barge/short-barge.html',
      css: '/short-barge/short-barge.css',
      js: '/short-barge/short-barge.js',
      title: '驳箱下单',
      isSingle: true,
      text: '驳箱下单',
      href: 'short-barge',
      auth: 'SHORT_BARGE-M',
      parent: 'customer-order',
    },
    {
      css: '/supervision-management/supervision-management.css',
      html: '/supervision-management/supervision-management.html',
      js: '/supervision-management/supervision-management.js',
      isSingle: true,
      title: '检验监装管理',
      text: '检验监装管理',
      parent: 'customer-order',
      auth: 'SUPERVISION_MANAGEMENT-M',
      href: 'supervision-management'
    },
    {
      html: '/port-entry-forecast/port-entry-forecast.html',
      css: '/port-entry-forecast/port-entry-forecast.css',
      js: '/port-entry-forecast/port-entry-forecast.js',
      title: '易港通预进港',
      isSingle: true,
      text: '易港通预进港',
      href: 'port-entry-forecast',
      auth: 'PORT-ENTRY-FORECAST-LIST-M',
      parent: 'customer-order'
    },
    {
      html: '/abnormal-cost/abnormal-cost.html',
      css: '/abnormal-cost/abnormal-cost.css',
      js: '/abnormal-cost/abnormal-cost.js',
      title: '异常订单',
      isSingle: true,
      text: '异常订单',
      href: 'abnormal-cost',
      auth: 'PORT-ENTRY-FORECAST-LIST-M', // ABNORMAL-COST
      parent: 'customer-order'
    },
    {
      html: '/collocation-task-tracking/collocation-task-tracking.html',
      css: '/collocation-task-tracking/collocation-task-tracking.css',
      js: '/collocation-task-tracking/collocation-task-tracking.js',
      title: '搭配任务跟踪',
      isSingle: true,
      text: '搭配任务跟踪',
      href: 'collocation-task-tracking',
    },
    {
      html: '/abnormal-cost-handle/abnormal-cost-handle.html',
      css: '/abnormal-cost-handle/abnormal-cost-handle.css',
      js: '/abnormal-cost-handle/abnormal-cost-handle.js',
      title: '异常订单费用核查',
      isSingle: true,
      text: '异常订单费用核查',
      href: 'abnormal-cost-handle',
      auth: 'PORT-ENTRY-FORECAST-LIST-M', // abnormal-cost-handle
      parent: 'customer-order'
    },
    {
      html: '/invoicing-product/invoicing-product.html',
      css: '/invoicing-product/invoicing-product.css',
      js: '/invoicing-product/invoicing-product.js',
      title: '开票品名维护',
      isSingle: true,
      text: '开票品名维护',
      href: 'invoicing-product',
      auth: 'BBKF',
    },
  // 单证处理菜单
    {
      html: '/set-box-list/set-box-list.html',
      css: '/set-box-list/set-box-list.css',
      js: '/set-box-list/set-box-list.js',
      title: '放箱列表',
      isSingle: true,
      text: '放箱列表',
      href: 'set-box-list',
      auth: 'YZS_RELEASE_CONTAINER_LIST-M',
      parent: 'document-processing',
      seq: 1
    },
    {
      css: '/container-seal-input/container-seal-input.css',
      html: '/container-seal-input/container-seal-input.html',
      js: '/container-seal-input/container-seal-input.js',
      isSingle: true,
      parent: 'document-processing',
      title: '箱封号录入',
      text: '箱封号录入',
      auth: 'NEW_INPUT_CONTAINER_NUMBER-M-3',
      href: 'container-seal-input',
      seq: 2
    },
    {
      css: '/container-seal-entry/container-seal-entry.css',
      html: '/container-seal-entry/container-seal-entry.html',
      js: '/container-seal-entry/container-seal-entry.js',
      isSingle: true,
      parent: 'document-processing',
      title: '箱封号复核',
      text: '箱封号复核',
      auth: 'NEW_INPUT_CONTAINER_NUMBER-M-1',
      href: 'container-seal-entry'
    },
    {
      css: '/yangshan-declare-manage/yangshan-declare-manage.css',
      html: '/yangshan-declare-manage/yangshan-declare-manage.html',
      js: '/yangshan-declare-manage/yangshan-declare-manage.js',
      isSingle: true,
      parent: 'document-processing',
      title: '洋山一放',
      text: '洋山一放',
      auth: 'NEW_INPUT_CONTAINER_NUMBER-M-2',
      href: 'yangshan-declare-manage'
    },
    {
      html: '/document-operate/document-operate.html',
      css: '/document-operate/document-operate.css',
      js: '/document-operate/document-operate.js',
      title: '单证操作',
      isSingle: true,
      text: '单证操作',
      auth: 'NEW_INPUT_CONTAINER_NUMBER-M-4',
      parent:'document-processing',
      href: 'document-operate',
    },
    {
      html: '/declare-manage/declare-manage.html',
      css: '/declare-manage/declare-manage.css',
      js: '/declare-manage/declare-manage.js',
      title: '报关管理',
      text: '报关管理',
      isSingle: true,
      parent: 'document-processing',
      auth: 'CUSTOMS_MANAGEMENT-M',
      href: 'declare-manage'
    },
    {
      html: '/entrusted-customs-declaration/entrusted-customs-declaration.html',
      css: '/entrusted-customs-declaration/entrusted-customs-declaration.css',
      js: '/entrusted-customs-declaration/entrusted-customs-declaration.js',
      title: '发送报关行',
      isSingle: true,
      text: '发送报关行',
      href: 'entrusted-customs-declaration',
      auth: 'PORT-ENTRY-FORECAST-LIST-M',
      parent:'entrusted-customs-declaration'
    },
    {
      html: '/receipt-point-maintain/receipt-point-maintain.html',
      css: '/receipt-point-maintain/receipt-point-maintain.css',
      js: '/receipt-point-maintain/receipt-point-maintain.js',
      title: '回单点维护',
      isSingle: true,
      text: '回单点维护',
      href: 'receipt-point-maintain',
      auth: 'YZS_RELEASE_CONTAINER_LIST-M-1',
      parent: 'document-processing',
    },
  // 运输管理菜单
    {
      html: '/driver-communication/driver-communication.html',
      css: '/driver-communication/driver-communication.css',
      js: '/driver-communication/driver-communication.js',
      title: '司机热线',
      isSingle: true,
      text: '司机热线',
      href: 'driver-communication',
      auth: 'DRIVER_COMMUNICATE-M',
      parent: 'transportation-manager',
      seq: 1
    },
    {
      html: '/special-line/special-line.html',
      css: '/special-line/special-line.css',
      js: '/special-line/special-line.js',
      title: '专线管理',
      isSingle: true,
      text: '专线管理',
      href: 'special-line',
      auth: 'SPECIAL_LINE-M',
      parent: 'transportation-manager',
      seq: 1
    },
    {
      html: '/special-group-driver/special-group-driver.html',
      css: '/special-group-driver/special-group-driver.css',
      js: '/special-group-driver/special-group-driver.js',
      title: '专组司机',
      isSingle: true,
      text: '专组司机',
      href: 'special-group-driver',
      auth: 'SPECIAL_GROUP_DRIVER-M',
      parent: 'transportation-manager',
      seq: 1
    },
    {
      html: '/driver-communication-detail/driver-communication-detail.html',
      css: '/driver-communication-detail/driver-communication-detail.css',
      js: '/driver-communication-detail/driver-communication-detail.js',
      title: '司机热线详情',
      isSingle: true,
      text: '司机热线详情',
      href: 'driver-communication-detail',
      auth: 'DRIVER_COMMUNICATE_DETAIL-M-1',
      parent: 'transportation-manager',
      seq: 2
    },
    {
      html: '/driver-communication-new/driver-communication-new.html',
      css: '/driver-communication-new/driver-communication-detail.css',
      js: '/driver-communication-new/driver-communication-new.js',
      title: '司机热线详情(新)',
      isSingle: true,
      text: '司机热线详情(新)',
      href: 'driver-communication-new',
      auth: 'DRIVER_COMMUNICATE_DETAIL-M-2',
      parent: 'transportation-manager',
      seq: 2
    },
    {
      html: '/smart-dispatch-log/smart-dispatch-log.html',
      css: '/smart-dispatch-log/smart-dispatch-log.css',
      js: '/smart-dispatch-log/smart-dispatch-log.js',
      title: '智能推送记录',
      isSingle: true,
      text: '智能推送记录',
      href: 'smart-dispatch-log',
      auth: 'TRUCK_TRAIL-M-1',
      parent: 'transportation-manager',
      seq: 2
    },
    {
      html: '/new-order-inquiry/new-order-inquiry.html',
      css: '/new-order-inquiry/new-order-inquiry.css',
      js: '/new-order-inquiry/new-order-inquiry.js',
      title: '订单查询',
      isSingle: true,
      text: '订单查询',
      href: 'new-order-inquiry',
      auth: 'CONTAINER_ALL_STATUS-M-2',
      parent: 'transportation-manager',
      seq: 2
    },
    {
      html: '/on-time-address-audit/on-time-address-audit.html',
      css: '/on-time-address-audit/on-time-address-audit.css',
      js: '/on-time-address-audit/on-time-address-audit.js',
      title: '准点奖励审核',
      isSingle: true,
      text: '准点奖励审核',
      href: 'on-time-address-audit',
      auth: 'PROMISE_ON_TIME_AUDIT',
      seq: 2
    },
    {
      html: '/hitch-carry-on/hitch-carry-on.html',
      css: '/hitch-carry-on/hitch-carry-on.css',
      js: '/hitch-carry-on/hitch-carry-on.js',
      title: '顺风车业务',
      isSingle: true,
      text: '顺风车业务',
      href: 'hitch-carry-on',
    },
    {
      css: '/land-transform-water-detail/land-transform-water-detail.css',
      html: '/land-transform-water-detail/land-transform-water-detail.html',
      js: '/land-transform-water-detail/land-transform-water-detail.js',
      isSingle: true,
      title: '待确认陆改水详情',
      text: '待确认陆改水详情',
      parent: 'free-ride',
      auth: 'LAND_TRANSFORM_WATER_DETAIL',
      href: 'land-transform-water-detail'
    },
    {
      css: '/land-transform-water-detail-confirm/land-transform-water-detail-confirm.css',
      html: '/land-transform-water-detail-confirm/land-transform-water-detail-confirm.html',
      js: '/land-transform-water-detail-confirm/land-transform-water-detail-confirm.js',
      isSingle: true,
      title: '确认陆改水',
      text: '确认陆改水',
      parent: 'free-ride',
      auth: 'LAND_TRANSFORM_WATER_DETAIL_CONFIRM',
      href: 'land-transform-water-detail-confirm'
    },
    {
      html: '/available-for-delivery/available-for-delivery.html',
      css: '/available-for-delivery/available-for-delivery.css',
      js: '/available-for-delivery/available-for-delivery.js',
      title: '可带货列表',
      isSingle: true,
      text: '可带货列表',
      href: 'available-for-delivery',
    },
    {
      html: '/handle-tasks/handle-tasks.html',
      css: '/handle-tasks/handle-tasks.css',
      js: '/handle-tasks/handle-tasks.js',
      title: '定价偏离预警',
      isSingle: true,
      text: '定价偏离预警',
      href: 'handle-tasks',
    },
    {
      html: '/verification-tasks/verification-tasks.html',
      css: '/verification-tasks/verification-tasks.css',
      js: '/verification-tasks/verification-tasks.js',
      title: '定价偏离核实',
      isSingle: true,
      text: '定价偏离核实',
      href: 'verification-tasks',
    },
    {
      html: '/verification-tasks-sale/verification-tasks-sale.html',
      css: '/verification-tasks-sale/verification-tasks-sale.css',
      js: '/verification-tasks-sale/verification-tasks-sale.js',
      title: '售价偏离核实',
      isSingle: true,
      text: '售价偏离核实',
      href: 'verification-tasks-sale',
    },
    {
      html: '/maintenance-line/maintenance-line.html',
      css: '/maintenance-line/maintenance-line.css',
      js: '/maintenance-line/maintenance-line.js',
      title: '带货线路管理',
      isSingle: true,
      text: '带货线路管理',
      href: 'maintenance-line',
      auth: 'CARGO_LINE_MANAGEMENT',
      parent: 'transportation-manager',
      seq: 2
    },
    {
      html: '/box-tracking/box-tracking.html',
      css: '/box-tracking/box-tracking.css',
      js: '/box-tracking/box-tracking.js',
      title: '缺箱跟踪',
      isSingle: true,
      text: '缺箱跟踪',
      href: 'box-tracking',
      auth: 'BOX_TRACKING-M',
      seq:2,
      parent:'transportation-manager'
    },
    {
      html: '/door-cost-management/door-cost-management.html',
      css: '/door-cost-management/door-cost-management.css',
      js: '/door-cost-management/door-cost-management.js',
      title: '维护发单价格',
      isSingle: true,
      text: '维护发单价格',
      href: 'door-cost-management',
      auth: 'YZS_DOOR_COST-M-3',
      parent: 'transportation-manager',
      seq: 3
    },
    {
      html: '/multimodal-transport/multimodal-transport.html',
      css: '/multimodal-transport/multimodal-transport.css',
      js: '/multimodal-transport/multimodal-transport.js',
      title: '驳船任务',
      isSingle: true,
      text: '驳船任务',
      href: 'multimodal-transport',
      auth: 'TRANSPORTATION_BARGE_MISSION',
      parent: 'transportation-manager',
    },
    {
      html: '/land-transform-water/land-transform-water.html',
      css: '/land-transform-water/land-transform-water.css',
      js: '/land-transform-water/land-transform-water.js',
      title: '待确认陆改水',
      isSingle: true,
      auth: 'LAND_TRANSFORM_WATER',
      text: '待确认陆改水',
      href: 'land-transform-water',
      parent: 'transportation-manager',
    },
    // 第一阶段保留入口，后续彻底下架
    {
      css: '/car-trace-report/car-trace-report.css',
      html: '/car-trace-report/car-trace-report.html',
      js: '/car-trace-report/car-trace-report.js',
      isSingle: true,
      parent: 'transportation-manager',
      title: '车辆跟踪统计表',
      text: '车辆跟踪统计表',
      auth: 'CONTAINER_ALL_STATUS-M-3',
      href: 'car-trace-report',
      seq: 5
    },
    {
      css: '/car-trace-report-new/car-trace-report-new.css',
      html: '/car-trace-report-new/car-trace-report-new.html',
      js: '/car-trace-report-new/car-trace-report-new.js',
      isSingle: true,
      parent: 'transportation-manager',
      title: '车辆跟踪统计表(新)',
      text: '车辆跟踪统计表(新)',
      auth: 'NEW-CONTAINER_ALL_STATUS-M-3',
      href: 'car-trace-report-new',
      seq: 5
    },
    {
      css: '/car-trace-report-detail/car-trace-report-detail.css',
      html: '/car-trace-report-detail/car-trace-report-detail.html',
      js: '/car-trace-report-detail/car-trace-report-detail.js',
      isSingle: true,
      title: '车辆跟踪统计表详情',
      text: '车辆跟踪统计表详情',
      href: 'car-trace-report-detail',
    },
    {
      html: '/raise-node-verify/raise-node-verify.html',
      css: '/raise-node-verify/raise-node-verify.css',
      js: '/raise-node-verify/raise-node-verify.js',
      title: '核实提箱点',
      isSingle: true,
      text: '核实提箱点',
      href: 'raise-node-verify',
      auth: 'RAISE_NODE_VERIFY',
      parent: 'transportation-manager'
    },
    {
      html: '/raise-node-verify-detail/raise-node-verify-detail.html',
      css: '/raise-node-verify-detail/raise-node-verify-detail.css',
      js: '/raise-node-verify-detail/raise-node-verify-detail.js',
      title: '核实提箱点详情',
      isSingle: true,
      text: '核实提箱点详情',
      href: 'raise-node-verify-detail',
      auth: 'RAISE_NODE_VERIFY_DETAIL',
      // parent: 'transportation-manager'
    },
    {
      html: '/load-unload-node-verify/load-unload-node-verify.html',
      css: '/load-unload-node-verify/load-unload-node-verify.css',
      js: '/load-unload-node-verify/load-unload-node-verify.js',
      title: '核实装卸点',
      isSingle: true,
      text: '核实装卸点',
      href: 'load-unload-node-verify',
      auth: 'LOAD_UNLOAD_NODE_VERIFY',
      parent: 'transportation-manager'
    },
    {
      html: '/load-unload-node-verify-detail/load-unload-node-verify-detail.html',
      css: '/load-unload-node-verify-detail/load-unload-node-verify-detail.css',
      js: '/load-unload-node-verify-detail/load-unload-node-verify-detail.js',
      title: '核实装卸点详情',
      isSingle: true,
      text: '核实装卸点详情',
      href: 'load-unload-node-verify-detail',
      auth: 'LOAD_UNLOAD_NODE_VERIFY_DETAIL',
      // parent: 'transportation-manager'
    },
    {
      html: '/return-node-verify/return-node-verify.html',
      css: '/return-node-verify/return-node-verify.css',
      js: '/return-node-verify/return-node-verify.js',
      title: '核实还箱点',
      isSingle: true,
      text: '核实还箱点',
      href: 'return-node-verify',
      auth: 'RETURN_NODE_VERIFY',
      parent: 'transportation-manager'
    },
    {
      html: '/return-node-verify-detail/return-node-verify-detail.html',
      css: '/return-node-verify-detail/return-node-verify-detail.css',
      js: '/return-node-verify-detail/return-node-verify-detail.js',
      title: '核实还箱点详情',
      isSingle: true,
      text: '核实还箱点详情',
      href: 'return-node-verify-detail',
      auth: 'RETURN_NODE_VERIFY_DETAIL',
      // parent: 'transportation-manager'
    },
    {
      html: '/suspend-accept-order-assess/suspend-accept-order-assess.html',
      css: '/suspend-accept-order-assess/suspend-accept-order-assess.css',
      js: '/suspend-accept-order-assess/suspend-accept-order-assess.js',
      title: '暂停接单考评',
      isSingle: true,
      text: '暂停接单考评',
      href: 'suspend-accept-order-assess',
      auth: 'SUSPEND_ACCEPT_ORDER_ASSESS',
      // parent: 'transportation-manager'
    },
    {
      html: '/vehicle-trajectory/vehicle-trajectory.html',
      css: '/vehicle-trajectory/vehicle-trajectory.css',
      js: '/vehicle-trajectory/vehicle-trajectory.js',
      title: '车辆轨迹',
      isSingle: true,
      text: '车辆轨迹',
      href: 'vehicle-trajectory',
      parent: 'transportation-manager',
      auth: 'TRUCK_TRAIL-M-2',
      noLoginAllowed: true,
      seq: 6
    },
    {
      html: '/container-price-maintain/container-price-maintain.html',
      css: '/container-price-maintain/container-price-maintain.css',
      js: '/container-price-maintain/container-price-maintain.js',
      title: '维护堆场费用',
      isSingle: true,
      text: '维护堆场费用',
      href: 'container-price-maintain',
      auth: 'DEPOT_FEE_MAINTAIN-M',
      parent: 'transportation-manager',
      seq: 12
    },
    {
      html: '/nucleic-acid-detection/nucleic-acid-detection.html',
      css: '/nucleic-acid-detection/nucleic-acid-detection.css',
      js: '/nucleic-acid-detection/nucleic-acid-detection.js',
      title: '司机防疫凭证管理',
      isSingle: true,
      text: '司机防疫凭证管理',
      href: 'nucleic-acid-detection',
      auth: 'NUCLEIC-ACID-DETECTION-M',
      parent: 'transportation-manager',
      seq: 12
    },
    {
      html: '/pre-record-manage/pre-record-manage.html',
      css: '/pre-record-manage/pre-record-manage.css',
      js: '/pre-record-manage/pre-record-manage.js',
      title: '预录管理',
      isSingle: true,
      text: '预录管理',
      href: 'pre-record-manage',
      auth: 'PANEL_FAIL_PRERECORD-M',
      parent:'transportation-manager',
      seq: 13
    },
    {
      html: '/secondary-arrival-tracking/secondary-arrival-tracking.html',
      css: '/secondary-arrival-tracking/secondary-arrival-tracking.css',
      js: '/secondary-arrival-tracking/secondary-arrival-tracking.js',
      title: '二次进港跟踪',
      isSingle: true,
      text: '二次进港跟踪',
      href: 'secondary-arrival-tracking',
      auth: 'YZS_DOOR_COST-M-1',
      parent: 'transportation-manager'
    },
    {
      html: '/control-vip-grab/control-vip-grab.html',
      css: '/control-vip-grab/control-vip-grab.css',
      js: '/control-vip-grab/control-vip-grab.js',
      title: '司机客服策略',
      isSingle: true,
      text: '司机客服策略',
      href: 'control-vip-grab',
      auth: 'YZS_DOOR_COST-M-2',
      parent: 'transportation-manager'
    },
    {
      html: '/pre-record-data-review/pre-record-data-review.html',
      css: '/pre-record-data-review/pre-record-data-review.css',
      js: '/pre-record-data-review/pre-record-data-review.js',
      title: '数据复核',
      isSingle: true,
      text: '数据复核',
      href: 'pre-record-data-review',
      auth: 'PRERECORD_DATA_REVIEW-M',
      parent: 'transportation-manager',
    },
    {
      html: '/paperless-nocar/paperless-nocar.html',
      css: '/paperless-nocar/paperless-nocar.css',
      js: '/paperless-nocar/paperless-nocar.js',
      title: '上海无纸化',
      isSingle: true,
      text: '上海无纸化',
      href: 'paperless-nocar',
      auth: 'PAPERLESS_NOCAR-M',
      parent: 'transportation-manager',
    },
    {
      html: '/phone-records/phone-records.html',
      css: '/phone-records/phone-records.css',
      js: '/phone-records/phone-records.js',
      title: '客服电话',
      isSingle: true,
      text: '客服电话',
      href: 'phone-records',
      auth: 'PHONE_RECORDS-M',
      parent: 'transportation-manager',
    },
    {
      html: '/cash-payable/cash-payable.html',
      js: '/cash-payable/cash-payable.js',
      title: '现金单港口运费比例配置',
      isSingle: true,
      text: '现金单港口运费比例配置',
      href: 'cash-payable',
      auth: 'CASH-PAYABLE',
      parent: 'business'
    },
    {
      html: '/rush-refund-record/rush-refund-record.html',
      css: '/rush-refund-record/rush-refund-record.css',
      js: '/rush-refund-record/rush-refund-record.js',
      title: '抢退单记录查询',
      isSingle: true,
      text: '抢退单记录查询',
      href: 'rush-refund-record',
      auth: 'RUSH_REFUND_RECORD',
      parent: 'transportation-manager',
    },
    {
      html: '/compensate-business-list/compensate-business-list.html',
      css: '/compensate-business-list/compensate-business-list.css',
      js: '/compensate-business-list/compensate-business-list.js',
      title: '补产值管理',
      isSingle: true,
      text: '补产值管理',
      href: 'compensate-business-list',
      auth: 'COMPENSATE_BUSINESS_LIST',
      parent: 'transportation-manager',
    },
    {
      html: '/compensate-practice-manage/compensate-practice-manage.html',
      css: '/compensate-practice-manage/compensate-practice-manage.css',
      js: '/compensate-practice-manage/compensate-practice-manage.js',
      title: '补业务管理',
      isSingle: true,
      text: '补业务管理',
      href: 'compensate-practice-manage',
      auth: 'COMPENSATE_PRACTICE_LIST',
      parent: 'transportation-manager',
      }, 
    // 宁波白名单
    {
      html: '/ningbo-white-list/ningbo-white-list.html',
      css: '/ningbo-white-list/ningbo-white-list.css',
      js: '/ningbo-white-list/ningbo-white-list.js',
      title: '宁波白名单',
      isSingle: true,
      text: '宁波白名单',
      href: 'ningbo-white-list',
      auth: 'NINGBO_WHITE_LIST',
      parent: 'transportation-manager',
    },
  // 应收客户菜单
    {
      html: '/new-cost-entry/new-cost-entry.html',
      css: '/new-cost-entry/new-cost-entry.css',
      js: '/new-cost-entry/new-cost-entry.js',
      title: '应收费用(新)',
      isSingle: true,
      text: '应收费用(新)',
      href: 'new-cost-entry',
      auth: 'NEW_CHARGE_RECEIVE-M',
      parent: 'receivable-customer',
      seq: 1
    },
    {
      html: '/shipping-booking-space-receivable/shipping-booking-space-receivable.html',
      css: '/shipping-booking-space-receivable/shipping-booking-space-receivable.css',
      js: '/shipping-booking-space-receivable/shipping-booking-space-receivable.js',
      title: '海运费用',
      isSingle: true,
      text: '海运费用',
      href: 'shipping-booking-space-receivable',
      auth: 'SHIPPING_BOOKING_SPACE_RECEIVABLE',
      parent: 'receivable-customer',
      seq: 1
    },
    {
      html: '/shipping-booking-space-receivable-details/shipping-booking-space-receivable-details.html',
      css: '/shipping-booking-space-receivable-details/shipping-booking-space-receivable-details.css',
      js: '/shipping-booking-space-receivable-details/shipping-booking-space-receivable-details.js',
      title: '海运应收',
      isSingle: false,
      text: '海运应收',
      href: 'shipping-booking-space-receivable-details',
      auth: 'SHIPPING_BOOKING_SPACE_RECEIVABLE_DETAILS',
      // parent: 'receivable-customer',
      // seq: 1
    },
    {
      css: '/expense-confirmation-management/expense-confirmation-management.css',
      html: '/expense-confirmation-management/expense-confirmation-management.html',
      js: '/expense-confirmation-management/expense-confirmation-management.js',
      title: '费用确认管理',
      isSingle: true,
      text: '费用确认管理',
      href: 'expense-confirmation-management',
      auth: 'CUSTOMER_OPERATION_MANAGE-M',
      parent: 'receivable-customer',
      seq:2
    },
      // SEC是新写的页面
    {
      css: '/receivable-bill-management-sec/receivable-bill-management-sec.css',
      html: '/receivable-bill-management-sec/receivable-bill-management-sec.html',
      js: '/receivable-bill-management-sec/receivable-bill-management-sec.js',
      isSingle: true,
      title: '应收账单管理(新)',
      text: '应收账单管理(新)',
      parent: 'receivable-customer',
      auth: 'REVENUE_BILLING_REPORT_MGT-M',
      href: 'receivable-bill-management-sec',
      seq: 3
    },
    {
      css: '/invoicing-application/invoicing-application.css',
      html: '/invoicing-application/invoicing-application.html',
      js: '/invoicing-application/invoicing-application.js',
      isSingle: true,
      title: '申请开票',
      text: '申请开票',
      parent: 'receivable-customer',
      auth: '',
      href: 'invoicing-application',
    },
    {
      html: '/making-account-receivable/making-account-receivable.html',
      css: '/making-account-receivable/making-account-receivable.css',
      js: '/making-account-receivable/making-account-receivable.js',
      title: '应收账单制作',
      isSingle: true,
      text: '应收账单制作',
      href: 'making-account-receivable',
      auth: 'MAKING_ACCOUNT_RECEIVABLE-M-2',
      parent: 'receivable-customer',
      seq: 3
    },
    {
      html: '/receivable-issue-invoice/receivable-issue-invoice.html',
      css: '/receivable-issue-invoice/receivable-issue-invoice.css',
      js: '/receivable-issue-invoice/receivable-issue-invoice.js',
      title: '开票申请',
      isSingle: true,
      text: '开票申请',
      href: 'receivable-issue-invoice',
      auth: 'REVENUE_INVOICE_APPLICATION-M',
      parent: 'receivable-customer',
      seq: 5
    },
    {
      html: '/invoice-write-off/invoice-write-off.html',
      css: '/invoice-write-off/invoice-write-off.css',
      js: '/invoice-write-off/invoice-write-off.js',
      title: '开票核销',
      isSingle: true,
      text: '开票核销',
      href: 'invoice-write-off',
      auth: 'YZS_REVENUE_INVOICE-M-1',
      parent: 'receivable-customer',
      seq: 6
    },
    {
      html: '/receipt-management/receipt-management.html',
      css: '/receipt-management/receipt-management.css',
      js: '/receipt-management/receipt-management.js',
      title: '收款单管理',
      isSingle: true,
      text: '收款单管理',
      href: 'receipt-management',
      auth: 'YZS_REVENUE_INVOICE-M-2',
      parent: 'receivable-customer',
      seq: 7
    },
    {
      html: '/collection-plan/collection-plan.html',
      css: '/collection-plan/collection-plan.css',
      js: '/collection-plan/collection-plan.js',
      title: '收款计划',
      isSingle: true,
      text: '收款计划',
      href: 'collection-plan',
      auth: 'PAYMENT_PLAN-M',
      parent: 'receivable-customer',
      seq:8
    },
    {
      html: '/prepayment-transfer-audit/prepayment-transfer-audit.html',
      css: '/prepayment-transfer-audit/prepayment-transfer-audit.css',
      js: '/prepayment-transfer-audit/prepayment-transfer-audit.js',
      title: '预付转账审核',
      isSingle: true,
      text: '预付转账审核',
      href: 'prepayment-transfer-audit',
      auth: 'PREPAYMENT-TRANSFER-AUDIT-M',
      parent: 'receivable-customer',
    },
    {
      html: '/stamping-bills-receivable/stamping-bills-receivable.html',
      css: '/stamping-bills-receivable/stamping-bills-receivable.css',
      js: '/stamping-bills-receivable/stamping-bills-receivable.js',
      title: '应收账单盖章',
      isSingle: true,
      text: '应收账单盖章',
      href: 'stamping-bills-receivable',
      auth: 'STAMPING_BILLS_RECEIVABLE',
      parent: 'receivable-customer',
      seq:10
    },
  // 应付司机菜单
  {
      html: '/driver-cost/driver-cost.html',
      css: '/driver-cost/driver-cost.css',
      js: '/driver-cost/driver-cost.js',
      title: '应付司机',
      isSingle: true,
      text: '应付司机',
      href: 'driver-cost',
      auth: 'FLEET_COST_DRIVER_MGT-M-1',
      parent: 'driver'
    },
    {
      html: '/etc-new-card/etc-new-card.html',
      css: '/etc-new-card/etc-new-card.css',
      js: '/etc-new-card/etc-new-card.js',
      title: 'ETC办卡',
      isSingle: true,
      text: 'ETC办卡',
      href: 'etc-new-card',
      auth: 'FLEET_COST_DRIVER_MGT-M-2',
      parent: 'driver'
    },
    {
      html: '/etc-charge-card/etc-charge-card.html',
      css: '/etc-charge-card/etc-charge-card.css',
      js: '/etc-charge-card/etc-charge-card.js',
      title: 'ETC充值',
      isSingle: true,
      text: 'ETC充值',
      href: 'etc-charge-card',
      auth: 'FLEET_COST_DRIVER_MGT-M-3',
      parent: 'driver'
    },
    {
      html: '/etc-passservice-user/etc-passservice-user.html',
      css: '/etc-passservice-user/etc-passservice-user.css',
      js: '/etc-passservice-user/etc-passservice-user.js',
      title: 'ETC通行服务用户管理',
      isSingle: true,
      text: 'ETC通行服务用户管理',
      href: 'etc-passservice-user',
      auth: 'FLEET_COST_DRIVER_MGT-M-4',
      parent: 'driver'
    },
    {
      html: '/etc-passservice-package/etc-passservice-package.html',
      css: '/etc-passservice-package/etc-passservice-package.css',
      js: '/etc-passservice-package/etc-passservice-package.js',
      title: 'ETC通行服务管理',
      isSingle: true,
      text: 'ETC通行服务管理',
      href: 'etc-passservice-package',
      auth: 'FLEET_COST_DRIVER_MGT-M-5',
      parent: 'driver'
    },
    {
      html: '/etc-passservice-card/etc-passservice-card.html',
      css: '/etc-passservice-card/etc-passservice-card.css',
      js: '/etc-passservice-card/etc-passservice-card.js',
      title: 'ETC通行服务卡管理',
      isSingle: true,
      text: 'ETC通行服务卡管理',
      href: 'etc-passservice-card',
      auth: 'FLEET_COST_DRIVER_MGT-M-6',
      parent: 'driver'
    },
    {
      html: '/etc-passservice-invoice/etc-passservice-invoice.html',
      css: '/etc-passservice-invoice/etc-passservice-invoice.css',
      js: '/etc-passservice-invoice/etc-passservice-invoice.js',
      title: 'ETC通行发票列表',
      isSingle: true,
      text: 'ETC通行发票列表',
      href: 'etc-passservice-invoice',
      auth: 'FLEET_COST_DRIVER_MGT-M-7',
      parent: 'driver'
    },
    {
      html: '/etc-passservice-reimbursement-old/etc-passservice-reimbursement-old.html',
      css: '/etc-passservice-reimbursement-old/etc-passservice-reimbursement-old.css',
      js: '/etc-passservice-reimbursement-old/etc-passservice-reimbursement-old.js',
      title: 'ETC通行报销列表-旧',
      isSingle: true,
      text: 'ETC通行报销列表-旧',
      href: 'etc-passservice-reimbursement-old',
      auth: 'FLEET_COST_DRIVER_MGT-M-8',
      parent: 'driver'
    },
    {
      html: '/etc-passservice-reimbursement/etc-passservice-reimbursement.html',
      css: '/etc-passservice-reimbursement/etc-passservice-reimbursement.css',
      js: '/etc-passservice-reimbursement/etc-passservice-reimbursement.js',
      title: 'ETC报销',
      isSingle: true,
      text: 'ETC报销',
      href: 'etc-passservice-reimbursement',
      auth: 'FLEET_COST_DRIVER_MGT-M-9',
      parent: 'driver'
    },
    {
      html: '/etc-passservice-withdrawal/etc-passservice-withdrawal.html',
      css: '/etc-passservice-withdrawal/etc-passservice-withdrawal.css',
      js: '/etc-passservice-withdrawal/etc-passservice-withdrawal.js',
      title: 'ETC提现',
      isSingle: true,
      text: 'ETC提现',
      href: 'etc-passservice-withdrawal',
      auth: 'FLEET_COST_DRIVER_MGT-M-10',
      parent: 'driver'
    },
    {
      html: '/etc-fy-log/etc-fy-log.html',
      css: '/etc-fy-log/etc-fy-log.css',
      js: '/etc-fy-log/etc-fy-log.js',
      title: 'ETC费耘推送卡日志',
      isSingle: true,
      text: 'ETC费耘推送卡日志',
      href: 'etc-fy-log',
      auth: 'FLEET_COST_DRIVER_MGT-M-11',
      parent: 'driver'
    },
    {
      html: '/repay-management/repay-management.html',
      css: '/repay-management/repay-management.css',
      js: '/repay-management/repay-management.js',
      title: '还款管理',
      isSingle: true,
      text: '还款管理',
      href: 'repay-management',
      auth: 'REPAY_MANAGEMENT-M',
      // parent: 'driver'
    },
    {
      html: '/repayment-plan-ccb/repayment-plan-ccb.html',
      css: '/repayment-plan-ccb/repayment-plan-ccb.css',
      js: '/repayment-plan-ccb/repayment-plan-ccb.js',
      title: '建行贷款还款计划',
      isSingle: true,
      text: '建行贷款还款计划',
      href: 'repayment-plan-ccb',
      auth: 'PLAN_CCB-M-1',
      // parent: 'driver'
    },
    {
      html: '/activation-card-ccb/activation-card-ccb.html',
      css: '/activation-card-ccb/activation-card-ccb.css',
      js: '/activation-card-ccb/activation-card-ccb.js',
      title: '建行贷款银行卡激活',
      isSingle: true,
      text: '建行贷款银行卡激活',
      href: 'activation-card-ccb',
      auth: 'PLAN_CCB-M-2',
      // parent: 'driver'
    },
    {
      html: '/driver-bill-make/driver-bill-make.html',
      css: '/driver-bill-make/driver-bill-make.css',
      js: '/driver-bill-make/driver-bill-make.js',
      title: '车老板账单制作',
      isSingle: true,
      text: '车老板账单制作',
      href: 'driver-bill-make',
      auth: 'APP_DRIVER_BILLING_REPORT_CREATE-M',
      // parent: 'driver'
    },
    {
      html: '/driver-bill-management/driver-bill-management.html',
      css: '/driver-bill-management/driver-bill-management.css',
      js: '/driver-bill-management/driver-bill-management.js',
      title: '车老板账单管理',
      isSingle: true,
      text: '车老板账单管理',
      href: 'driver-bill-management',
      auth: 'APP_DRIVER_BILLING_REPORT_MGT-M',
      // parent: 'driver'
    },
    {
      html: '/boss-bill-pay-week/boss-bill-pay-week.html',
      css: '/boss-bill-pay-week/boss-bill-pay-week.css',
      js: '/boss-bill-pay-week/boss-bill-pay-week.js',
      title: '车老板周结现金支付',
      isSingle: true,
      text: '车老板周结现金支付',
      href: 'boss-bill-pay-week',
      auth: 'APP_DRIVER_CASH_PAYMENT-M',
      // parent: 'driver'
    },
    {
      html: '/boss-bill-pay-oil/boss-bill-pay-oil.html',
      css: '/boss-bill-pay-oil/boss-bill-pay-oil.css',
      js: '/boss-bill-pay-oil/boss-bill-pay-oil.js',
      title: '车老板油卡支付',
      isSingle: true,
      text: '车老板油卡支付',
      href: 'boss-bill-pay-oil',
      auth: 'APP_DRIVER_OIL_PAYMENT-M',
      // parent: 'driver'
    },
    {
      html: '/boss-bill-pay-mounth/boss-bill-pay-mounth.html',
      css: '/boss-bill-pay-mounth/boss-bill-pay-mounth.css',
      js: '/boss-bill-pay-mounth/boss-bill-pay-mounth.js',
      title: '车老板月结现金支付',
      isSingle: true,
      text: '车老板月结现金支付',
      href: 'boss-bill-pay-mounth',
      auth: 'APP_DRIVER_VIP_PAYMENT-M-1',
      // parent: 'driver'
    },
    {
      html: '/withdrawal-application/withdrawal-application.html',
      css: '/withdrawal-application/withdrawal-application.css',
      js: '/withdrawal-application/withdrawal-application.js',
      title: '车老板提现申请',
      isSingle: true,
      text: '车老板提现申请',
      href: 'withdrawal-application',
      auth: 'APP_DRIVER_VIP_PAYMENT-M-2',
      parent: 'driver'
    },
    {
      html: '/other-bill-pay/other-bill-pay.html',
      css: '/other-bill-pay/other-bill-pay.css',
      js: '/other-bill-pay/other-bill-pay.js',
      title: '外发账单支付',
      isSingle: true,
      text: '外发账单支付',
      href: 'other-bill-pay',
      auth: 'WF_DRIVER_PAYMENT-M',
      parent: 'driver'
    },
    {
      html: '/platform-configuration/platform-configuration.html',
      css: '/platform-configuration/platform-configuration.css',
      js: '/platform-configuration/platform-configuration.js',
      title: '发单方平台配置',
      isSingle: true,
      text: '发单方平台配置',
      href: 'platform-configuration',
      auth: 'PLATFORM-CONFIGURATION',
      parent: 'driver'
    },
    {
      html: '/signing-record/signing-record.html',
      css: '/signing-record/signing-record.css',
      js: '/signing-record/signing-record.js',
      title: '接单人与接单平台签约记录',
      isSingle: true,
      text: '接单人与接单平台签约记录',
      href: 'signing-record',
      auth: 'SIGNING-RECORD',
      parent: 'driver'
    },
  //   供应商菜单
    {
      html: '/company-cost-new/company-cost-new.html',
      css: '/company-cost/company-cost-new.css',
      js: '/company-cost-new/company-cost-new.js',
      title: '公司成本(新)',
      isSingle: true,
      text: '公司成本(新)',
      href: 'company-cost-new',
      auth: 'NEW_COMPANY_COST-M',
      parent: 'cost-other'
    },
    {
      html: '/supplier-bill-make/supplier-bill-make.html',
      css: '/supplier-bill-make/supplier-bill-make.css',
      js: '/supplier-bill-make/supplier-bill-make.js',
      title: '供应商账单制作',
      isSingle: true,
      text: '供应商账单制作',
      href: 'supplier-bill-make',
      auth: 'SUPPLIER_BILLING_REPORT_CREATE-M',
      parent: 'cost-other'
    },
    {
      html: '/supplier-bill-management/supplier-bill-management.html',
      css: '/supplier-bill-management/supplier-bill-management.css',
      js: '/supplier-bill-management/supplier-bill-management.js',
      title: '供应商账单管理',
      isSingle: true,
      text: '供应商账单管理',
      href: 'supplier-bill-management',
      auth: 'SUPPLIER_BILLING_REPORT_MGT-M-1',
      parent: 'cost-other'
    },
    {
      html: '/supplier-management/supplier-management.html',
      css: '/supplier-management/supplier-management.css',
      js: '/supplier-management/supplier-management.js',
      title: '供应商管理',
      isSingle: true,
      text: '供应商管理',
      href: 'supplier-management',
      auth: 'SUPPLIER_BILLING_REPORT_MGT-M-2',
      parent: 'cost-other'
    },
    {
      html: '/supplier-application/supplier-application.html',
      js: '/supplier-application/supplier-application.js',
      title: '供应商申请',
      isSingle: true,
      text: '供应商申请',
      href: 'supplier-application',
      auth: 'SUPPLIER_APPLICATION',
      parent: 'cost-other'
    },
    {
      html: '/supplier-file/supplier-file.html',
      js: '/supplier-file/supplier-file.js',
      title: '供应商档案',
      isSingle: true,
      text: '供应商档案',
      href: 'supplier-file',
      auth: 'SUPPLIER_FILE',
      parent: 'cost-other'
    },
    {
      html: '/supplier-file-edit/supplier-file-edit.html',
      js: '/supplier-file-edit/supplier-file-edit.js',
      title: '供应商档案编辑',
      isSingle: true,
      text: '供应商档案编辑',
      href: 'supplier-file-edit',
      auth: 'SUPPLIER_FILE_EDIT',
      parent: 'cost-other'
  },
    {
      html: '/supplier-make-bill/supplier-make-bill.html',
      js: '/supplier-make-bill/supplier-make-bill.js',
      title: '供应商账单制作',
      isSingle: true,
      text: '供应商账单制作',
      href: 'supplier-make-bill',
      auth: 'SUPPLIER_MAKE_BILL',
      parent: 'cost-other'
    },
    {
      html: '/supplier-application-company/supplier-application-company.html',
      js: '/supplier-application-company/supplier-application-company.js',
      title: '供应商企业申请',
      isSingle: true,
      text: '供应商企业申请',
      href: 'supplier-application-company',
      auth: 'SUPPLIER_APPLICATION_COMPANY',
    },
    {
      html: '/supplier-application-personal/supplier-application-personal.html',
      js: '/supplier-application-personal/supplier-application-personal.js',
      title: '供应商个人申请',
      isSingle: true,
      text: '供应商个人申请',
      href: 'supplier-application-personal',
      auth: 'SUPPLIER_APPLICATION_PERSONAL',
    },
    {
      html: '/supplier-application-edit/supplier-application-edit.html',
      js: '/supplier-application-edit/supplier-application-edit.js',
      title: '供应商申请编辑',
      isSingle: true,
      text: '供应商申请编辑',
      href: 'supplier-application-edit',
      auth: 'SUPPLIER_APPLICATION_EDIT',
    },
    {
      html: '/supplier-contract-apply-management/supplier-contract-apply-management.html',
      js: '/supplier-contract-apply-management/supplier-contract-apply-management.js',
      title: '供应商合同申请',
      isSingle: true,
      text: '供应商合同申请',
      href: 'supplier-contract-apply-management',
      auth: 'SUPPLIER_CONTRACT_APPLY_MANAGEMENT',
      parent: 'cost-other'
    },
    {
      html: '/shipping-booking-space-bill-detail/shipping-booking-space-bill-detail.html',
      js: '/shipping-booking-space-bill-detail/shipping-booking-space-bill-detail.js',
      title: '供应商账单详情',
      isSingle: true,
      text: '供应商账单详情',
      href: 'shipping-booking-space-bill-detail',
      // auth: 'SUPPLIER_CONTRACT_APPLY_MANAGEMENT',
      // parent: 'cost-other'
    },
  // 商务菜单
    {
      html: '/business-audit/business-audit.html',
      css: '/business-audit/business-audit.css',
      js: '/business-audit/business-audit.js',
      title: '应收应付审核',
      isSingle: true,
      text: '应收应付审核',
      href: 'business-audit',
      auth: 'BUSSINESS_AUDIT-M',
      parent: 'business'
    },
    {
      html: '/profit-report/profit-report.html',
      css: '/profit-report/profit-report.css',
      js: '/profit-report/profit-report.js',
      title: '箱利润报表',
      isSingle: true,
      text: '箱利润报表',
      href: 'profit-report',
      auth: 'REPORT_PROFIT_PER_CTN-M',
      parent: 'business'
    },
    {
      html: '/excess-audit/excess-audit.html',
      css: '/excess-audit/excess-audit.css',
      js: '/excess-audit/excess-audit.js',
      title: '运力审核',
      isSingle: true,
      text: '运力审核',
      href: 'excess-audit',
      auth: 'CUSTOMER_QUOTA-M',
      parent:'business'
    },
    {
      html: '/business-standard-price/business-standard-price.html',
      css: '/business-standard-price/business-standard-price.css',
      js: '/business-standard-price/business-standard-price.js',
      title: '商务标准报价',
      isSingle: true,
      text: '商务标准报价',
      href: 'business-standard-price',
      auth: 'BUSINESS_STANDARD_PRICE-M-2',
      parent: 'business',
    },
    {
      html: '/freight-pricing/freight-pricing.html',
      css: '/freight-pricing/freight-pricing.css',
      js: '/freight-pricing/freight-pricing.js',
      title: '应付运费定价',
      isSingle: true,
      text: '应付运费定价',
      href: 'freight-pricing',
      auth: 'SET_PRICE',
      parent: 'business',
    },

    {
      html: '/local-fee-management/local-fee-management.html',
      css: '/local-fee-management/local-fee-management.css',
      js: '/local-fee-management/local-fee-management.js',
      title: 'Local费用查询',
      isSingle: true,
      text: 'Local费用查询',
      href: 'local-fee-management',
      auth: 'LOCAL-FEE',
      parent: 'business',
    },

    {
      html: '/local-fee-update-list/local-fee-update-list.html',
      css: '/local-fee-update-list/local-fee-update-list.css',
      js: '/local-fee-update-list/local-fee-update-list.js',
      title: 'Local费用变更查询',
      isSingle: true,
      text: 'Local费用变更查询',
      href: 'local-fee-update-list',
      auth: 'LOCAL-FEE-UPDATE',
      parent: 'business',
    },

    {
      html: '/local-fee-update-add/local-fee-update-add.html',
      css: '/local-fee-update-add/local-fee-update-add.css',
      js: '/local-fee-update-add/local-fee-update-add.js',
      title: 'Local费用变更新增',
      isSingle: true,
      text: 'Local费用变更新增',
      href: 'local-fee-update-add',
      auth: 'LOCAL-FEE-UPDATE-ADD',
      // parent: 'business',
    },

    {
      html: '/local-fee-update-edit/local-fee-update-edit.html',
      css: '/local-fee-update-edit/local-fee-update-edit.css',
      js: '/local-fee-update-edit/local-fee-update-edit.js',
      title: 'Local费用变更更改',
      isSingle: true,
      text: 'Local费用变更更改',
      href: 'local-fee-update-edit',
      auth: 'LOCAL-FEE-UPDATE-EDIT',
      // parent: 'business',
    },


    {
      html: '/surcharge-rules/surcharge-rules.html',
      css: '/surcharge-rules/surcharge-rules.css',
      js: '/surcharge-rules/surcharge-rules.js',
      title: '附加费用规则',
      isSingle: true,
      text: '附加费用规则',
      href: 'surcharge-rules',
      auth: 'SURCHARGE_RULES',
      parent: 'business',
    },
    {
      html: '/etc-standard-price/etc-standard-price.html',
      css: '/etc-standard-price/etc-standard-price.css',
      js: '/etc-standard-price/etc-standard-price.js',
      title: 'ETC标准报价',
      isSingle: true,
      text: 'ETC标准报价',
      href: 'etc-standard-price',
      auth: 'BUSINESS_STANDARD_PRICE-M-2',
      parent: 'business',
    },
    {
      html: '/black-menu/black-menu.html',
      css: '/black-menu/black-menu.css',
      js: '/black-menu/black-menu.js',
      title: '失信名单',
      isSingle: true,
      text: '失信名单',
      href: 'black-menu',
      auth: 'BLACK_MENU-M',
      parent: 'business',
    },
    {
      css: '/register-review/register-review.css',
      html: '/register-review/register-review.html',
      js: '/register-review/register-review.js',
      isSingle: true,
      title: '新客户商务风控',
      text: '新客户商务风控',
      parent: 'business',
      auth: 'REGISTER_REVIEW-M',
      href: 'register-review'
    },
    {
      css: '/invoice-review/invoice-review.css',
      html: '/invoice-review/invoice-review.html',
      js: '/invoice-review/invoice-review.js',
      isSingle: true,
      title: '新增客户发票抬头审核',
      text: '新增客户发票抬头审核',
      parent: 'business',
      auth: 'INVOICE-REVIEW-M',
      href: 'invoice-review'
    },
    {
      css: '/invoice-review-bd/invoice-review-bd.css',
      html: '/invoice-review-bd/invoice-review-bd.html',
      js: '/invoice-review-bd/invoice-review-bd.js',
      isSingle: true,
      title: '新增客户发票抬头法务审核',
      text: '新增客户发票抬头法务审核',
      parent: 'business',
      auth: 'INVOICE-REVIEW-BD-M',
      href: 'invoice-review-bd'
    },
    {
      html: '/port-charges/port-charges.html',
      css: '/port-charges/port-charges.css',
      js: '/port-charges/port-charges.js',
      title: '异常费用公告编辑',
      isSingle: true,
      text: '异常费用公告编辑',
      href: 'port-charges',
      auth: 'PORT_CHARGES-M',
      parent: 'business',
    },
    {
      html: '/daily-quota-setting/daily-quota-setting.html',
      css: '/daily-quota-setting/daily-quota-setting.css',
      js: '/daily-quota-setting/daily-quota-setting.js',
      title: '每日箱量额度设置',
      isSingle: true,
      text: '每日箱量额度设置',
      href: 'daily-quota-setting',
      auth: 'BOX_CONFIHURATION-M',
      parent: 'business',
    },
    {
      html: '/peak-season-quota/peak-season-quota.html',
      css: '/peak-season-quota/peak-season-quota.css',
      js: '/peak-season-quota/peak-season-quota.js',
      title: '旺季&额度设置',
      isSingle: true,
      text: '旺季&额度设置',
      href: 'peak-season-quota',
      auth: 'PEAK_SEASON_QUOTA-M',
      parent:'business'
    },
    {
      html: '/peak-season-quota-details/peak-season-quota-details.html',
      css: '/peak-season-quota-details/peak-season-quota-details.css',
      js: '/peak-season-quota-details/peak-season-quota-details.js',
      title: '旺季&额度设置详情',
      isSingle: true,
      text: '旺季&额度设置详情',
      href: 'peak-season-quota-details',
      auth: 'PEAK_SEASON_QUOTA_DETAILS-M',
      parent:'business'
    },
    {
      html: '/multiple-locations-price-settings/multiple-locations-price-settings.html',
      css: '/multiple-locations-price-settings/multiple-locations-price-settings.css',
      js: '/multiple-locations-price-settings/multiple-locations-price-settings.js',
      title: '多地装价格设置',
      isSingle: true,
      text: '多地装价格设置',
      href: 'multiple-locations-price-settings',
      auth: 'MULTIPLE-LOCATIONS-PRICE-SETTINGS-M',
      parent: 'business'
    },
    {
      html: '/driver-line-settings/driver-line-settings.html',
      css: '/driver-line-settings/driver-line-settings.css',
      js: '/driver-line-settings/driver-line-settings.js',
      title: '设置线路',
      isSingle: true,
      text: '设置线路',
      href: 'driver-line-settings',
      auth: 'DRIVER-LINE-SETTINGS',
      parent: 'business'
    },
    {
      html: '/driver-line-edit/driver-line-edit.html',
      css: '/driver-line-edit/driver-line-edit.css',
      js: '/driver-line-edit/driver-line-edit.js',
      title: '设置司机路线',
      isSingle: true,
      text: '设置司机路线',
      href: 'driver-line-edit',
      auth: 'DRIVER-LINE-EDIT',
      parent: 'business'
    },
    {
      html: '/driver-line-manage/driver-line-manage.html',
      css: '/driver-line-manage/driver-line-manage.css',
      js: '/driver-line-manage/driver-line-manage.js',
      title: '线路管理',
      isSingle: true,
      text: '线路管理',
      href: 'driver-line-manage',
      auth: 'DRIVER-LINE-MANAGE',
      parent: 'business'
    },
    {
      html: '/performance-deposit/performance-deposit.html',
      css: '/performance-deposit/performance-deposit.css',
      js: '/performance-deposit/performance-deposit.js',
      title: '履约金待确认',
      isSingle: true,
      text: '履约金待确认',
      href: 'performance-deposit',
      auth: 'PERFORMANCE-DEPOSIT',
      parent: 'business'
    },
  //   运维菜单
    {
      html: '/driver-blacklist/driver-blacklist.html',
      css: '/driver-blacklist/driver-blacklist.css',
      js: '/driver-blacklist/driver-blacklist.js',
      title: '司机黑名单',
      isSingle: true,
      text: '司机黑名单',
      href: 'driver-blacklist',
      // auth: 'YZS_DOOR_COST',
      auth:"DRIVER_BLACKLIST-M",
      parent: 'operation-maintenance',
      seq: 3
    },
    {
      html: '/version-driver-whitelist/version-driver-whitelist.html',
      css: '/version-driver-whitelist/version-driver-whitelist.css',
      js: '/version-driver-whitelist/version-driver-whitelist.js',
      title: '司机灰度名单(业财一体)',
      isSingle: true,
      text: '司机灰度名单(业财一体)',
      href: 'version-driver-whitelist',
      auth: 'DRIVER_GRAY_LIST',
      parent: 'operation-maintenance',
      seq: 3
    },
    
    {
      html: '/blocking-driver-audit/blocking-driver-audit.html',
      css: '/blocking-driver-audit/blocking-driver-audit.css',
      js: '/blocking-driver-audit/blocking-driver-audit.js',
      title: '拉黑审核',
      isSingle: true,
      text: '拉黑审核',
      href: 'blocking-driver-audit',
      // auth: 'YZS_DOOR_COST',
      auth:"DRIVER_BLACKLIST-M",
      parent: 'operation-maintenance',
      seq: 3
    },
    {
      html: '/notice-editor/notice-editor.html',
      css: '/notice-editor/notice-editor.css',
      js: '/notice-editor/notice-editor.js',
      title: '公告编辑',
      isSingle: true,
      text: '公告编辑',
      href: 'notice-editor',
      auth: 'ANNOUNCEMENT_EDITOR-M',
      parent: 'operation-maintenance',
      seq: 3
    },
    {
      html: '/share-image-config/share-image-config.html',
      css: '/share-image-config/share-image-config.css',
      js: '/share-image-config/share-image-config.js',
      title: '分享图片配置',
      isSingle: true,
      text: '分享图片配置',
      href: 'share-image-config',
      auth: 'SHARE_PICTURE_CONFIGURATION',
      parent: 'operation-maintenance',
      seq: 3
    },
    {
      html: '/group-message/group-message.html',
      css: '/group-message/group-message.css',
      js: '/group-message/group-message.js',
      title: '群发消息',
      isSingle: true,
      text: '群发消息',
      seq: 3,
      href: 'group-message',
      auth: 'SEND_MESSAGE-M',
      parent: 'operation-maintenance'
    },
    {
      html: '/season-strategy-setting/season-strategy-setting.html',
      css: '/season-strategy-setting/season-strategy-setting.css',
      js: '/season-strategy-setting/season-strategy-setting.js',
      title: '旺季策略配置',
      isSingle: true,
      text: '旺季策略配置',
      href: 'season-strategy-setting',
      auth: 'STRATEGY_SEASON-M',
      parent: 'operation-maintenance',
      seq: 3
    },
    {
      html: '/trace-log-management/trace-log-management.html',
      css: '/trace-log-management/trace-log-management.css',
      js: '/trace-log-management/trace-log-management.js',
      title: '跟踪日志管理',
      isSingle: true,
      text: '跟踪日志管理',
      href: 'trace-log-management',
      auth: 'SYS_TRACKING_LOG_MGT-M',
      parent: 'operation-maintenance',
    },
    {
      html: '/capacity-account/capacity-account.html',
      css: '/capacity-account/capacity-account.css',
      js: '/capacity-account/capacity-account.js',
      title: '司机账户',
      isSingle: true,
      text: '司机账户',
      href: 'capacity-account',
      auth: 'DRIVER_ACCOUNT_CENTER-M-1',
      parent: 'operation-maintenance',
    },
    {
      html: '/capacity-credit/capacity-credit.html',
      css: '/capacity-credit/capacity-credit.css',
      js: '/capacity-credit/capacity-credit.js',
      title: '司机授信额度',
      isSingle: true,
      text: '司机授信额度',
      href: 'capacity-credit',
      auth: 'DRIVER_ACCOUNT_CENTER-M-3',
      parent: 'operation-maintenance',
    },
    {
      html: '/capacity-amount-flow/capacity-amount-flow.html',
      css: '/capacity-amount-flow/capacity-amount-flow.css',
      js: '/capacity-amount-flow/capacity-amount-flow.js',
      title: '余额详情',
      isSingle: true,
      text: '余额详情',
      href: 'capacity-amount-flow',
      auth: 'DRIVER_ACCOUNT_CENTER-M-1',
    },
    {
      html: '/driver-account-old/driver-account-old.html',
      css: '/driver-account-old/driver-account-old.css',
      js: '/driver-account-old/driver-account-old.js',
      title: '司机账户(旧)',
      isSingle: true,
      text: '司机账户(旧)',
      href: 'driver-account-old',
      auth: 'DRIVER_ACCOUNT_CENTER-M-1',
      // parent: 'operation-maintenance',
    },
    {  
      html: '/driver-approve/driver-approve.html',
      css: '/driver-approve/driver-approve.css',
      js: '/driver-approve/driver-approve.js',
      title: '司机账户操作审批',
      isSingle: true,
      text: '司机账户操作审批',
      href: 'driver-approve',
      auth: 'DRIVER_ACCOUNT_CENTER-M-2',
      parent: 'operation-maintenance',
    },
    {
      html: '/grab-list/grab-list.html',
      css: '/grab-list/grab-list.css',
      js: '/grab-list/grab-list.js',
      title: '抢单列表',
      isSingle: true,
      text: '抢单列表',
      href: 'grab-list',
      auth: 'DRIVER_ACCOUNT_CENTER',
      parent: 'operation-maintenance-M-3',
    },
    {
      html: '/driver-cost-maintenance/driver-cost-maintenance.html',
      css: '/driver-cost-maintenance/driver-cost-maintenance.css',
      js: '/driver-cost-maintenance/driver-cost-maintenance.js',
      title: '应付费用维护',
      isSingle: true,
      text: '应付费用维护',
      href: 'driver-cost-maintenance',
      auth: 'COST_MODIFY_SUPPORT-M',
      parent: 'operation-maintenance'
    },
    {
      html: '/maintain-mail-server/maintain-mail-server.html',
      css: '/maintain-mail-server/maintain-mail-server.css',
      js: '/maintain-mail-server/maintain-mail-server.js',
      title: '邮件发送记录',
      isSingle: true,
      text: '邮件发送记录',
      href: 'maintain-mail-server',
      auth: 'EMAIL_LOG-M',
      parent:'operation-maintenance'
    },
    {
      html: '/maintain-message-server/maintain-message-server.html',
      css: '/maintain-message-server/maintain-message-server.css',
      js: '/maintain-message-server/maintain-message-server.js',
      title: '短信发送记录',
      isSingle: true,
      text: '短信发送记录',
      href: 'maintain-message-server',
      auth: 'SMS_LOG-M',
      parent:'operation-maintenance'
    },
    {
      html: '/to-dispatch-analysis/to-dispatch-analysis.html',
      css: '/to-dispatch-analysis/to-dispatch-analysis.css',
      js: '/to-dispatch-analysis/to-dispatch-analysis.js',
      title: '调度评价',
      isSingle: true,
      text: '调度评价',
      href: 'to-dispatch-analysis',
      auth: 'DISPATCH_ANALYSIS-M',
      parent: 'operation-maintenance',
    },
    {
      html: '/suspend-business/suspend-business.html',
      css: '/suspend-business/suspend-business.css',
      js: '/suspend-business/suspend-business.js',
      title: '暂停业务',
      isSingle: true,
      text: '暂停业务',
      href: 'suspend-business',
      auth:'PANEL_BUSINESS_SUSPENSION-M',
      parent: 'operation-maintenance'
    },
    {
      html: '/driver-location-state/driver-location-state.html',
      css: '/driver-location-state/driver-location-state.css',
      js: '/driver-location-state/driver-location-state.js',
      title: '司机北斗信号状态',
      isSingle: true,
      text: '司机北斗信号状态',
      href: 'driver-location-state',
      auth: 'YZS_DOOR_COST-M-4',
      parent: 'operation-maintenance',
    },
    {
      html: '/ship-maintenance/ship-maintenance.html',
      css: '/ship-maintenance/ship-maintenance.css',
      js: '/ship-maintenance/ship-maintenance.js',
      title: '船期维护',
      isSingle: true,
      text: '船期维护',
      href: 'ship-maintenance',
      auth: 'SCHEDULE_MANAGEMENT-M',
      parent: 'operation-maintenance',
    },
    {
      html: '/customer-truck-block/customer-truck-block.html',
      css: '/customer-truck-block/customer-truck-block.css',
      js: '/customer-truck-block/customer-truck-block.js',
      title: '货车位置定位屏蔽',
      isSingle: true,
      text: '货车位置定位屏蔽',
      href: 'customer-truck-block',
      auth: 'CUSTOMER-TRUCK-BLOCK-M-1',
      parent: 'operation-maintenance',
    },
    {
      html: '/customer-truck-binding/customer-truck-binding.html',
      css: '/customer-truck-binding/customer-truck-binding.css',
      js: '/customer-truck-binding/customer-truck-binding.js',
      title: '货车位置注册审核',
      isSingle: true,
      text: '货车位置注册审核',
      href: 'customer-truck-binding',
      auth: 'CUSTOMER-TRUCK-BINDING-M',
      parent: 'operation-maintenance',
    },
    {
      html: '/customer-truck-count/customer-truck-count.html',
      css: '/customer-truck-count/customer-truck-count.css',
      js: '/customer-truck-count/customer-truck-count.js',
      title: '货车位置统计(维运网)',
      isSingle: true,
      text: '货车位置统计(维运网)',
      href: 'customer-truck-count',
      auth: 'CUSTOMER-TRUCK-BLOCK-M-2',
      parent: 'operation-maintenance',
    },
    {
      html: '/capacity-estimation/capacity-estimation.html',
      css: '/capacity-estimation/capacity-estimation.css',
      js: '/capacity-estimation/capacity-estimation.js',
      title: '可用运力预估',
      isSingle: true,
      text: '可用运力预估',
      href: 'capacity-estimation',
      auth: 'CUSTOMER-TRUCK-BLOCK-M-3',
      parent: 'operation-maintenance',
    },
    {
      html: '/peak-day-config/peak-day-config.html',
      js: '/peak-day-config/peak-day-config.js',
      title: '旺时&节日设置',
      isSingle: true,
      text: '旺时&节日设置',
      href: 'peak-day-config',
      auth: 'PEAK_DAY_CONFIG-M',
      parent: 'operation-maintenance',
    },
    {
      html: '/base-data-warehouse/base-data-warehouse.html',
      css: '/base-data-warehouse/base-data-warehouse.css',
      js: '/base-data-warehouse/base-data-warehouse.js',
      title: '基础字段仓库',
      isSingle: true,
      text: '基础字段仓库',
      href: 'base-data-warehouse',
      auth: 'BASE-DATE-M',
      parent: 'operation-maintenance',
    },
  // 系统管理菜单
    {
      html: '/business-parameters/business-parameters.html',
      css: '/business-parameters/business-parameters.css',
      js: '/business-parameters/business-parameters.js',
      title: '客户询价参数设置',
      isSingle: true,
      parent: 'system-manager',
      text: '客户询价参数设置',
      auth: 'BUSINESS_PARAMETERS-M',
      href: 'business-parameters'
    },
    {
      html: '/role-management/role-management.html',
      css: '/role-management/role-management.css',
      js: '/role-management/role-management.js',
      title: '角色管理',
      isSingle: true,
      text: '角色管理',
      href: 'role-management',
      auth: 'SYS_ROLE_MGT-M',
      parent: 'system-manager'
    },
    {
      html: '/auth-management/auth-management.html',
      css: '/auth-management/auth-management.css',
      js: '/auth-management/auth-management.js',
      title: '权限管理',
      isSingle: true,
      text: '权限管理',
      href: 'auth-management',
      auth: 'SYS_AUTH_MGT-M-1',
      parent: 'system-manager'
    },
    {
      html: '/cost-setting/cost-setting.html',
      css: '/cost-setting/cost-setting.css',
      js: '/cost-setting/cost-setting.js',
      title: '费用项设置',
      isSingle: true,
      text: '费用项设置',
      href: 'cost-setting',
      auth: 'SYS_AUTH_MGT-M-2',
      parent: 'system-manager'
    },
    {
      html: '/accounts-management/accounts-management.html',
      css: '/accounts-management/accounts-management.css',
      js: '/accounts-management/accounts-management.js',
      title: '系统账户管理',
      isSingle: true,
      text: '系统账户管理',
      href: 'accounts-management',
      auth: 'SYS_AUTH_MGT-M-3',
      parent: 'system-manager'
    },
    {
      html: '/customer-service-group-management/customer-service-group-management.html',
      css: '/customer-service-group-management/customer-service-group-management.css',
      js: '/customer-service-group-management/customer-service-group-management.js',
      title: '客服组管理',
      isSingle: true,
      text: '客服组管理',
      href: 'customer-service-group-management',
      auth: 'SYS_CUS_GROP-M',
      parent: 'system-manager'
    },
    {
      html: '/customer-service-duty/customer-service-duty.html',
      css: '/customer-service-duty/customer-service-duty.css',
      js: '/customer-service-duty/customer-service-duty.js',
      title: '客服电话值班',
      isSingle: true,
      text: '客服电话值班',
      href: 'customer-service-duty',
      auth: 'SERVICE_PHONE_DUTY',
      parent: 'system-manager'
    },
    {
      html: '/night-customer-service-duty/night-customer-service-duty.html',
      css: '/night-customer-service-duty/night-customer-service-duty.css',
      js: '/night-customer-service-duty/night-customer-service-duty.js',
      title: '夜班客服值班',
      isSingle: true,
      text: '夜班客服值班',
      href: 'night-customer-service-duty',
      auth: 'SERVICE_PHONE_DUTY',
      parent: 'system-manager'
    },
    {
      html: '/at-online-monitoring/at-online-monitoring.html',
      css: '/at-online-monitoring/at-online-monitoring.css',
      js: '/at-online-monitoring/at-online-monitoring.js',
      title: '坐席在线监控',
      isSingle: true,
      text: '坐席在线监控',
      href: 'at-online-monitoring',
      auth: 'SYS_ONL_MONI',
      parent: 'system-manager'
      
    },
    {
      html: '/agent-class-management/agent-class-management.html',
      css: '/agent-class-management/agent-class-management.css',
      js: '/agent-class-management/agent-class-management.js',
      title: '代班管理',
      isSingle: true,
      text: '代班管理',
      href: 'agent-class-management',
      auth: 'SYS__AGENT_GROP',
      parent: 'system-manager'
    },
    {
      html: '/password-modify/password-modify.html',
      css: '/password-modify/password-modify.css',
      js: '/password-modify/password-modify.js',
      title: '密码修改',
      isSingle: true,
      text: '密码修改',
      href: 'password-modify',
      parent: 'system-manager'
    },
    {
      html: '/factory-archives/factory-archives.html',
      css: '/factory-archives/factory-archives.css',
      js: '/factory-archives/factory-archives.js',
      title: '工厂档案',
      isSingle: true,
      text: '工厂档案',
      href: 'factory-archives',
      auth: 'SYS_FACTORY_ARCHIVES-M',
      parent:'system-manager'
    },
    {
      html: '/factory-comment/factory-comment.html',
      css: '/factory-comment/factory-comment.css',
      js: '/factory-comment/factory-comment.js',
      title: '工厂评价',
      isSingle: true,
      text: '工厂评价',
      href: 'factory-comment',
      auth: 'FACTORY-COMMENT',
      parent:'system-manager'
    },
    {
        html: '/door-point-audit/door-point-audit.html',
        css: '/door-point-audit/door-point-audit.css',
        js: '/door-point-audit/door-point-audit.js',
        title: '门点地址审核任务',
        isSingle: true,
        text: '门点地址审核任务',
        href: 'door-point-audit',
        auth: 'DOOR_POINT_AUDIT',
        parent:'system-manager'
    },
    {
        html: '/yard-point-audit/yard-point-audit.html',
        css: '/yard-point-audit/yard-point-audit.css',
        js: '/yard-point-audit/yard-point-audit.js',
        title: '堆场地址审核任务',
        isSingle: true,
        text: '堆场地址审核任务',
        href: 'yard-point-audit',
        auth: 'YARD_POINT_AUDIT',
        parent:'system-manager'
    },
    {
        html: '/quay-point-audit/quay-point-audit.html',
        css: '/quay-point-audit/quay-point-audit.css',
        js: '/quay-point-audit/quay-point-audit.js',
        title: '港区/码头地址审核任务',
        isSingle: true,
        text: '港区/码头地址审核任务',
        href: 'quay-point-audit',
        auth: 'QUAY_POINT_AUDIT',
        parent:'system-manager'
    },
    {
      html: '/container-yard/container-yard.html',
      css: '/container-yard/container-yard.css',
      js: '/container-yard/container-yard.js',
      title: '堆场',
      isSingle: true,
      text: '堆场',
      href: 'container-yard',
      auth: 'SYS_DEPOT_MGT-M-1',
      parent: 'system-manager'
    },
    {
      html: '/container-storehouse/container-storehouse.html',
      css: '/container-storehouse/container-storehouse.css',
      js: '/container-storehouse/container-storehouse.js',
      title: '仓库',
      isSingle: true,
      text: '仓库',
      href: 'container-storehouse',
      auth: 'SYS_DEPOT_MGT-M-2',
      parent: 'system-manager'
    },
    {
      html: '/send-bill-pot/send-bill-pot.html',
      css: '/send-bill-pot/send-bill-pot.css',
      js: '/send-bill-pot/send-bill-pot.js',
      title: '寄单点',
      isSingle: true,
      text: '寄单点',
      href: 'send-bill-pot',
      auth: 'SYS_DEPOT_MGT-M-3',
      parent: 'system-manager'
    },
    {
      html: '/sealed-maintenance/sealed-maintenance.html',
      css: '/sealed-maintenance/sealed-maintenance.css',
      js: '/sealed-maintenance/sealed-maintenance.js',
      title: '加封地维护',
      isSingle: true,
      text: '加封地维护',
      href: 'sealed-maintenance',
      auth: 'SYS_DEPOT_MGT-M-4',
      parent: 'system-manager'
    },
    {
      html: '/door-area/door-area.html',
      css: '/door-area/door-area.css',
      js: '/door-area/door-area.js',
      title: '门点区域',
      isSingle: true,
      text: '门点区域',
      href: 'door-area',
      auth: 'YZS_DOOR_AREA-M',
      parent: 'system-manager',
    },
    {
      html: '/small-container-adjustment/small-container-adjustment.html',
      css: '/small-container-adjustment/small-container-adjustment.css',
      js: '/small-container-adjustment/small-container-adjustment.js',
      href: 'small-container-adjustment',
      title: '拼车改时间与摆放',
      isSingle: true,
      text: '拼车改时间与摆放',
    },
    {
      html: '/container-requires-maintenance/container-requires-maintenance.html',
      css: '/container-requires-maintenance/container-requires-maintenance.css',
      js: '/container-requires-maintenance/container-requires-maintenance.js',
      title: '做箱&提箱要求维护',
      isSingle: true,
      text: '做箱&提箱要求维护',
      href: 'container-requires-maintenance',
      auth: 'CONTAINER_REQUIRES_MAINTENANCE-M',
      parent: 'system-manager'
    },
    {
      html: '/shipping-company-manage/shipping-company-manage.html',
      css: '/shipping-company-manage/shipping-company-manage.css',
      js: '/shipping-company-manage/shipping-company-manage.js',
      title: '船公司管理',
      isSingle: true,
      text: '船公司管理',
      href: 'shipping-company-manage',
      auth: 'SHIPPING_COMPANY_MANAGE-M',
      parent: 'system-manager'
    },
    {
      html: '/customer-tip/customer-tip.html',
      css: '/customer-tip/customer-tip.css',
      js: '/customer-tip/customer-tip.js',
      title: '客户标签设置',
      isSingle: true,
      text: '客户标签设置',
      href: 'customer-tip',
      auth: 'CUSTOMER_LABEL-M',
      parent: 'system-manager'
    },
    {
      html: '/attachment-setting/attachment-setting.html',
      css: '/attachment-setting/attachment-setting.css',
      js: '/attachment-setting/attachment-setting.js',
      title: '附件设置',
      isSingle: true,
      text: '附件设置',
      href: 'attachment-setting',
      auth: 'SYS_AUTH_MGT-M-4',
      parent: 'system-manager'
    },
    {
      html: '/expense-instruction/expense-instruction.html',
      css: '/expense-instruction/expense-instruction.css',
      js: '/expense-instruction/expense-instruction.js',
      title: '费用指令',
      isSingle: true,
      text: '费用指令',
      href: 'expense-instruction',
      auth: 'SYS_COST_INSTRUCTION',
      parent: 'system-manager',
    },
    {
      css: '/land-transform-water-config/land-transform-water-config.css',
      html: '/land-transform-water-config/land-transform-water-config.html',
      js: '/land-transform-water-config/land-transform-water-config.js',
      isSingle: true,
      title: '陆改水设置',
      text: '陆改水设置',
      parent: 'system-manager',
      auth: 'LAND_TRANSFORM_WATER_CONFIG',
      href: 'land-transform-water-config'
    },
    {
      html: '/driver-line-point/driver-line-point.html',
       css: '/driver-line-point/driver-line-point.css',
       js: '/driver-line-point/driver-line-point.js',
       title: '设置线路必经点',
       isSingle: true,
       text: '设置线路必经点',
       href: 'driver-line-point',
       auth: 'DRIVER_LINE_POINT',
       parent: 'system-manager'
    },
  //   空箱调拨菜单
    {
      html: '/feidansender-audit/feidansender-audit.html',
      css: '/feidansender-audit/feidansender-audit.css',
      js: '/feidansender-audit/feidansender-audit.js',
      title: '发单人审核',
      isSingle: true,
      text: '发单人审核',
      auth: 'FEIDAN_CTN_NO-M-1',
      parent: 'empty-container',
      href: 'feidansender-audit'
    },
    {
      html: '/busy-season-approval/busy-season-approval.html',
      css: '/busy-season-approval/busy-season-approval.css',
      js: '/busy-season-approval/busy-season-approval.js',
      title: '旺季超额审批',
      isSingle: true,
      auth: 'BUSY_SEASON_APPROVAL',
      text: '旺季超额审批',
      href: 'busy-season-approval'
    },
    {
      html: '/busy-season-approval-detail/busy-season-approval-detail.html',
      css: '/busy-season-approval-detail/busy-season-approval-detail.css',
      js: '/busy-season-approval-detail/busy-season-approval-detail.js',
      title: '旺季超额审批详情',
      isSingle: true,
      auth: 'BUSY_SEASON_APPROVAL',
      text: '旺季超额审批详情',
      href: 'busy-season-approval-detail'
    },
    {
      html: '/ocean-freight-booking-inquiry/ocean-freight-booking-inquiry.html',
      css: '/ocean-freight-booking-inquiry/ocean-freight-booking-inquiry.css',
      js: '/ocean-freight-booking-inquiry/ocean-freight-booking-inquiry.js',
      title: '海运订舱待报价',
      isSingle: true,
      text: '海运订舱待报价',
      href: 'ocean-freight-booking-inquiry'
    },
    {
      html: '/sea-freight-booking-confirm/sea-freight-booking-confirm.html',
      css: '/sea-freight-booking-confirm/sea-freight-booking-confirm.css',
      js: '/sea-freight-booking-confirm/sea-freight-booking-confirm.js',
      title: '海运订舱待确认舱位',
      isSingle: true,
      text: '海运订舱待确认舱位',
      href: 'sea-freight-booking-confirm'
    },
    {
      // 废弃
      html: '/sea-freight-booking-inquiry-list/sea-freight-booking-inquiry-list.html',
      css: '/sea-freight-booking-inquiry-list/sea-freight-booking-inquiry-list.css',
      js: '/sea-freight-booking-inquiry-list/sea-freight-booking-inquiry-list.js',
      title: '海运订舱询价',
      isSingle: true,
      text: '海运订舱询价',
      href: 'sea-freight-booking-inquiry-list'
    },
    {
      html: '/shipping-booking-inquery/shipping-booking-inquery.html',
      css: '/shipping-booking-inquery/shipping-booking-inquery.css',
      js: '/shipping-booking-inquery/shipping-booking-inquery.js',
      title: '海运订舱待报价',
      isSingle: true,
      text: '海运订舱待报价',
      href: 'shipping-booking-inquery'
    },
    {
      html: '/maintain-supplier-prices/maintain-supplier-prices.html',
      css: '/maintain-supplier-prices/maintain-supplier-prices.css',
      js: '/maintain-supplier-prices/maintain-supplier-prices.js',
      title: '维护供应商报价',
      isSingle: true,
      text: '维护供应商报价',
      href: 'maintain-supplier-prices'
    },
    {
      html: '/shipping-wait-priced/shipping-wait-priced.html',
      css: '/shipping-wait-priced/shipping-wait-priced.css',
      js: '/shipping-wait-priced/shipping-wait-priced.js',
      title: '海运待定价',
      isSingle: true,
      text: '海运待定价',
      href: 'shipping-wait-priced'
    },
    {
      html: '/shipping-booking-pricing/shipping-booking-pricing.html',
      css: '/shipping-booking-pricing/shipping-booking-pricing.css',
      js: '/shipping-booking-pricing/shipping-booking-pricing.js',
      title: '海运订舱定价',
      isSingle: true,
      text: '海运订舱定价',
      href: 'shipping-booking-pricing'
    },
    {
      html: '/shipping-pricing-deviation/shipping-pricing-deviation.html',
      css: '/shipping-pricing-deviation/shipping-pricing-deviation.css',
      js: '/shipping-pricing-deviation/shipping-pricing-deviation.js',
      title: '海运定价偏离',
      isSingle: true,
      text: '海运定价偏离',
      href: 'shipping-pricing-deviation'
    },
    {
      html: '/shipping-pricing-deviation-adjust/shipping-pricing-deviation-adjust.html',
      css: '/shipping-pricing-deviation-adjust/shipping-pricing-deviation-adjust.css',
      js: '/shipping-pricing-deviation-adjust/shipping-pricing-deviation-adjust.js',
      title: '海运订舱定价偏离调整',
      isSingle: true,
      text: '海运订舱定价偏离调整',
      href: 'shipping-pricing-deviation-adjust'
    },
    {
      html: '/shipping-pricing-detail/shipping-pricing-detail.html',
      css: '/shipping-pricing-detail/shipping-pricing-detail.css',
      js: '/shipping-pricing-detail/shipping-pricing-detail.js',
      title: '海运订舱定价详情',
      isSingle: true,
      text: '海运订舱定价详情',
      href: 'shipping-pricing-detail'
    },
    {
      html: '/shipping-cost-detail/shipping-cost-detail.html',
      css: '/shipping-cost-detail/shipping-cost-detail.css',
      js: '/shipping-cost-detail/shipping-cost-detail.js',
      title: '维护供应商价格详情',
      isSingle: true,
      text: '维护供应商价格详情',
      href: 'shipping-cost-detail'
    },
    {
      html: '/shipping-order-confirm-final-price/shipping-order-confirm-final-price.html',
      css: '/shipping-order-confirm-final-price/shipping-order-confirm-final-price.css',
      js: '/shipping-order-confirm-final-price/shipping-order-confirm-final-price.js',
      title: '待确认成交价',
      isSingle: true,
      text: '待确认成交价',
      href: 'shipping-order-confirm-final-price'
    },
    {
      html: '/shipping-order-wait-supplement-price/shipping-order-wait-supplement-price.html',
      css: '/shipping-order-wait-supplement-price/shipping-order-wait-supplement-price.css',
      js: '/shipping-order-wait-supplement-price/shipping-order-wait-supplement-price.js',
      title: '待补充订舱成交价',
      isSingle: true,
      text: '待补充订舱成交价',
      href: 'shipping-order-wait-supplement-price'
    },
    {
      css: '/empty-container-business/empty-container-business.css',
      html: '/empty-container-business/empty-container-business.html',
      js: '/empty-container-business/empty-container-business.js',
      isSingle: true,
      parent: 'empty-container',
      title: '空箱业务',
      text: '空箱业务',
      auth: 'FEIDAN_CTN_NO-M-2',
      href: 'empty-container-business'
    },
  //   活动中心菜单
    {
      html: '/act-detail/act-detail.html',
      css: '/act-detail/act-detail.css',
      js: '/act-detail/act-detail.js',
      title: '活动详情',
      isSingle: true,
      text: '活动详情',
      href: 'act-detail',
      auth: 'MEETING_APPLY_CONFIRM-M',
      // parent:'offline-operation'
      parent:'active-center'
    },
    {
      html: '/exhibition-information/exhibition-information.html',
      css: '/exhibition-information/exhibition-information.css',
      js: '/exhibition-information/exhibition-information.js',
      title: '展会管理',
      isSingle: true,
      text: '展会管理',
      href: 'exhibition-information',
      auth: 'EXHIBITION-INFORMATION',
      parent:'customer'
    },
    {
      html: '/moon-search/moon-search.html',
      css: '/moon-search/moon-search.css',
      js: '/moon-search/moon-search.js',
      title: '客户礼品发放',
      isSingle: true,
      text: '客户礼品发放',
      href: 'moon-search',
      auth: 'CUSTOMER_GIFT_DISTRIBUTION-M-1',
      parent:'active-center'
    },
    {
      html: '/user-point-goods/user-point-goods.html',
      css: '/user-point-goods/user-point-goods.css',
      js: '/user-point-goods/user-point-goods.js',
      title: '积分商品设置',
      isSingle: true,
      text: '积分商品设置',
      href: 'user-point-goods',
      auth: 'CUSTOMER_GIFT_DISTRIBUTION-M-2',
      parent:'active-center'
    },
    {
      html: '/user-point-exchange-record/user-point-exchange-record.html',
      css: '/user-point-exchange-record/user-point-exchange-record.css',
      js: '/user-point-exchange-record/user-point-exchange-record.js',
      title: '积分兑换订单',
      isSingle: true,
      text: '积分兑换订单',
      href: 'user-point-exchange-record',
      auth: 'CUSTOMER_GIFT_DISTRIBUTION-M-3',
      parent:'active-center'
    },
    {
      html: '/activity-center/activity-center.html',
      css: '/activity-center/activity-center.css',
      js: '/activity-center/activity-center.js',
      title: '活动中心',
      isSingle: true,
      text: '活动中心',
      href: 'activity-center',
      auth: 'CUSTOMER_GIFT_DISTRIBUTION-M-4',
      parent:'active-center'
    },
    {
      html: '/driver-welfare/driver-welfare.html',
      css: '/driver-welfare/driver-welfare.css',
      js: '/driver-welfare/driver-welfare.js',
      title: '司机福利',
      isSingle: true,
      text: '司机福利',
      href: 'driver-welfare',
      auth: 'CUSTOMER_GIFT_DISTRIBUTION-M-5',
      parent:'active-center'
    },
    {
      html: '/scholarship-apply/scholarship-apply.html',
      css: '/scholarship-apply/scholarship-apply.css',
      js: '/scholarship-apply/scholarship-apply.js',
      title: '奖/助学金',
      isSingle: true,
      text: '奖/助学金',
      href: 'scholarship-apply',
      auth: 'CUSTOMER_GIFT_DISTRIBUTION-M-6',
      parent:'active-center'
    },
  // 拼箱业务菜单
    {
      html: '/lcl-system/lcl-system.html',
      css: '/lcl-system/lcl-system.css',
      js: '/lcl-system/lcl-system.js',
      title: '拼箱进仓',
      isSingle: true,
      text: '拼箱进仓',
      href: 'lcl-system',
      auth: 'LCL_SYSTEM-M',
      parent: 'lcl',
    },
  // 商车科技菜单
    {
      html: '/to-vehicle-management/to-vehicle-management.html',
      css: '/to-vehicle-management/to-vehicle-management.css',
      js: '/to-vehicle-management/to-vehicle-management.js',
      title: '车辆销售',
      isSingle: true,
      text: '车辆销售',
      href: 'to-vehicle-sales-management',
      auth: 'VEHICLE_MANAGEMENT-M-2',
      parent: 'business-vehicle-technology',
    },
  //   安全管理菜单
    {
      html: '/to-security-management/to-security-management.html',
      css: '/to-security-management/to-security-management.css',
      js: '/to-security-management/to-security-management.js',
      title: '安全管理(新)',
      isSingle: true,
      text: '安全管理(新)',
      href: 'to-security-management',
      auth: 'SECURITY_INCIDENT-M-0',
      parent:'safety-management'
    },
    {
      html: '/security-incident/security-incident.html',
      css: '/security-incident/security-incident.css',
      js: '/security-incident/security-incident.js',
      title: '安全事故',
      isSingle: true,
      text: '安全事故',
      href: 'security-incident',
      auth: 'SECURITY_INCIDENT-M-1',
      parent:'safety-management'
    },
    {
      html: '/driver-safety-commitment/driver-safety-commitment.html',
      css: '/driver-safety-commitment/driver-safety-commitment.css',
      js: '/driver-safety-commitment/driver-safety-commitment.js',
      title: '司机承诺书',
      isSingle: true,
      text: '司机承诺书',
      auth: 'SECURITY_INCIDENT-M-2',
      href: 'driver-safety-commitment',
      parent:'safety-management'
    },
    {
      html: '/driver-regulations-handle/driver-regulations-handle.html',
      css: '/driver-regulations-handle/driver-regulations-handle.css',
      js: '/driver-regulations-handle/driver-regulations-handle.js',
      title: '违章管理',
      isSingle: true,
      text: '违章管理',
      auth: 'SECURITY_INCIDENT-M-3',
      href: 'driver-regulations-handle',
      parent:'safety-management'
    },
    {
      html: '/driver-offline-training/driver-offline-training.html',
      css: '/driver-offline-training/driver-offline-training.css',
      js: '/driver-offline-training/driver-offline-training.js',
      title: '线下安全培训',
      isSingle: true,
      text: '线下安全培训',
      auth: 'SECURITY_INCIDENT-M-4',
      href: 'driver-offline-training',
      parent:'safety-management'
    },
    {
      html: "/training-plan/training-plan.html",
      css: "/training-plan/training-plan.css",
      js: "/training-plan/training-plan.js",
      title: "线上安全培训",
      isSingle: true,
      text: "线上安全培训",
      auth: "ONLINE_TRAINING",
      href: "training-plan",
      parent: "safety-management"
    },
    {
      html: '/driver-offline-training/driver-offline-training.html',
      css: '/driver-offline-training/driver-offline-training.css',
      js: '/driver-offline-training/driver-offline-training.js',
      title: '车辆监控',
      isSingle: true,
      text: '车辆监控',
      auth: 'SAFETY_MONITOR-M',
      href: 'ds-monitor',
      parent:'safety-management'
    },
    //工厂作业风险
    {
      html: '/factory-operation-risks/factory-operation-risks.html',
      css: '/factory-operation-risks/factory-operation-risks.css',
      js: '/factory-operation-risks/factory-operation-risks.js',
      title: '工厂作业风险',
      isSingle: true,
      text: '工厂作业风险',
      auth: 'FACTORY_OPERATION_RISKS',
      href: 'factory-operation-risks',
    },
  // 增值服务菜单
    {
      html: '/to-shop-client/to-shop-client.html',
      css: '/to-shop-client/to-shop-client.css',
      js: '/to-shop-client/to-shop-client.js',
      title: '商城管理',
      isSingle: true,
      text: '商城管理',
      href: 'to-shop-client',
      auth: 'SHOP_MANAGEMENT-M',
      parent: 'shop-management',
    },
  // 产品价格菜单
    {
      html: '/product-price/product-price.html',
      css: '/product-price/product-price.css',
      js: '/product-price/product-price.js',
      title: '价格中心',
      isSingle: true,
      text: '价格中心',
      href: 'product-price',
      auth:   "PRODUCT_PRICE_MANAGEMENT-M" ,
      parent: 'product-price',
    },
  // 业务伙伴菜单
    {
      html: '/pbp-waybill-management/pbp-waybill-management.html',
      css: '/pbp-waybill-management/pbp-waybill-management.css',
      js: '/pbp-waybill-management/pbp-waybill-management.js',
      title: '运单管理',
      isSingle: true,
      text: '运单管理',
      href: 'pbp-waybill-management',
      auth: 'PBP_WX-M-1',
      parent: 'pbp-wx-web',
    },
    {
      html: '/pbp-pay-management/pbp-pay-management.html',
      css: '/pbp-pay-management/pbp-pay-management.css',
      js: '/pbp-pay-management/pbp-pay-management.js',
      title: '付款管理',
      isSingle: true,
      text: '付款管理',
      href: 'pbp-pay-management',
      auth: 'PBP_WX-M-2',
      parent: 'pbp-wx-web',
    },
    {
      html: '/pbp-dailybill-list/pbp-dailybill-list.html',
      css: '/pbp-dailybill-list/pbp-dailybill-list.css',
      js: '/pbp-dailybill-list/pbp-dailybill-list.js',
      title: '日账单管理',
      isSingle: true,
      text: '日账单管理',
      href: 'pbp-dailybill-list',
      auth: 'PBP_WX-M-3',
      parent: 'pbp-wx-web',
    },
  // 工作台菜单
    {
      html: '/seize-order/seize-order.html',
      css: '/seize-order/seize-order.css',
      js: '/seize-order/seize-order.js',
      title: '待确认做箱',
      isSingle: true,
      text: '待确认做箱',
      href: 'seize-order-containerNotAccepted'
    },
    {
      html: '/seize-order-boxNotAccepted/seize-order-boxNotAccepted.html',
      css: '/seize-order-boxNotAccepted/seize-order-boxNotAccepted.css',
      js: '/seize-order-boxNotAccepted/seize-order-boxNotAccepted.js',
      title: '待确认做箱',
      isSingle: true,
      text: '待确认做箱',
      href: 'seize-order-boxNotAccepted',
      parent: 'customer'
    },
    {
      html: '/wait-set-price/wait-set-price.html',
      css: '/wait-set-price/wait-set-price.css',
      js: '/wait-set-price/wait-set-price.js',
      title: '待定价',
      isSingle: true,
      text: '待定价',
      href: 'wait-set-price'
    },
    {
      html: '/wait-task-match/wait-task-match.html',
      css: '/wait-task-match/wait-task-match.css',
      js: '/wait-task-match/wait-task-match.js',
      title: '待搭短驳',
      isSingle: true,
      text: '待搭短驳',
      href: 'wait-task-match'
    },
    {
      html: '/wait-set-price-special/wait-set-price-special.html',
      css: '/wait-set-price-special/wait-set-price-special.css',
      js: '/wait-set-price-special/wait-set-price-special.js',
      title: '待定价(专线)',
      isSingle: true,
      text: '待定价(专线)',
      href: 'wait-set-price-special'
    },
    {
      html: '/short-barge-dispatch/short-barge-dispatch.html',
      css: '/short-barge-dispatch/short-barge-dispatch.css',
      js: '/short-barge-dispatch/short-barge-dispatch.js',
      title: '短驳调度',
      isSingle: true,
      text: '短驳调度',
      href: 'short-barge-dispatch'
    },
    {
      html: '/short-barge-match/short-barge-match.html',
      css: '/short-barge-match/short-barge-match.css',
      js: '/short-barge-match/short-barge-match.js',
      title: '搭配待派车',
      isSingle: true,
      text: '搭配待派车',
      href: 'short-barge-match'
    },
    {
      html: '/short-barge-dispatched/short-barge-dispatched.html',
      css: '/short-barge-dispatched/short-barge-dispatched.css',
      js: '/short-barge-dispatched/short-barge-dispatched.js',
      title: '短驳待预约',
      isSingle: true,
      text: '短驳待预约',
      href: 'short-barge-dispatched'
    },
    {
      html: '/short-barge-redispatched/short-barge-redispatched.html',
      css: '/short-barge-redispatched/short-barge-redispatched.css',
      js: '/short-barge-redispatched/short-barge-redispatched.js',
      title: '短驳已派车',
      isSingle: true,
      text: '短驳已派车',
      href: 'short-barge-redispatched'
    },
    {
      html: '/container-prerecord-list/container-prerecord-list.html',
      css: '/container-prerecord-list/container-prerecord-list.css',
      js: '/container-prerecord-list/container-prerecord-list.js',
      title: '箱货数据异常',
      isSingle: true,
      text: '箱货数据异常',
      href: 'container-prerecord-list'
    },
    {
      html: '/return-business/return-business.html',
      css: '/return-business/return-business.css',
      js: '/return-business/return-business.js',
      title: '补派业务',
      isSingle: true,
      text: '补派业务',
      href: 'return-business'
    },
    {
      html: '/apply-refund/apply-refund.html',
      css: '/apply-refund/apply-refund.css',
      js: '/apply-refund/apply-refund.js',
      title: '申请退单',
      isSingle: true,
      text: '申请退单',
      href: 'apply-refund'
    },
    {
      html: '/wait-verify-price/wait-verify-price.html',
      css: '/wait-verify-price/wait-verify-price.css',
      js: '/wait-verify-price/wait-verify-price.js',
      title: '待审核定价',
      isSingle: true,
      text: '待审核定价', //审核成功  打回
      href: 'wait-verify-price'
      // auth: 'CONTAINER_WAIT_AUDIT',
      // parent: 'transportation-manager',
      // seq: 3
    },
    {
      html: '/wait-dispatch/wait-dispatch.html',
      css: '/wait-dispatch/wait-dispatch.css',
      js: '/wait-dispatch/wait-dispatch.js',
      title: '待调度',
      isSingle: true,
      text: '待调度',
      href: 'wait-dispatch'
    },
    {
      html: '/wait-grab-order/wait-grab-order.html',
      css: '/wait-grab-order/wait-grab-order.css',
      js: '/wait-grab-order/wait-grab-order.js',
      title: '待抢单',
      isSingle: true,
      text: '待抢单',
      href: 'wait-grab-order'
    },
    {
      html: '/container-confirm-order/container-confirm-order.html',
      css: '/container-confirm-order/container-confirm-order.css',
      js: '/container-confirm-order/container-confirm-order.js',
      title: '待调度确认派单',
      isSingle: true,
      text: '待调度确认派单',
      href: 'container-confirm-order'
    },
    {
      html: '/container-depot/container-depot.html',
      css: '/container-depot/container-depot.css',
      js: '/container-depot/container-depot.js',
      title: '待指定堆场',
      isSingle: true,
      text: '待指定堆场',
      href: 'container-depot'
    },
    {
      html: '/wait-appoint-mailing/wait-appoint-mailing.html',
      css: '/wait-appoint-mailing/wait-appoint-mailing.css',
      js: '/wait-appoint-mailing/wait-appoint-mailing.js',
      title: '待指定寄单点',
      isSingle: true,
      text: '待指定寄单点',
      href: 'wait-appoint-mailing'
    },
    {
      html: '/container-dispatched/container-dispatched.html',
      css: '/container-dispatched/container-dispatched.css',
      js: '/container-dispatched/container-dispatched.js',
      title: '已派车',
      isSingle: true,
      text: '已派车',
      href: 'container-dispatched'
    },
    {
      html: '/abnormal-expenses-liability/abnormal-expenses-liability.html',
      css: '/abnormal-expenses-liability/abnormal-expenses-liability.css',
      js: '/abnormal-expenses-liability/abnormal-expenses-liability.js',
      title: '异常费用定责',
      isSingle: true,
      text: '异常费用定责',
      href: 'abnormal-expenses-liability',
      auth: 'ABNORMAL-EXPENSES-LIABILITY'
    },
    {
      html: '/container-situation-report/container-situation-report.html',
      css: '/container-situation-report/container-situation-report.css',
      js: '/container-situation-report/container-situation-report.js',
      title: '做箱情况',
      isSingle: true,
      text: '做箱情况',
      href: 'container-situation-report'
    },
    {
      html: '/status-tracking/status-tracking.html',
      css: '/status-tracking/status-tracking.css',
      js: '/status-tracking/status-tracking.js',
      title: '提单动态查询',
      isSingle: true,
      text: '提单动态查询',
      href: 'status-tracking'
    },
    {
      html: '/car-position/car-position.html',
      css: '/car-position/car-position.css',
      js: '/car-position/car-position.js',
      title: '车辆实时位置',
      isSingle: true,
      text: '车辆实时位置',
      href: 'car-position',
      noLoginAllowed: true
    },
    {
      html: '/order-details/order-details.html',
      css: '/order-details/order-details.css',
      js: '/order-details/order-details.js',
      title: '订单详情',
      isSingle: false,
      text: '订单详情',
      href: 'order-details'
    },
    {
      html: '/details-new/details-new.html',
      css: '/details-new/details-new.css',
      js: '/details-new/details-new.js',
      title: '订单详情(新)',
      isSingle: true,
      text: '订单详情(新)',
      href: 'details-new'
    },
    {
      css: '/contract-review/contract-review.css',
      html: '/contract-review/contract-review.html',
      js: '/contract-review/contract-review.js',
      isSingle: true,
      title: '合同审核',
      text: '合同审核',
      // parent: 'customer',
      auth: 'CUSTOMER_CONTRACT_AUDIT',
      href: 'contract-review'
    },
    {
      css: '/receivable-bill-details/receivable-bill-details.css',
      html: '/receivable-bill-details/receivable-bill-details.html',
      js: '/receivable-bill-details/receivable-bill-details.js',
      isSingle: true,
      title: '应收账单详情',
      text: '应收账单详情',
      href: 'receivable-bill-details'
    },
    {
      html: '/track-factory-plan/track-factory-plan.html',
      css: '/track-factory-plan/track-factory-plan.css',
      js: '/track-factory-plan/track-factory-plan.js',
      title: '确认工厂计划',
      isSingle: true,
      text: '确认工厂计划',
      href: 'track-factory-plan'
    }, 
    {
      html: '/transporter-feedback/transporter-feedback.html',
      css: '/transporter-feedback/transporter-feedback.css',
      js: '/transporter-feedback/transporter-feedback.js',
      title: '运管反馈',
      isSingle: true,
      text: '运管反馈',
      href: 'transporter-feedback'
    },
    {
      html: '/customer-manage/customer-manage.html',
      css: '/customer-manage/customer-manage.css',
      js: '/customer-manage/customer-manage.js',
      title: '客户档案管理',
      isSingle: true,
      text: '客户档案管理',
      href: 'customer-manage'
    },
    {
      html: '/customer-business-evaluation/customer-business-evaluation.html',
      css: '/customer-business-evaluation/customer-business-evaluation.css',
      js: '/customer-business-evaluation/customer-business-evaluation.js',
      title: '客户业务评价',
      isSingle: true,
      text: '客户业务评价',
      href: 'customer-business-evaluation',
    },
    {
      html: '/driver-bill-details/driver-bill-details.html',
      css: '/driver-bill-details/driver-bill-details.css',
      js: '/driver-bill-details/driver-bill-details.js',
      title: '车老板账单详情',
      isSingle: true,
      text: '车老板账单详情',
      href: 'driver-bill-details'
    },
    {
      html: '/driver-bill-other-details/driver-bill-other-details.html',
      css: '/driver-bill-other-details/driver-bill-other-details.css',
      js: '/driver-bill-other-details/driver-bill-other-details.js',
      title: '外发账单详情',
      isSingle: true,
      text: '外发账单详情',
      href: 'driver-bill-other-details'
    },
    {
      html: '/supplier-bill-details/supplier-bill-details.html',
      css: '/supplier-bill-details/supplier-bill-details.css',
      js: '/supplier-bill-details/supplier-bill-details.js',
      title: '供应商账单详情',
      isSingle: true,
      text: '供应商账单详情',
      href: 'supplier-bill-details'
    },
    {
      html: '/edit-container/edit-container.html',
      css: '/edit-container/edit-container.css',
      js: '/edit-container/edit-container.js',
      title: '编辑订单',
      isSingle: true,
      text: '编辑订单',
      href: 'edit-container'
    },
    {
      css: '/sms-management/sms-management.css',
      html: '/sms-management/sms-management.html',
      js: '/sms-management/sms-management.js',
      isSingle: true,
      title: '短信运营',
      text: '短信运营',
      href: 'sms-management'
    },
    {
      css: '/declare-statistics/declare-statistics.css',
      html: '/declare-statistics/declare-statistics.html',
      js: '/declare-statistics/declare-statistics.js',
      isSingle: true,
      // parent: 'document-processing',
      title: '报关统计',
      text: '报关统计',
      // auth: 'NEW_INPUT_CONTAINER_NUMBER',
      href: 'declare-statistics'
    },
    {
      html: '/driver-bill-other-management/driver-bill-other-management.html',
      css: '/driver-bill-other-management/driver-bill-other-management.css',
      js: '/driver-bill-other-management/driver-bill-other-management.js',
      title: '外发账单管理',
      isSingle: true,
      text: '外发账单管理',
      href: 'driver-bill-other-management',
      // auth: 'WF_DRIVER_BILLING_REPORT_MGT',
      // parent: 'driver'
    },
    {
      html: '/drop-box-yard/drop-box-yard.html',
      css: '/drop-box-yard/drop-box-yard.css',
      js: '/drop-box-yard/drop-box-yard.js',
      title: '指定落箱堆场',
      isSingle: true,
      text: '指定落箱堆场',
      href: 'drop-box-yard',
      // auth: 'YZS_DOOR_COST',
      // parent: 'transportation-manager'
    },
    {
      html: '/export-ticket-bill/export-ticket-bill.html',
      css: '/export-ticket-bill/export-ticket-bill.css',
      js: '/export-ticket-bill/export-ticket-bill.js',
      title: '按票导出账单',
      isSingle: true,
      text: '按票导出账单',
      href: 'export-ticket-bill'
    },
    {
      html: '/confirm-packing/confirm-packing.html',
      css: '/confirm-packing/confirm-packing.css',
      js: '/confirm-packing/confirm-packing.js',
      title: '待确认打单',
      isSingle: true,
      text: '待确认打单',
      href: 'confirm-packing'
    },
    {
      html: '/wait-arrange-harbour/wait-arrange-harbour.html',
      css: '/wait-arrange-harbour/wait-arrange-harbour.css',
      js: '/wait-arrange-harbour/wait-arrange-harbour.js',
      title: '待安排进港',
      isSingle: true,
      text: '待安排进港',
      href: 'wait-arrange-harbour'
    },
    {
      html: '/drop-container-set-price/drop-container-set-price.html',
      css: '/drop-container-set-price/drop-container-set-price.css',
      js: '/drop-container-set-price/drop-container-set-price.js',
      title: '待定价',
      isSingle: true,
      text: '待定价',
      href: 'drop-container-set-price'
    },
    {
      html: '/drop-container-verify-price/drop-container-verify-price.html',
      css: '/drop-container-verify-price/drop-container-verify-price.css',
      js: '/drop-container-verify-price/drop-container-verify-price.js',
      title: '待审核定价',
      isSingle: true,
      text: '待审核定价',
      href: 'drop-container-verify-price'
    },
    {
      html: '/drop-container-dispatch/drop-container-dispatch.html',
      css: '/drop-container-dispatch/drop-container-dispatch.css',
      js: '/drop-container-dispatch/drop-container-dispatch.js',
      title: '待调度',
      isSingle: true,
      text: '待调度',
      href: 'drop-container-dispatch'
    },  
    {
      html: '/driver-point/driver-point.html',
      css: '/driver-point/driver-point.css',
      js: '/driver-point/driver-point.js',
      title: '司机积分',
      isSingle: true,
      text: '司机积分',
      href: 'driver-point',
    },
    {
      html: '/driver-amount/driver-amount.html',
      css: '/driver-amount/driver-amount.css',
      js: '/driver-amount/driver-amount.js',
      title: '司机余额',
      isSingle: true,
      text: '司机余额',
      href: 'driver-amount',
    },
    {
      html: '/driver-amount-flow/driver-amount-flow.html',
      css: '/driver-amount-flow/driver-amount-flow.css',
      js: '/driver-amount-flow/driver-amount-flow.js',
      title: '司机余额流水',
      isSingle: true,
      text: '司机余额流水',
      href: 'driver-amount-flow',
    },
    {
      html: '/expenses-audit-failure/expenses-audit-failure.html',
      css: '/expenses-audit-failure/expenses-audit-failure.css',
      js: '/expenses-audit-failure/expenses-audit-failure.js',
      title: '费用审核失败',
      isSingle: true,
      text: '费用审核失败',
      href: 'expenses-audit-failure',
      auth: '',
      parent:''
    },
    {
      html: '/overdue-records/overdue-records.html',
      css: '/overdue-records/overdue-records.css',
      js: '/overdue-records/overdue-records.js',
      title: '逾期账款',
      isSingle: true,
      text: '逾期账款',
      href: 'overdue-records',
      auth: '',
      parent:''
    },
    {
      html: '/reserve-get-release/reserve-get-release.html',
      css: '/reserve-get-release/reserve-get-release.css',
      js: '/reserve-get-release/reserve-get-release.js',
      title: '预订预提放单',
      isSingle: true,
      text: '预订预提放单',
      href: 'reserve-get-release',
      // auth: 'CUSTOMER_COMMUNICATE',
      // parent: 'customer-order'
    },
    {
      html: '/reserve-get-await/reserve-get-await.html',
      css: '/reserve-get-await/reserve-get-await.css',
      js: '/reserve-get-await/reserve-get-await.js',
      title: '预订预提',
      isSingle: true,
      text: '预订预提',
      href: 'reserve-get-await',
      // auth: 'CUSTOMER_COMMUNICATE',
      // parent: 'customer-order'
    },
    {
      html: '/reporting-cost-audit/reporting-cost-audit.html',
      css: '/reporting-cost-audit/reporting-cost-audit.css',
      js: '/reporting-cost-audit/reporting-cost-audit.js',
      title: '上报费用审核',
      isSingle: true,
      text: '上报费用审核',
      href: 'reporting-cost-audit'
    },
    {
      html: '/drop-container-grab-order/drop-container-grab-order.html',
      css: '/drop-container-grab-order/drop-container-grab-order.css',
      js: '/drop-container-grab-order/drop-container-grab-order.js',
      title: '待抢单',
      isSingle: true,
      text: '待抢单',
      href: 'drop-container-grab-order'
    },
    {
      html: '/drop-container-confirm-order/drop-container-confirm-order.html',
      css: '/drop-container-confirm-order/drop-container-confirm-order.css',
      js: '/drop-container-confirm-order/drop-container-confirm-order.js',
      title: '待确认司机抢单',
      isSingle: true,
      text: '待确认司机抢单',
      href: 'drop-container-confirm-order'
    },
    {
      html: '/capacity-generalize-detail/capacity-generalize-detail.html',
      css: '/capacity-generalize-detail/capacity-generalize-detail.css',
      js: '/capacity-generalize-detail/capacity-generalize-detail.js',
      title: '电话推广任务详情',
      isSingle: true,
      text: '电话推广任务详情',
      href: 'capacity-generalize-detail',
    },
    {
      html: '/free-vip/free-vip.html',
      css: '/free-vip/free-vip.css',
      js: '/free-vip/free-vip.js',
      title: '空闲vip',
      isSingle: true,
      text: '空闲vip',
      href: 'free-vip',
    },
    {
      html: '/adjustable-driver/adjustable-driver.html',
      css: '/adjustable-driver/adjustable-driver.css',
      js: '/adjustable-driver/adjustable-driver.js',
      title: '可调整司机',
      isSingle: true,
      text: '可调整司机',
      href: 'adjustable-driver',
    },
    {
      html: '/internal-coordination/internal-coordination.html',
      css: '/internal-coordination/internal-coordination.css',
      js: '/internal-coordination/internal-coordination.js',
      title: '内部协同',
      isSingle: true,
      text: '内部协同',
      href: 'internal-coordination',
    },
    {
      html: '/dispatch-manage/dispatch-manage.html',
      css: '/dispatch-manage/dispatch-manage.css',
      js: '/dispatch-manage/dispatch-manage.js',
      title: '集中调度',
      isSingle: true,
      text: '集中调度',
      href: 'dispatch-manage',
    },
    {
      html: '/maintain-transit-port/maintain-transit-port.html',
      css: '/maintain-transit-port/maintain-transit-port.css',
      js: '/maintain-transit-port/maintain-transit-port.js',
      title: '维护中转港',
      isSingle: true,
      text: '维护中转港',
      href: 'maintain-transit-port',
    },
    {
      html: '/receipt-recovery/receipt-recovery.html',
      css: '/receipt-recovery/receipt-recovery.css',
      js: '/receipt-recovery/receipt-recovery.js',
      title: '待确认回单收齐',
      isSingle: true,
      text: '待确认回单收齐',
      auth: 'NEW_INPUT_CONTAINER_NUMBER',
      //parent:'document-processing',
      href: 'receipt-recovery'
    },
    {
      html: '/pickup-late/pickup-late.html',
      css: '/pickup-late/pickup-late.css',
      js: '/pickup-late/pickup-late.js',
      title: '提箱迟到',
      isSingle: true,
      text: '提箱迟到',
      // auth: 'NEW_INPUT_CONTAINER_NUMBER',
      // parent:'document-processing',
      href: 'pickup-late'
    },
    {
      html: '/planArrival-late/planArrival-late.html',
      css: '/planArrival-late/planArrival-late.css',
      js: '/planArrival-late/planArrival-late.js',
      title: '到厂迟到',
      isSingle: true,
      text: '到厂迟到',
      // auth: 'NEW_INPUT_CONTAINER_NUMBER',
      // parent:'document-processing',
      href: 'planArrival-late'
    },
    {
      html: '/install-container-abnormal/install-container-abnormal.html',
      css: '/install-container-abnormal/install-container-abnormal.css',
      js: '/install-container-abnormal/install-container-abnormal.js',
      title: '装箱异常',
      isSingle: true,
      text: '装箱异常',
      // auth: 'NEW_INPUT_CONTAINER_NUMBER',
      // parent:'document-processing',
      href: 'install-container-abnormal'
    },
    {
      html: '/customer-shut/customer-shut.html',
      css: '/customer-shut/customer-shut.css',
      js: '/customer-shut/customer-shut.js',
      title: '门点退关',
      isSingle: true,
      text: '门点退关',
      href: 'customer-shut'
    },
    {
      html: '/wait-order-receiving-newOrder/wait-order-receiving-newOrder.html',
      css: '/wait-order-receiving-newOrder/wait-order-receiving-newOrder.css',
      js: '/wait-order-receiving-newOrder/wait-order-receiving-newOrder.js',
      title: '待接单(在线订单)',
      isSingle: true,
      text: '待接单(在线订单)',
      href: 'wait-order-receiving-newOrder'
    },
    {
      html: '/customer-abnormal-tracking/customer-abnormal-tracking.html',
      css: '/customer-abnormal-tracking/customer-abnormal-tracking.css',
      js: '/customer-abnormal-tracking/customer-abnormal-tracking.js',
      title: '异常跟踪(客服)',
      isSingle: true,
      text: '异常跟踪(客服)',
      href: 'customer-abnormal-tracking',
      auth: 'CUSTOMER_ABNORMAL_TRACKING',
    },
    {
      html: '/transport-abnormal-tracking/transport-abnormal-tracking.html',
      css: '/transport-abnormal-tracking/transport-abnormal-tracking.css',
      js: '/transport-abnormal-tracking/transport-abnormal-tracking.js',
      title: '截单告警',
      isSingle: true,
      text: '截单告警',
      href: 'transport-abnormal-tracking',
      auth: 'TRANSPORT_ABNORMAL_TRACKING',
    },
    {
      html: '/customer-change-detail/customer-change-detail.html',
      css: '/customer-change-detail/customer-change-detail.css',
      js: '/customer-change-detail/customer-change-detail.js',
      title: '沟通记录',
      isSingle: false,
      text: '沟通记录',
      href: 'customer-change-detail',
      auth: 'CUSTOMER_CHARGE_DETAIL'
    },
    {
      css: '/lost-customers-analysis/lost-customers-analysis.css',
      html: '/lost-customers-analysis/lost-customers-analysis.html',
      js: '/lost-customers-analysis/lost-customers-analysis.js',
      isSingle: true,
      title: '流失客户分析',
      text: '流失客户分析',
      auth: 'LOSING_CUSTOMERS',
      href: 'lost-customers-analysis'
    },
    {
      css: '/unscheduled-imported/unscheduled-imported.css',
      html: '/unscheduled-imported/unscheduled-imported.html',
      js: '/unscheduled-imported/unscheduled-imported.js',
      isSingle: true,
      title: '进口未排计划',
      text: '进口未排计划',
      auth: 'unscheduled-imported',
      href: 'unscheduled-imported'
    },
    {
      html: '/advance-port-count/advance-port-count.html',
      css: '/advance-port-count/advance-port-count.css',
      js: '/advance-port-count/advance-port-count.js',
      title: '预进港状态统计',
      isSingle: true,
      text: '预进港状态统计',
      href: 'advance-port-count',
      auth: 'ADVANCE_PORT_COUNT'
    },
    {
      html: '/serialnum-is-ningbo/serialnum-is-ningbo.html',
      css: '/serialnum-is-ningbo/serialnum-is-ningbo.css',
      js: '/serialnum-is-ningbo/serialnum-is-ningbo.js',
      title: '宁波无纸化',
      isSingle: true,
      text: '宁波无纸化',
      href: 'serialnum-is-ningbo',
      auth: 'SERIALNUM-IS-NINGBO',
      // parent: 'system-manager'
    },
    {
      html: '/strategy-setting-detail/strategy-setting-detail.html',
      css: '/strategy-setting-detail/strategy-setting-detail.css',
      js: '/strategy-setting-detail/strategy-setting-detail.js',
      title: '旺季详情配置',
      isSingle: true,
      text: '旺季详情配置',
      href: 'strategy-setting-detail',
    },
    {
      html: '/season-strategy-customerLists/season-strategy-customerLists.html',
      css: '/season-strategy-customerLists/season-strategy-customerLists.css',
      js: '/season-strategy-customerLists/season-strategy-customerLists.js',
      title: '旺季策略-客户名单',
      isSingle: true,
      text: '旺季策略-客户名单',
      href: 'season-strategy-customerLists',
    },
    {
      html: '/fee-reduction-review/fee-reduction-review.html',
      css: '/fee-reduction-review/fee-reduction-review.css',
      js: '/fee-reduction-review/fee-reduction-review.js',
      title: '费用减免审核',
      isSingle: true,
      text: '费用减免审核',
      href: 'fee-reduction-review',
      auth: '',
      parent:''
    },
    {
      html: '/customer-fee-reject-audit/customer-fee-reject-audit.html',
      css: '/customer-fee-reject-audit/customer-fee-reject-audit.css',
      js: '/customer-fee-reject-audit/customer-fee-reject-audit.js',
      title: '客户驳回费用确认',
      isSingle: true,
      text: '客户驳回费用确认',
      href: 'customer-fee-reject-audit',
      auth: '',
      parent:''
    },
    {
      html: '/factory-overloading/factory-overloading.html',
      css: '/factory-overloading/factory-overloading.css',
      js: '/factory-overloading/factory-overloading.js',
      title: '工厂超额装卸',
      isSingle: true,
      text: '工厂超额装卸',
      href: 'factory-overloading',
      auth: '',
      parent:''
    },
    {
      html: '/urgegoodsinfo/urgegoodsinfo.html',
      css: '/urgegoodsinfo/urgegoodsinfo.css',
      js: '/urgegoodsinfo/urgegoodsinfo.js',
      title: '箱货数据补充',
      isSingle: true,
      text: '箱货数据补充',
      href: 'urgegoodsinfo',
      auth: '',
      parent:''
    },
    {
      html: '/coustomer-door-container-check/coustomer-door-container-check.html',
      css: '/coustomer-door-container-check/coustomer-door-container-check.css',
      js: '/coustomer-door-container-check/coustomer-door-container-check.js',
      title: '做箱门点审核',
      isSingle: true,
      text: '做箱门点审核',
      href: 'coustomer-door-container-check',
      auth: '',
      parent:''
    },
    {
      html: '/factory-information-collection/factory-information-collection.html',
      css: '/factory-information-collection/factory-information-collection.css',
      js: '/factory-information-collection/factory-information-collection.js',
      title: '新工厂信息采集(工厂)',
      isSingle: true,
      text: '新工厂信息采集(工厂)',
      href: 'factory-information-collection',
      auth: '',
      parent:''
    },
    {
      html: '/factory-information-collection-driver/factory-information-collection-driver.html',
      css: '/factory-information-collection-driver/factory-information-collection-driver.css',
      js: '/factory-information-collection-driver/factory-information-collection-driver.js',
      title: '新工厂信息采集(司机)',
      isSingle: true,
      text: '新工厂信息采集(司机)',
      href: 'factory-information-collection-driver',
      auth: '',
      parent:''
    },
    {
      html: '/factory-information-collection-audit/factory-information-collection-audit.html',
      css: '/factory-information-collection-audit/factory-information-collection-audit.css',
      js: '/factory-information-collection-audit/factory-information-collection-audit.js',
      title: '新工厂信息审核',
      isSingle: true,
      text: '新工厂信息审核',
      href: 'factory-information-collection-audit',
      auth: '',
      parent:''
    },
    {
      html: '/abnormal-expenses/abnormal-expenses.html',
      css: '/abnormal-expenses/abnormal-expenses.css',
      js: '/abnormal-expenses/abnormal-expenses.js',
      title: '异常费用',
      isSingle: true,
      text: '异常费用',
      href: 'abnormal-expenses',
      auth: '',
      parent:''
    },
    /*禁区通行证办理*/
    {
      html: '/forbidden-permit/forbidden-permit.html',
      css: '/forbidden-permit/forbidden-permit.css',
      js: '/forbidden-permit/forbidden-permit.js',
      title: '通行证办理',
      isSingle: true,
      text: '通行证办理',
      href: 'forbidden-permit'
    },
    {
      html: '/bd-user-management/bd-user-management.html',
      css: '/bd-user-management/bd-user-management.css',
      js: '/bd-user-management/bd-user-management.js',
      title: '达客必优用户管理',
      isSingle: true,
      text: '达客必优用户管理',
      href: 'bd-user-management',
      auth: 'BD-USER-MANAGEMENT',
    },
    {
      html: '/drop-container-gate-list/drop-container-gate-list.html',
      css: '/drop-container-gate-list/drop-container-gate-list.css',
      js: '/drop-container-gate-list/drop-container-gate-list.js',
      title: '进港清单',
      isSingle: true,
      text: '进港清单',
      href: 'drop-container-gate-list',
      auth: 'DROP-CONTAINER-GATE-LIST',
    },
    {
      html: '/drayage-task-trace/drayage-task-trace.html',
      css: '/drayage-task-trace/drayage-task-trace.css',
      js: '/drayage-task-trace/drayage-task-trace.js',
      title: '短驳任务跟踪',
      isSingle: true,
      text: '短驳任务跟踪',
      href: 'drayage-task-trace',
      auth: 'DRAYAGE-TASK-TRACE',
    },
    {
      html: '/pbp-dailybill-detail/pbp-dailybill-detail.html',
      css: '/pbp-dailybill-detail/pbp-dailybill-detail.css',
      js: '/pbp-dailybill-detail/pbp-dailybill-detail.js',
      title: '日账单详情',
      isSingle: true,
      text: '日账单详情',
      href: 'pbp-dailybill-detail',
    },
    {
      html: '/wait-arrange-harbour-shortBarge/wait-arrange-harbour-shortBarge.html',
      css: '/wait-arrange-harbour-shortBarge/wait-arrange-harbour-shortBarge.css',
      js: '/wait-arrange-harbour-shortBarge/wait-arrange-harbour-shortBarge.js',
      title: '短驳待安排还箱',
      isSingle: true,
      text: '短驳待安排还箱',
      href: 'wait-arrange-harbour-shortBarge'
    },
    {
        html: '/no-attendance-visit/no-attendance-visit.html',
        css: '/no-attendance-visit/no-attendance-visit.css',
        js: '/no-attendance-visit/no-attendance-visit.js',
        title: '未出勤回访',
        isSingle: true,
        text: '未出勤回访',
        href: 'no-attendance-visit'
    },
    {
        html: '/no-send-cars/no-send-cars.html',
        css: '/no-send-cars/no-send-cars.css',
        js: '/no-send-cars/no-send-cars.js',
        title: '补产值',
        isSingle: true,
        text: '补产值',
        href: 'no-send-cars'
    },
    {
      html: '/container-confirm-order-driver/container-confirm-order-driver.html',
      css: '/container-confirm-order-driver/container-confirm-order-driver.css',
      js: '/container-confirm-order-driver/container-confirm-order-driver.js',
      title: '待司机确认接单',
      isSingle: true,
      text: '待司机确认接单',
      href: 'container-confirm-order-driver'
    },
    {
      html: '/prerecord-remould/prerecord-remould.html',
      css: '/prerecord-remould/prerecord-remould.css',
      js: '/prerecord-remould/prerecord-remould.js',
      title: '预录管理',
      isSingle: true,
      text: '预录管理',
      href: 'prerecord-remould',
      auth: 'PRERECORD-REMOULD',
      parent: 'document-processing'
    },

    {
        html: '/short-barge/short-barge.html',
        css: '/short-barge/short-barge.css',
        js: '/short-barge/short-barge.js',
        title: '驳箱下单',
        isSingle: true,
        text: '驳箱下单',
        href: 'short-barge',
    },

    {
      html: '/tally-sheet-task/tally-sheet-task.html',
      css: '/tally-sheet-task/tally-sheet-task.css',
      js: '/tally-sheet-task/tally-sheet-task.js',
      title: '理单任务',
      isSingle: true,
      text: '理单任务',
      href: 'tally-sheet-task',
      auth: 'TALLY-SHEET-TASK',
      parent: 'document-processing'
    },
    {
      html: '/eir-manage/eir-manage.html',
      css: '/eir-manage/eir-manage.css',
      js: '/eir-manage/eir-manage.js',
      title: '设备单管理',
      isSingle: true,
      text: '设备单管理',
      href: 'eir-manage',
      auth: 'EIR-MANAGE',
      parent: 'document-processing'
    },
    {
      html: '/release-container-task/release-container-task.html',
      css: '/release-container-task/release-container-task.css',
      js: '/release-container-task/release-container-task.js',
      title: '放箱任务',
      isSingle: true,
      text: '放箱任务',
      href: 'release-container-task',
      auth: 'RELEASE-CONTAINER-TASK',
      parent: 'document-processing'
    },
    {
      html: '/release-container-order/release-container-order.html',
      css: '/release-container-order/release-container-order.css',
      js: '/release-container-order/release-container-order.js',
      title: '放箱订单',
      isSingle: true,
      text: '放箱订单',
      href: 'release-container-order',
      auth: 'RELEASE-CONTAINER-ORDER',
      parent: 'document-processing'
    },
    {
      html: '/release-container-config/release-container-config.html',
      css: '/release-container-config/release-container-config.css',
      js: '/release-container-config/release-container-config.js',
      title: '放箱规则配置',
      isSingle: true,
      text: '放箱规则配置',
      href: 'release-container-config',
      auth: 'RELEASE-CONTAINER-CONFIG',
      parent: 'document-processing'
    },
    {
      html: '/look-after-driver/look-after-driver.html',
      css: '/look-after-driver/look-after-driver.css',
      js: '/look-after-driver/look-after-driver.js',
      title: '关照司机',
      isSingle: true,
      text: '关照司机',
      href: 'look-after-driver'
    },
    {
      html: '/look-after-driver-admin/look-after-driver-admin.html',
      css: '/look-after-driver-admin/look-after-driver-admin.css',
      js: '/look-after-driver-admin/look-after-driver-admin.js',
      title: '关照司机档案',
      isSingle: true,
      text: '关照司机档案',
      href: 'look-after-driver-admin',
      auth: 'LOOK_AFTER_DRIVER_ADMIN',
      parent: 'transportation-manager',
    },{
      html: '/customer-prosecution/customer-prosecution.html',
      css: '/customer-prosecution/customer-prosecution.css',
      js: '/customer-prosecution/customer-prosecution.js',
      title: '客户起诉',
      isSingle: true,
      text: '客户起诉',
      href: 'customer-prosecution',
      auth: 'CUSTOMER-PROSECUTION',
      parent: 'business'
    },
    {
      html: '/batch-production-order/batch-production-order.html',
      css: '/batch-production-order/batch-production-order.css',
      js: '/batch-production-order/batch-production-order.js',
      title: '批量订单操作',
      isSingle: true,
      text: '批量订单操作',
      href: 'batch-production-order',
      // auth: 'ORDER-SEARCH-LIST-M',
      // parent: 'customer-order'
    },
    {
      html: '/edit-customer-contract-price/edit-customer-contract-price.html',
      css: '/edit-customer-contract-price/edit-customer-contract-price.css',
      js: '/edit-customer-contract-price/edit-customer-contract-price.js',
      title: '编辑合同价',
      isSingle: true,
      text: '编辑合同价',
      href: 'edit-customer-contract-price',
      // auth: 'CUSTOMER-CONTRACT-PRICE',
      // parent: 'customer-contract-price'
    },
    {
      html: '/newAdd-customer-contract-price/newAdd-customer-contract-price.html',
      css: '/newAdd-customer-contract-price/newAdd-customer-contract-price.css',
      js: '/newAdd-customer-contract-price/newAdd-customer-contract-price.js',
      title: '新增合同价',
      isSingle: true,
      text: '新增合同价',
      href: 'newAdd-customer-contract-price',
      // auth: 'CUSTOMER-CONTRACT-PRICE',
      // parent: 'customer-contract-price'
    },
    {
      html: '/sign-contract/sign-contract.html',
      css: '/sign-contract/sign-contract.css',
      js: '/sign-contract/sign-contract.js',
      title: '合同首签',
      isSingle: true,
      text: '合同首签',
      href: 'sign-contract',
      auth: 'CONTRACT_TASK_NEW',
      // parent: 'transportation-manager'
    },
    {
      html: '/renewal-contract/renewal-contract.html',
      css: '/renewal-contract/renewal-contract.css',
      js: '/renewal-contract/renewal-contract.js',
      title: '合同续签',
      isSingle: true,
      text: '合同续签',
      href: 'renewal-contract',
      auth: 'CONTRACT_TASK_RENEW',
      // parent: 'transportation-manager'
    },
    //客户档案管理(旧) 线上保留(货重区间验证用)!
    {
      html: '/customer-manage-sub/customer-manage-sub.html',
      css: '/customer-manage-sub/customer-manage-sub.css',
      js: '/customer-manage-sub/customer-manage-sub.js',
      title: '客户档案管理(旧)',
      isSingle: true,
      text: '客户档案管理(旧)',
      href: 'customer-manage-sub'
    },
    {
      html: '/contract-exemption/contract-exemption.html',
      css: '/contract-exemption/contract-exemption.css',
      js: '/contract-exemption/contract-exemption.js',
      title: '合同缓签待审批',
      isSingle: true,
      text: '合同缓签待审批',
      href: 'contract-exemption',
      auth: 'AUDIT_CONTRACT_DELAY_SIGN',
      // parent: 'transportation-manager'
    },
    {
      html: '/contract-apply-management/contract-apply-management.html',
      css: '/contract-apply-management/contract-apply-management.css',
      js: '/contract-apply-management/contract-apply-management.js',
      title: '合同申请管理',
      isSingle: true,
      text: '合同申请管理',
      href: 'contract-apply-management',
      auth: 'CONTRACT_APPLY_MANAGEMENT',
      parent: 'business',
    },
    {
      html: '/contract-apply-management-recover/contract-apply-management-recover.html',
      css: '/contract-apply-management-recover/contract-apply-management-recover.css',
      js: '/contract-apply-management-recover/contract-apply-management-recover.js',
      title: '合同待收回',
      isSingle: true,
      text: '合同待收回',
      href: 'contract-apply-management-recover',
      auth: 'CONTRACT_APPLY_MANAGEMENT',
    },
    {
      html: '/contract-apply-exempt-management/contract-apply-exempt-management.html',
      js: '/contract-apply-exempt-management/contract-apply-exempt-management.js',
      title: '合同缓签待审批',
      isSingle: true,
      text: '合同缓签待审批',
      href: 'contract-apply-exempt-management',
      auth: 'CONTRACT_APPLY_MANAGEMENT',
    },
    {
      html: '/contract-management/contract-management.html',
      css: '/contract-management/contract-management.css',
      js: '/contract-management/contract-management.js',
      title: '客户合同管理',
      isSingle: true,
      text: '客户合同管理',
      href: 'contract-management',
      auth: 'CONTRACT_MANAGEMENT',
      parent: 'business',
    },
    {
      html: '/supplier-contract-management/supplier-contract-management.html',
      js: '/supplier-contract-management/supplier-contract-management.js',
      title: '供应商合同管理',
      isSingle: true,
      text: '供应商合同管理',
      href: 'supplier-contract-management',
      auth: 'SUPPLIER_CONTRACT_MANAGEMENT',
      parent: 'business',
    },
    {
      html: '/contract-apply-new/contract-apply-new.html',
      css: '/contract-apply-new/contract-apply-new.css',
      js: '/contract-apply-new/contract-apply-new.js',
      title: '合同申请',
      isSingle: true,
      text: '合同申请',
      href: 'contract-apply-new',
    },
    {
      html: '/contract-detail/contract-detail.html',
      css: '/contract-detail/contract-detail.css',
      js: '/contract-detail/contract-detail.js',
      title: '合同管理详情',
      isSingle: true,
      text: '合同管理详情',
      href: 'contract-detail',
    },
    {
      html: '/exempt-audit/exempt-audit.html',
      css: '/exempt-audit/exempt-audit.css',
      js: '/exempt-audit/exempt-audit.js',
      title: '合同免签审核',
      isSingle: true,
      text: '合同免签审核',
      href: 'exempt-audit',
    },
    {
      html: '/special-line-order/special-line-order.html',
      css: '/special-line-order/special-line-order.css',
      js: '/special-line-order/special-line-order.js',
      title: '专线订单管理',
      isSingle: true,
      text: '专线订单管理',
      href: 'special-line-order',
      auth: 'SPECIAL_LINE_ORDER-M',
      parent: 'transportation-manager',
      seq: 1
    },
    {
      html: '/special-line-order-detail/special-line-order-detail.html',
      css: '/special-line-order-detail/special-line-order-detail.css',
      js: '/special-line-order-detail/special-line-order-detail.js',
      title: '专线订单详情',
      isSingle: true,
      text: '专线订单详情',
      href: 'special-line-order-detail',
    },
    {
      html: '/public-dedicated-line/public-dedicated-line.html',
      css: '/public-dedicated-line/public-dedicated-line.css',
      js: '/public-dedicated-line/public-dedicated-line.js',
      title: '公共专线管理',
      isSingle: true,
      text: '公共专线管理',
      href: 'public-dedicated-line',
      auth: 'PUBLIC_DEDICATED_LINE-M',
      parent: 'transportation-manager',
      seq: 1
    },
    {
      html: '/pre-dispatch-tobe-confirm/pre-dispatch-tobe-confirm.html',
      css: '/pre-dispatch-tobe-confirm/pre-dispatch-tobe-confirm.css',
      js: '/pre-dispatch-tobe-confirm/pre-dispatch-tobe-confirm.js',
      title: '预派单待确认',
      isSingle: true,
      text: '预派单待确认',
      href: 'pre-dispatch-tobe-confirm',
    },
    {
      html: '/pickup-barge/pickup-barge.html',
      css: '/pickup-barge/pickup-barge.css',
      js: '/pickup-barge/pickup-barge.js',
      title: '以提代驳',
      isSingle: true,
      text: '以提代驳',
      href: 'pickup-barge',
    },
    {
      html: '/available-trans-capacity-vehicle/available-trans-capacity-vehicle.html',
      css: '/available-trans-capacity-vehicle/available-trans-capacity-vehicle.css',
      js: '/available-trans-capacity-vehicle/available-trans-capacity-vehicle.js',
      title: '预测车辆明细',
      isSingle: true,
      text: '预测车辆明细',
      href: 'available-trans-capacity-vehicle',
    },
    {
      html: '/available-trans-capacity/available-trans-capacity.html',
      css: '/available-trans-capacity/available-trans-capacity.css',
      js: '/available-trans-capacity/available-trans-capacity.js',
      title: '可用运力预测',
      isSingle: true,
      text: '可用运力预测',
      href: 'available-trans-capacity',
      auth: 'AVAILABLE_TRANS_CAPACITY-M',
      parent: 'transportation-manager',
      seq: 1
    },
    {
      html: '/extension-insurance-application/extension-insurance-application.html',
      css: '/extension-insurance-application/extension-insurance-application.css',
      js: '/extension-insurance-application/extension-insurance-application.js',
      title: '延保申请',
      isSingle: true,
      text: '延保申请',
      href: 'extension-insurance-application'
    },
    {
      html: '/extension-insurance-examine/extension-insurance-examine.html',
      css: '/extension-insurance-examine/extension-insurance-examine.css',
      js: '/extension-insurance-examine/extension-insurance-examine.js',
      title: '延保申请详情',
      isSingle: true,
      text: '延保申请详情',
      href: 'extension-insurance-examine'
    },
    {
      html: "/lcl-map/lcl-map.html",
      css: "/lcl-map/lcl-map.css",
      js: "/lcl-map/lcl-map.js",
      title: "小箱拼车地图(旧)",
      isSingle: true,
      text: "小箱拼车地图(旧)",
      href: "lcl-map",
    },
    {
      html: "/twinhaul-map/twinhaul-map.html",
      css: "/twinhaul-map/twinhaul-map.css",
      js: "/twinhaul-map/twinhaul-map.js",
      title: "小箱拼车",
      isSingle: true,
      text: "小箱拼车",
      href: "twinhaul-map",
    },
    {
      html: "/hitch-hiking/hitch-hiking.html",
      css: "/hitch-hiking/hitch-hiking.css",
      js: "/hitch-hiking/hitch-hiking.js",
      title: "顺风车搭配",
      isSingle: true,
      text: "顺风车搭配",
      href: "hitch-hiking",
    },
    //合同
    {
      html: '/service-management/service-management.html',
      css: '/service-management/service-management.css',
      js: '/service-management/service-management.js',
      title: '服务管理',
      isSingle: true,
      text: '服务管理',
      href: 'service-management'
      // auth: 'SERVICE_MANAGEMENT',
      // parent: 'business',
    },
    {
      html: '/contract-version-management/contract-version-management.html',
      css: '/contract-version-management/contract-version-management.css',
      js: '/contract-version-management/contract-version-management.js',
      title: '合同版本管理',
      isSingle: true,
      parent: 'business',
      auth: 'CONTRACT_VERSION_MANAGEMENT',
      text: '合同版本管理',
      href: 'contract-version-management'
    },
    {
      html: '/service-main/service-main.html',
      css: '/service-main/service-main.css',
      js: '/service-main/service-main.js',
      title: '服务主体',
      isSingle: true,
      parent: 'business',
      auth: 'SERVICE_MAIN',
      text: '服务主体',
      href: 'service-main'
    },
    {
      html: '/service-detail/service-detail.html',
      css: '/service-detail/service-detail.css',
      js: '/service-detail/service-detail.js',
      title: '服务主体详情',
      isSingle: true,
      auth: 'CUSTOMER_ORDERING_CAR',
      text: '服务主体详情',
      href: 'service-detail'
    },
    {
      html: '/department-contract-main-management/department-contract-main-management.html',
      css: '/department-contract-main-management/department-contract-main-management.css',
      js: '/department-contract-main-management/department-contract-main-management.js',
      title: '部门签约主体管理',
      isSingle: true,
      parent: 'business',
      auth: 'DEPARTMENT_CONTRACT_MAIN_MANAGEMENT',
      text: '部门签约主体管理',
      href: 'department-contract-main-management'
    },
    {
      html: '/contract-signing/contract-signing.html',
      css: '/contract-signing/contract-signing.css',
      js: '/contract-signing/contract-signing.js',
      title: '合同签署管理',
      isSingle: true,
      parent: 'business',
      auth: 'CONTRACT_SIGNING',
      text: '合同签署管理',
      href: 'contract-signing'
    },
    {
      html: '/supplier-contract-signing/supplier-contract-signing.html',
      js: '/supplier-contract-signing/supplier-contract-signing.js',
      title: '供应商合同签署',
      isSingle: true,
      parent: 'business',
      auth: 'SUPPLIER_CONTRACT_SIGNING',
      text: '供应商合同签署',
      href: 'supplier-contract-signing'
    },
    {
      html: '/contract-batch-archive/contract-batch-archive.html',
      css: '/contract-batch-archive/contract-batch-archive.css',
      js: '/contract-batch-archive/contract-batch-archive.js',
      title: '批量压缩合同',
      isSingle: true,
      auth: 'CUSTOMER_ORDERING_CAR',
      text: '批量压缩合同',
      href: 'contract-batch-archive'
    },
    {
      html: '/contract-signing-detail/contract-signing-detail.html',
      css: '/contract-signing-detail/contract-signing-detail.css',
      js: '/contract-signing-detail/contract-signing-detail.js',
      title: '合同签署详情',
      isSingle: true,
      auth: 'CUSTOMER_ORDERING_CAR',
      text: '合同签署详情',
      href: 'contract-signing-detail'
    },
    {
      html: '/factory-addr-register-audit/factory-addr-register-audit.html',
      css: '/factory-addr-register-audit/factory-addr-register-audit.css',
      js: '/factory-addr-register-audit/factory-addr-register-audit.js',
      title: '新工厂登记待审批',
      isSingle: true,
      text: '新工厂登记待审批',
      href: 'factory-addr-register-audit',
      auth: 'FACTORY_ADDR_REGISTER_AUDIT',
    },
    {
      html: '/exhibition-manage/exhibition-manage.html',
      css: '/exhibition-manage/exhibition-manage.css',
      js: '/exhibition-manage/exhibition-manage.js',
      title: '达客必优展会管理',
      isSingle: true,
      text: '达客必优展会管理',
      href: 'exhibition-manage',
      auth: 'DKBY-EXHIBITION-MANAGE',
      parent:'customer'
    },
    {
      html: '/exhibition-customer-registration/exhibition-customer-registration.html',
      css: '/exhibition-customer-registration/exhibition-customer-registration.css',
      js: '/exhibition-customer-registration/exhibition-customer-registration.js',
      title: '达客必优展会客户登记',
      isSingle: true,
      text: '达客必优展会客户登记',
      href: 'exhibition-customer-registration',
      auth: 'DKBY-EXHIBITION-CUSTOMER-REGISTER',
      parent:'customer'
    },
    {
      html: '/sea-freight-booking-customer-confirm-price/sea-freight-booking-customer-confirm-price.html',
      css: '/sea-freight-booking-customer-confirm-price/sea-freight-booking-customer-confirm-price.css',
      js: '/sea-freight-booking-customer-confirm-price/sea-freight-booking-customer-confirm-price.js',
      title: '海运订舱待确认价格',
      isSingle: true,
      text: '海运订舱待确认价格',
      href: 'sea-freight-booking-customer-confirm-price',
    },
    {
      html: '/sea-freight-booking-confirm-shipping-space/sea-freight-booking-confirm-shipping-space.html',
      css: '/sea-freight-booking-confirm-shipping-space/sea-freight-booking-confirm-shipping-space.css',
      js: '/sea-freight-booking-confirm-shipping-space/sea-freight-booking-confirm-shipping-space.js',
      title: '海运订舱待确认舱位',
      isSingle: true,
      text: '海运订舱待确认舱位',
      href: 'sea-freight-booking-confirm-shipping-space',
    },
    {
      html: '/sea-freight-booking-upload-consignment-note/sea-freight-booking-upload-consignment-note.html',
      css: '/sea-freight-booking-upload-consignment-note/sea-freight-booking-upload-consignment-note.css',
      js: '/sea-freight-booking-upload-consignment-note/sea-freight-booking-upload-consignment-note.js',
      title: '海运订舱待上传托书',
      isSingle: true,
      text: '海运订舱待上传托书',
      href: 'sea-freight-booking-upload-consignment-note',
    },
    {
      html: '/customer-group-robot-management/customer-group-robot-management.html',
      css: '/customer-group-robot-management/customer-group-robot-management.css',
      js: '/customer-group-robot-management/customer-group-robot-management.js',
      title: '客户群机器人管理',
      isSingle: true,
      text: '客户群机器人管理',
      href: 'customer-group-robot-management',
      auth: 'CUSTOMER_GROUP_ROBOT_MANAGEMENT',
      parent:'system-manager'
    },{
      html: '/add-robots-to-group/add-robots-to-group.html',
      css: '/add-robots-to-group/add-robots-to-group.css',
      js: '/add-robots-to-group/add-robots-to-group.js',
      title: '拉机器人入群',
      isSingle: true,
      text: '拉机器人入群',
      href: 'add-robots-to-group',
      auth: 'ADD_ROBOTS_TO_GROUP',
    },{
      html: '/bind-customer-group/bind-customer-group.html',
      css: '/bind-customer-group/bind-customer-group.css',
      js: '/bind-customer-group/bind-customer-group.js',
      title: '绑定客户群',
      isSingle: true,
      text: '绑定客户群',
      href: 'bind-customer-group',
      auth: 'BIND_CUSTOMER_GROUP',
    },
    // 商车科技菜单
    {
        html: '/to-ttp-web/to-ttp-web.html',
        css: '/to-ttp-web/to-ttp-web.css',
        js: '/to-ttp-web/to-ttp-web.js',
        title: '车联网',
        isSingle: true,
        text: '车联网',
        href: 'to-ttp-web',
        auth: 'TTP-WEB',
        parent: 'business-vehicle-technology',
    },
    {
      html: '/micro-app/index.html',
      css: '/micro-app/index.css',
      js: '/micro-app/index.js',
      title: 'micro-app',
      isSingle: true,
      text: 'micro-app',
      href: 'micro-app',
      auth: 'TTP-WEB',
      parent:'pbp-wx-web'
    },
    {
      html: '/charging-resource-collection/charging-resource-collection.html',
      css: '/charging-resource-collection/charging-resource-collection.css',
      js: '/charging-resource-collection/charging-resource-collection.js',
      title: '新能源采集',
      isSingle: true,
      text: '新能源采集',
      href: 'charging-resource-collection',
      auth: 'CHARGING-RESOURCE-COLLECTION',
      parent:'customer'
    },
    {
      html: '/cancel-paperless/cancel-paperless.html',
      css: '/cancel-paperless/cancel-paperless.css',
      js: '/cancel-paperless/cancel-paperless.js',
      title: '取消无纸化',
      isSingle: true,
      text: '取消无纸化',
      href: 'cancel-paperless',
    },
    {
      html: '/distribute-paperless/distribute-paperless.html',
      css: '/distribute-paperless/distribute-paperless.css',
      js: '/distribute-paperless/distribute-paperless.js',
      title: '派发无纸化',
      isSingle: true,
      text: '派发无纸化',
      href: 'distribute-paperless',
    },
    {
      html: '/reschedule-the-task/reschedule-the-task.html',
      css: '/reschedule-the-task/reschedule-the-task.css',
      js: '/reschedule-the-task/reschedule-the-task.js',
      title: '进重预约任务',
      isSingle: true,
      text: '进重预约任务',
      href: 'reschedule-the-task',
    },
    {
      html: '/customer-hotline/customer-hotline.html',
      css: '/customer-hotline/customer-hotline.css',
      js: '/customer-hotline/customer-hotline.js',
      title: '客户热线(新)',
      isSingle: true,
      text: '客户热线(新)',
      href: 'customer-hotline',
      auth: 'CUSTOMER_HOTLINE',
    },
    {
      html: '/bid-lead-management/bid-lead-management.html',
      css: '/bid-lead-management/bid-lead-management.css',
      js: '/bid-lead-management/bid-lead-management.js',
      title: '投标线索管理',
      isSingle: true,
      text: '投标线索管理',
      href: 'bid-lead-management',
      auth: 'BID_LEAD_MANAGEMENT',
      parent:'business'
    },
    {
      html: '/cost-wait-refund/cost-wait-refund.html',
      css: '/cost-wait-refund/cost-wait-refund.css',
      js: '/cost-wait-refund/cost-wait-refund.js',
      title: '成本待退费',
      isSingle: true,
      text: '成本待退费',
      href: 'cost-wait-refund',
    },
    {
      html: '/booking-space-order-list/booking-space-order-list.html',
      css: '/booking-space-order-list/booking-space-order-list.css',
      js: '/booking-space-order-list/booking-space-order-list.js',
      title: '供应商订舱订单',
      isSingle: true,
      text: '供应商订舱订单',
      href: 'booking-space-order-list',
      auth: 'BOOKING_SPACE_ORDER_LIST',
      parent:'supplier-orders'
    },
    {
      html: '/booking-space-order-details/booking-space-order-details.html',
      css: '/booking-space-order-details/booking-space-order-details.css',
      js: '/booking-space-order-details/booking-space-order-details.js',
      title: '供应商订舱订单',
      isSingle: false,
      text: '供应商订舱订单',
      href: 'booking-space-order-details',
      auth: 'BOOKING_SPACE_ORDER_DETAILS',
    },
    {
      html: '/booking-space-cost-out/booking-space-cost-out.html',
      css: '/booking-space-cost-out/booking-space-cost-out.css',
      js: '/booking-space-cost-out/booking-space-cost-out.js',
      title: '订舱成本',
      isSingle: false,
      text: '订舱成本',
      href: 'booking-space-cost-out',
      auth: 'BOOKING_SPACE_COST_OUT',
    },
    {
      html: '/booking-space-bill-making/booking-space-bill-making.html',
      css: '/booking-space-bill-making/booking-space-bill-making.css',
      js: '/booking-space-bill-making/booking-space-bill-making.js',
      title: '订舱供应商账单制作',
      isSingle: true,
      text: '订舱供应商账单制作',
      href: 'booking-space-bill-making',
      auth: 'BOOKING_SPACE_BILL_MAKING',
        parent:'cost-other'
    },
    {
      html: '/contract-apply-quickly-create-files/contract-apply-quickly-create-files.html',
      css: '/contract-apply-quickly-create-files/contract-apply-quickly-create-files.css',
      js: '/contract-apply-quickly-create-files/contract-apply-quickly-create-files.js',
      title: '快速建档',
      isSingle: true,
      text: '快速建档',
      href: 'contract-apply-quickly-create-files',
    },
    {
      html: '/contract-apply-create-files/contract-apply-create-files.html',
      css: '/contract-apply-create-files/contract-apply-create-files.css',
      js: '/contract-apply-create-files/contract-apply-create-files.js',
      title: '申请客户基础档案',
      isSingle: true,
      text: '申请客户基础档案',
      href: 'contract-apply-create-files',
    },
    {
      html: '/system-problem-feedback/system-problem-feedback.html',
      css: '/system-problem-feedback/system-problem-feedback.css',
      js: '/system-problem-feedback/system-problem-feedback.js',
      title: '系统问题反馈',
      isSingle: true,
      text: '系统问题反馈',
      href: 'system-problem-feedback',
    },
    {
      html: '/system-problem-feedback/system-problem-feedback.html',
      css: '/system-problem-feedback/system-problem-feedback.css',
      js: '/system-problem-feedback/system-problem-feedback.js',
      title: '问题反馈',
      isSingle: true,
      text: '问题反馈',
      href: 'digital-suggestions',
      auth: 'DIGITAL_SUGGESTIONS',
      parent:'digital-suggestions'
    },
  ];
  
  var parentList = [{
      text: '客户',
      name: 'customer',
      subMenu: []
    },
    {
      text: '运力',
      name: 'capacity',
      icon: 'iconfont icon-dingdan',
      subMenu: []
    },
    {
      text: '客户订单',
      name: 'customer-order',
      subMenu: []
    },
    {
      text: '单证处理',
      name: 'document-processing',
      subMenu: []
    },
    {
      text: '运输管理',
      name: 'transportation-manager',
      subMenu: []
    },
    {
      text: '应收客户',
      name: 'receivable-customer',
      subMenu: []
    },
    {
      text: '应付司机',
      name: 'driver',
      subMenu: []
    },
    {
      // text: '其他应付',
      text: '供应商',
      name: 'cost-other',
      subMenu: []
    },
    {
      text:"供应商订单",
      name: 'supplier-orders',
      subMenu: []
    },
    {
      text: '商务',
      name: 'business',
      subMenu: []
    },
    {
      text: '运维',
      name: 'operation-maintenance',
      subMenu: []
    },
    {
      text: '空箱调拨',
      name: 'empty-container',
      subMenu: []
    },
    {
      text: '活动中心',
      name: 'active-center',
      subMenu: []
    },
    {
      text: '顺风车',
      name: 'free-ride',
      subMenu: []
    },
    {
      text: '拼箱业务',
      name: 'lcl',
      subMenu: []
    },
    {
      text: '商车科技',
      name: 'business-vehicle-technology',
      subMenu: []
    },
    {
      text: '安全管理',
      name: 'safety-management',
      subMenu: []
    },
    {
      text: '增值服务',
      name: 'shop-management',
      subMenu: []
    },
    {
      text: '产品价格',
      name: 'product-price',
      subMenu: []
    },
    {
      text: '伙伴业务',
      name: 'pbp-wx-web',
      subMenu: []
    },
    {
      text: '系统管理',
      name: 'system-manager',
      subMenu: []
    },
    {
      text: '系统设置',
      name: 'system-settings',
      portalCode: 'LOCALE_DATA',
      subMenu: []
    },
    {
      text: '数字化建议',
      name: 'digital-suggestions',
      subMenu: []
    },
  ];
  
  module.exports = {
    menuList: menuList,
    parentList: parentList
  };
