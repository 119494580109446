<template>
  <div class="common-viewer-container">
    <embed
      v-if="
        includeSuffix(url, [
          'doc',
          'docx',
          'xls',
          'xlsx',
          'ppt',
          'pptx',
          'pdf',
          'zip',
          'rar',
          '7z',
        ])
      "
      style="height: 100%; width: 100%"
      :src="getUrl(url)"
    />
    <div
      v-else-if="includeSuffix(url, ['png', 'jpg', 'gif', 'jpeg', 'webp'])"
      style="height: 100%; width: 100%"
    >
      <InlineViewer :images="[url]" :options="{ backdrop: true }" class="w-full h-full" />
    </div>
    <div v-else>
      在线预览只支持.doc、.docx、.xls、.xlsx、.ppt、.pptx、.zip、.rar、.7z格式的文件
    </div>
  </div>
</template>
<script>
import InlineViewer from "./inline-viewer.vue";
export default {
  components: {
    InlineViewer,
  },
  props: ["url"],
  watch: {
    url(val, oldVal) {
      console.log(val, "val");
    },
  },
  data() {
    return {};
  },

  methods: {
    includeSuffix(url, list) {
      if (!url) {
        return;
      }
      const extension = url.split(".").pop()?.toLowerCase() || "";
      return list.includes(extension);
    },
    getUrl(url) {
      let fileUrl = url;
      var i = "21627";
      var ssl = "&ssl=1";
      var a = fileUrl.substr(0, 5);
      if (a !== "https") {
        ssl = "";
      }

      // 测试 /image.driverglobe.com 域名
      if (fileUrl.indexOf("driverglobe.com") != -1) {
        i = "34260";
      }
      if (fileUrl.indexOf("tximage.fat.driverglobe.com") != -1) {
        i = "34947";
      }

      return "https://vip.ow365.cn/?i=" + i + ssl + "&furl=" + fileUrl;
    },
  },
};
</script>
