<template>
  <div class="searchForm">
    <a-form class="ant-advanced-search-form" :form="form" style="border: none">
      <a-row :gutter="24" type="flex" align="top">
        <a-col
          v-for="(item, key, index) in list"
          :key="index"
          :span="config.itemSpan ? config.itemSpan : 6"
        >
          <a-form-item
            :label="item.label"
            :labelCol="{ span: 8 }"
            :wrapperCol="{ span: 16 }"
          >
            <slot
              v-if="item.scopedSlots"
              :name="item.scopedSlots && item.scopedSlots.customRender"
              :value="item"
            >
            </slot>
            <template v-else>
              <a-input
                v-if="item.type === 'input'"
                :placeholder="item.placeholder"
                v-decorator="[item.key, {}]"
                :size="size || 'default'"
                allowClear
              ></a-input>
              <a-range-picker
                v-if="item.type === 'rangePicker'"
                style="width: 100%"
                :size="size || 'default'"
                allowClear
                v-decorator="[item.key, []]"
              />
              <a-select
                v-if="item.type === 'select'"
                allowClear
                :size="size || 'default'"
                :placeholder="item.placeholder"
                v-decorator="[item.key, { initialValue: item.defaultValue || '' }]"
                :options="item.children"
              ></a-select>
              <a-select
                v-if="item.type === 'checkbox'"
                mode="multiple"
                allowClear
                :size="size || 'default'"
                :placeholder="item.placeholder"
                v-decorator="[item.key]"
                :options="item.children"
              >
                <a-select-option v-for="(item, index) in item.children" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </template>
          </a-form-item>
        </a-col>
        <a-col :span="buttonSpan" :style="{ textAlign: 'right' }">
          <a-button
            type="primary"
            :size="size || 'default'"
            @click="search"
            :loading="loading"
            >搜索</a-button
          >
          <a-button
            type="Default"
            :size="size || 'default'"
            :style="{ marginLeft: '8px' }"
            @click="reset"
            >重置</a-button
          >
          <a-button
            v-if="isShowOpenButton"
            type="primary"
            :size="size || 'default'"
            :icon="isOpen ? 'up' : 'down'"
            :style="{ marginLeft: '8px' }"
            @click="isOpen = !isOpen"
            >{{ isOpen ? "收起" : "展开" }}</a-button
          >
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
// import moment from 'moment';
export default {
  props: ["config", "handleSearch", "handleReset", "size"],
  inject: ["par"],
  components: {},
  data() {
    return {
      form: this.$form.createForm(this),
      isOpen: false,
      loading: false,
      // searchValue:
    };
  },
  mounted() {
    // console.log(this.config, "config");
    // this.search();
  },
  computed: {
    isShowOpenButton() {
      //是否显示展开隐藏按钮
      const { items, itemSpan = 6 } = this.config;
      if (items.length >= (24 / itemSpan) * 2) {
        return true;
      }
      return false;
    },
    list() {
      const { items, itemSpan = 6 } = this.config;
      const switchLength = (24 / itemSpan) * 2;
      if (items.length >= switchLength && this.isOpen) {
        return items;
      }
      return items.slice(0, switchLength - 1);
    },
    buttonSpan() {
      const { itemSpan = 6 } = this.config;
      const rowLength = 24 / itemSpan;
      const remainder = this.list.length % rowLength;
      if (remainder) {
        return (rowLength - remainder) * itemSpan;
      }
      return 24;
    },
  },
  methods: {
    async search() {
      if (this.handleSearch) {
        this.loading = true;
        await this.handleSearch()?.finally(() => {
          this.loading = false;
        });
        return;
      }
      if (!this.config.tableRef) {
        // message.warning("缺少参数tableRef");
        return;
      }
      const table = this.par.$refs[this.config.tableRef];
      table.resetPageNo();
      this.loading = true;
      await table.handleSearch().finally(() => {
        this.loading = false;
      });
    },
    reset() {
      //重置列表先清楚表单数据在执行search方法
      if (this.handleReset) {
        this.handleReset();
        return;
      }
      this.form.resetFields();
      this.search();
    },
    handleValue(value, type, values, key) {
      if (type === "string") {
        value = value.toString();
        if (value.trim()) {
          values[key] = value;
          return;
        } else {
          delete values[key];
          return;
        }
      }
      if (type === "number") {
        try {
          value = parseInt(value);
          values[key] = value;
          return;
        } catch (error) {
          console.log(error);
          delete values[key];
          return;
        }
      }
      if (type === "boolean") {
        value = value ? true : false;
        values[key] = value;
        return;
      }
    },
    getValues() {
      const formValues = this.form.getFieldsValue();
      const values = { ...formValues };
      for (const item of this.list) {
        try {
          if (typeof values[item.key] === "string") {
            values[item.key] = values[item.key].trim();
          }
        } catch (error) {
          console.log(error);
        }
        if (
          values[item.key] === "" ||
          values[item.key] === null ||
          values[item.key] === undefined
        ) {
          delete values[item.key];
          continue;
        }
        if (item.handleValue) {
          let vo = values[item.key];
          item.handleValue(vo, item.kye, values);
          continue;
        }
        if (item.valueType) {
          this.handleValue(values[item.key], item.valueType, values, item.key);
          continue;
        }
        this.handleValue(values[item.key], "string", values, item.key);
      }
      return values;
    },
    setValues(params) {
      this.form.setFieldsValue(params);
    },
    resetFields() {
      this.form.resetFields();
    },
    setLoading(active) {
      this.loading = active;
    },
  },
};
</script>

<style scoped>
.searchForm /deep/ .ant-advanced-search-form {
  width: 100%;
  /* padding-right: 24px; */
  border: 1px solid #d9d9d9;
  border-radius: 0px;
}

.searchForm /deep/ .ant-form-item-label {
  line-height: 32px;
}

.searchForm /deep/ .ant-advanced-search-form .ant-form-item {
  display: flex;
  margin-bottom: 4px;
}

.searchForm /deep/ .ant-form-item-control {
  line-height: 32px;
}
</style>
