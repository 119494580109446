<template>
  <div class="file-item">
    <div class="title">
      <div @click="handleClick">
        <i class="el-icon-paperclip"></i>
        <span>{{ value && value.fileName }}</span>
      </div>
      <span style="width: auto; display: inline-flex; margin-left: 5px; font-size: 18px">
        <span
          v-if="value.status === 'uploaded'"
          class="download-icon"
          @click="handleDownload($event, value)"
        >
        </span>
        <span class="close-icon" @click="handleDelete" v-if="value.status === 'uploaded'">
        </span>
      </span>
    </div>
    <div
      class="progress"
      style="background-color: #fff"
      v-if="value.status === 'uploading' && value.progress"
    >
      <a-progress :percent="value.progress" :show-info="false"></a-progress>
    </div>
  </div>
</template>
<script>
import { urlToBold } from "./options.js";
import CloseIcon from "./svg-icon/closeIcon.vue";
import CloseIconHover from "./svg-icon/closeIconHover.vue";
import DownloadIcon from "./svg-icon/downloadIcon.vue";
import DownloadIconHover from "./svg-icon/downloadIconHover.vue";
import LoadingIcon from "./svg-icon/loadingIcon.vue";

export default {
  props: ["value", "index"],
  components: {
    CloseIcon,
    CloseIconHover,
    DownloadIcon,
    DownloadIconHover,
    LoadingIcon,
  },
  data() {
    return {
      showStatus: true,
      statusConfig: {
        uploadFailed: {
          color: "red",
          iconType: "el-icon-warning-outline",
        },
        uploading: {
          color: "orange",
          iconType: "el-icon-time",
        },
        uploaded: {
          color: "green",
          iconType: "el-icon-circle-check",
        },
        notUploaded: {
          color: "#555",
          iconType: "el-icon-time",
        },
      },
      isHover: false,
      isCloseHover: false,
    };
  },
  mounted() {},
  methods: {
    handleMousemove(e) {
      this.isHover = true;
      // alert("移入");
    },
    handleMouseleave(e) {
      this.isHover = false;
      // alert("移出");
    },
    handleCloseMousemove() {
      this.isCloseHover = true;
    },
    handleCloseMouseleave() {
      this.isCloseHover = false;
    },
    handleDelete() {
      this.$emit("delete", { value: this.value, index: this.index });
    },
    handleClick(e) {
      e.stopPropagation();
      this.$emit("click", { value: this.value, index: this.index });
    },
    async handleDownload(e, value) {
      console.log("handleDownload");
      e.stopPropagation();
      if (value.status == "uploaded") {
        const result = await urlToBold(value.fileUrl);
        if (!result.success) {
          this.$message.warning(result.msg);
          return;
        }
        let eleLink = document.createElement("a");
        eleLink.download = value.fileName;
        eleLink.style.display = "none";
        eleLink.href = URL.createObjectURL(result.blob);
        document.body.appendChild(eleLink);
        eleLink.click();
        document.body.removeChild(eleLink);
      }
    },
  },
};
</script>

<style space>
.status-icon {
  position: relative;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.file-item .title {
  position: relative;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.file-item .title > div {
  display: flex;
  align-items: center;
  position: relative;
  color: #1890ff;
  width: 80%;
}
.file-item .title > div > span {
  position: relative;
  max-width: 90%;
  display: inline-block;
  margin-left: 10px;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
}

@keyframes loading {
  form {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.file-item .download-icon {
  position: relative;
  fill: #ea9518;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-size: contain;
  margin-right: 8px;
  background-image: url("../../images/upload-components/download.png");
}
.file-item .download-icon:hover {
  position: relative;
  background-image: url("../../images/upload-components/download_hover.png");
}

.file-item .icon-loading {
  position: relative;
  animation: loading 0.5s linear infinite;
}

.file-item .close-icon {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-size: contain;
  background-image: url("../../images/upload-components/close.png");
}
.file-item .close-icon:hover {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url("../../images/upload-components/close_hover.png");
}
</style>
