<template>
  <div class="feed-dk-multiple-types-upload-file" ref="multipleTypesUploadFile">
    <div class="feed-upload-file-content">
      <div class="feed-base-upload">
        <span v-if="showTitle" class="feed-title ">图片上传</span>
        <BaseUpload
            @change="handleChange"
            class="upload"
            :getMountContent="getMountContent"
            style="width:250px;height:250px;align-items: center;justify-content: center;padding-top:20px"
        >
        </BaseUpload>
      </div>
      <div class="feed-file-list-box">
        <file-item
            class="feed-file-item"
            v-for="(item, index) in newFileList"
            @click="handleItemClick(item)"
            @delete="handleDelete"
            :index="index"
            :value="item"
        >
        </file-item>
      </div>
    </div>

    <el-drawer
        :visible="visible"
         title="查看附件"
         width="40%"
         style="padding-left:45px;padding-top:45px"
        :modal="false"
        :append-to-body="true"
        :with-header="false"
    >
      <CommonViewer
          v-if="fileUrl"
          :url="fileUrl"
          :style="{ width: '100%', height: '95%' }"
      />
      <div v-else>没有附件</div>
      <div class="feed-footer-btn">
        <el-button @click="visible = false">关闭</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import BaseUpload from "./feed-base-upload.vue";
import SearchFile from "./search-file.vue";
// import fileItem from "./file-item.vue";
import fileItem from "./file-item-new";
import DragDrawer from "../dk-drag-drawer.vue";
import CommonViewer from "../dk-common-viewer/index.vue";
import { newUrlToFile, getFileMD5, getFileUploadInfo, urlToFile } from "./options.js";
import { checkFileType } from "./mimeType.js";
import JSZip from "jszip";
import FileSaver from "file-saver";
export default {

  props: [
    "showTitle",
    "uploadConfig",
    "otherInfo",
    "fileUploadInfo",
    "beforeDelete",
    "beforeUpload",
    "showDownloadAllFiles",
  ],
  data() {
    return {
      fileList: [],
      uploadService: null,
      selectFile: [],
      visible:false,
      fileUrl:'',
    };
  },
  computed: {
    newFileList() {
      return this.fileList.map((v) => {
        return {
          ...v,
        };
      });
    },
  },
  components: {
    BaseUpload,
    SearchFile,
    fileItem,
    DragDrawer,
    CommonViewer,
  },

  methods: {
    getMountContent() {
      let elm = this.$refs.multipleTypesUploadFile;
      return elm;
    },
    handleChange(fileInfo) {
      const key = fileInfo.fileKey;
      if (fileInfo.file.size == 0) {
        this.$message.warning("以下文件大小为 0 字节，请检查后重试。");
        return;
      }
      if (this.fileUploadInfo) {
        if (fileInfo.file.size > this.fileUploadInfo.fileMaxSize) {
          let mNumber = this.fileUploadInfo.fileMaxSize / 1024 / 1024;
          this.$message.warning(`文件大小超过，${mNumber}M无法上传`);
          return;
        }
      }
      let keyIndex = this.fileList.findIndex((v) => v.fileKey === key);
      if (keyIndex < 0) {
        if (this.fileUploadInfo && this.fileUploadInfo.fileType.length) {
          let checkStatus = checkFileType(fileInfo.file, this.fileUploadInfo.fileType);
          if (!checkStatus) {
            let text =
                "目前只支持后缀名为" +
                this.fileUploadInfo.fileType.reduce((preVo, curVo) => {
                  return (preVo += "." + curVo + "、");
                }, "") +
                "的文件！";
            this.$message.warning(text);
            return;
          }
        }
        this.updateFileList("add", {
          fileKey: fileInfo.fileKey,
          fileName: fileInfo.fileName,
          file: fileInfo.file,
          status: "notUploaded",
          progress: null,
        });
      }
      if (this.uploadConfig.autoUpload) {
        this.upload();
      }
    },
    async upload() {
      let postList = this.fileList.filter((v) => {
        let status = v.status === "notUploaded" || v.status === "uploadFailed";
        return status;
      });
      let asyncList = [];
      for (let item of postList) {
        asyncList.push(this.postUploadFile(item));
      }
      const result = await Promise.all(asyncList);
      let isErr = false;
      for (let vo of result) {
        if (!vo.success) {
          if (!isErr) {
            isErr = true;
          }
          _.ui.notify({
            message: vo.msg,
            type: "warning",
          });
        }
      }
      let files = [...this.fileList];
      if (isErr) {
        return files;
      }
      return files;
    },

    async postUploadFile(fileInfo) {
      if (fileInfo.status == "uploaded") {
        _.ui.notify({
          message: "已经上传的文件不在上传",
          type: "warning",
        });
        return;
      }

      if (!this.uploadService) {
        _.ui.notify({
          message: "文件上传服务初始化失败",
          type: "warning",
        });
        return;
      }

      if (!fileInfo.file) {
        _.ui.notify({
          message: "缺少文件",
          type: "warning",
        });
        return;
      }
      if (this.beforeUpload) {
        try {
          const result = await this.beforeUpload(fileInfo);
          if (!result) {
            console.log("不上传");
            return;
          }
        } catch (error) {
          console.error(error);
          return;
        }
      }
      const result = await this.uploadService.uploadFile({
        file: fileInfo.file,
        onUploadProgress: (progress, uploadObject) => {
          fileInfo.progress = progress;
          if (fileInfo.progress < 100) {
            fileInfo.status = "uploading";
          } else {
            fileInfo.status = "uploaded";
          }
        },
      });

      if (result.success) {
        const key = fileInfo.fileKey;
        let keyIndex = this.fileList.findIndex((v) => v.fileKey === key);
        if (keyIndex >= 0) {
          this.$set(this.fileList[keyIndex], "fileUrl", result.fileUrl);
          this.$set(this.fileList[keyIndex], "status", "uploaded");
        }
      }
      return result;
    },
    async initUploadServer() {
      if (!this.uploadConfig) {
        _.ui.notify({
          message: "缺少上传参数",
          type: "warning",
        });
        return;
      }
      const config = this.uploadConfig;
      this.uploadService = new YzsFileService({
        bizKey: config.bizKey,
        bizType: config.bizType,
        basePrefix: "/gw",
        generateMate: function () {
          return {
            client: "PC",
            code: config.code,
            tag: "string",
            time: new Date().getTime(),
          };
        },
        generateHeaders: function () {
          return {
            Authorization: localStorage.getItem("login-token"),
          };
        },
      });
/*      const result = await getFileUploadInfo({
        bizType: config.bizType,
        sysCode: config.code,
      });
      this.fileUploadInfo = result;*/
    },

    async handleDelete(item) {
      if (this.beforeDelete) {
        const res = await this.beforeDelete({
          index: item.index,
          value: item.value,
        });
        if (!res) {
          console.log("处理结果显示不删除");
          return;
        }
      }
      if (item.value.id) {
        let index = this.selectFile.findIndex((v) => v === item.value.id);
        if (index >= 0) {
          this.selectFile.splice(index, 1);
        }
      }
      this.$emit("active", { type: "delete", index: item.index, value: item.value });
      this.updateFileList("delete", {
        start: item.index,
        end: 1,
      });
    },
    handleItemClick(item) {
      this.visible = true;
      this.fileUrl =item.fileUrl;
    },
    getContainer() {
      const node = document.getElementById("page-wrapper");
      return node;
    },
    async handleSelectFile(column) {
      if (this.selectFile.includes(column.id)) {
        let index = this.fileList.findIndex((vo) => {
          if (!vo.id) {
            return false;
          }
          if (vo.id == column.id) {
            return true;
          }
        });
        this.updateFileList("delete", {
          start: index,
          end: 1,
        });
        this.selectFile = this.selectFile.filter((vo) => {
          return column.id != vo;
        });
        return;
      }
      const result = await newUrlToFile(column.fileUrl, column.fileName);
      if (!result.success) {
        this.$message.warning(result.msg);
        return;
      }
      let file = result.file;
      if (file) {
        console.log(file, "file");
        if (file.size == 0) {
          this.$message.warning("以下文件大小为 0 字节，请检查后重试。");
          return;
        }
        if (this.fileUploadInfo) {
          if (file.size > this.fileUploadInfo.fileMaxSize) {
            let mNumber = this.fileUploadInfo.fileMaxSize / 1024 / 1024;
            this.$message.warning(`文件大小超过，${mNumber}M无法上传`);
            return;
          }
        }
        const key = getFileMD5(file);
        let keyIndex = this.fileList.findIndex((v) => v.fileKey === key);
        if (keyIndex < 0) {
          if (this.fileUploadInfo && this.fileUploadInfo.fileType.length) {
            let checkStatus = checkFileType(file, this.fileUploadInfo.fileType);
            if (!checkStatus) {
              let text =
                  "目前只支持后缀名为" +
                  this.fileUploadInfo.fileType.reduce((preVo, curVo) => {
                    return (preVo += "." + curVo + "、");
                  }, "") +
                  "的文件！";
              this.$message.warning(text);
              return;
            }
          }
          this.updateFileList("add", {
            fileKey: key,
            fileName: file.name,
            file: file,
            status: "notUploaded",
            progress: null,
            id: column.id,
          });
          this.selectFile.push(column.id);
        } else {
          this.$message.warning("当前文件已经选择！");
          return;
        }
        if (this.uploadConfig.autoUpload) {
          this.upload();
        }
      }
    },
    handleViewerFile(url) {
      this.$refs.commonViewerRef.viewer(url);
    },

    getValues() {
      return this.fileList;
    },
    setValues(fileList) {
      let oldValue = [...this.fileList];
      this.fileList = fileList;
      this.$nextTick(()=>{
        this.$emit("change", fileList, oldValue);
      })
    },
    addValue(fileInfo) {
      this.updateFileList("add", fileInfo);
    },
    initForm() {
      if (this.otherInfo && this.otherInfo.customerId) {
        if (!this.$refs.searchFileBox) {
          return;
        }
        this.$refs.searchFileBox.setCustomer({
          name: this.otherInfo.customerName,
          id: this.otherInfo.customerId,
        });
        this.$refs.searchFileBox.setValues({
          customerId: this.otherInfo.customerId,
        });
        this.$refs.searchFileBox.search();
      }
    },

    updateFileList(active, value) {
      let oldValue = [...this.fileList];
      if (active === "add") {
        this.fileList.push(value);
        this.$nextTick(()=>{
          this.$emit("change", [...this.fileList], oldValue);
        })
        return [...this.fileList];
      }
      if (active === "delete") {
        let vo = this.fileList.splice(value.start, value.end);
        this.$nextTick(()=>{
          this.$emit("change", [...this.fileList], oldValue);
        })
        return vo;
      }
    },
    resetValues() {
      this.fileList = [];
      this.selectFile = [];
    },
    async downloadAllFiles() {
      // const data = this.fileList; // 需要下载打包的路径
      const zip = new JSZip();
      const cache = {};
      const promises = [];
      for (let index in this.fileList) {
        let item = this.fileList[index];
        const promise = urlToFile(item.fileUrl, item.fileName).then((data) => {
          const { fileName } = item; // 获取文件名
          let name = index + 1 + "_" + fileName;
          zip.file(name, data, { binary: true }); // 逐个添加文件
          cache[name] = data;
        });
        promises.push(promise);
      }
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          // 生成二进制流
          let zipName = `${dayjs().format("YYYY-MM-DD")}附件`;
          FileSaver.saveAs(content, `${zipName}.zip`); // 利用file-saver保存文件
        });
      });
    },
  },
  watch: {
    otherInfo: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.initForm();
          }, 200);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    this.resetValues();
  },
  mounted() {
    this.initUploadServer();
    // console.log(this.otherInfo, "mounted.otherInfo");
    // if (this.uploadConfig.showFileSearch && this.otherInfo) {
    //   this.initForm();
    // }
  },
};
</script>

<style >
.feed-dk-multiple-types-upload-file {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content:flex-start;
}
.feed-title {
  font-size: 20px;
  display: inline-block;
  font-weight: bold;
  margin-bottom: 18px;
}

.feed-upload-file-content {
  display: flex;
  flex-direction: row;
  align-items: start;
}
.feed-base-upload {
  display: flex;
  flex-direction: column;
  width:250px;
  justify-content: center;
  align-items: center;
}
.feed-file-list-box {
  flex:1;
  display: flex;
  flex-direction: column;
  margin-left:10px;
  align-items: start;
  justify-content: flex-start;
}
.feed-file-item {
  position: relative;
  height:40px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
/* ===== 底部按钮排版 ===== */
.feed-footer-btn {
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
}


</style>
