import { httpQQ, http, harbourHttp } from "../../api/_http";
export function postCustomerList(payload) {
  return harbourHttp
    .post("/user/relatedCompany/list/new", payload)
    .then((res) => res.data);
}
export function fetchSearchFile(payload) {
  return httpQQ
    .post("/shippingqq-qidian/chatGroupFile/searchFile", payload)
    .then((res) => res.data);
}
